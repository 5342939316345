import moment from "moment";

export const getTimeDifference = (time) => {
    let targetMoment = moment.utc(time).local()
    const now = moment();
    const diff = targetMoment.diff(now);
    const duration = moment.duration(Math.abs(diff));
    if (duration.asWeeks() >= 1) {
      return `${Math.round(duration.asWeeks())}w`;
    } else if (duration.asDays() >= 1) {
      return `${Math.round(duration.asDays())}d`;
    } else if (duration.asHours() >= 1) {
      return `${Math.round(duration.asHours())}h`;
    } else {
      return `${Math.round(duration.asMinutes())}m`;
    }
}