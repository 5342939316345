import React, { useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import camera from "../../static/login/camera.svg";
import user from "../../static/login/user.png";
import moment from "moment";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import "../../styles/layout/cropper.css";

function UserForm() {
  const { t } = useTranslation();
  //Form signup

  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    sex: "none",
    dob: moment(),
    pictureId: 0,
    pictureUrl: null,
  });

  const handle12Change = (field) => (e) => {
    setFormValues({ ...formValues, [field]: e.target.value });
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      console.log("sdadsa");
    } else {
      swal({
        icon: "error",
        title: t("ucca-t-3"),
      });
    }
  };

  let tempErrors = {
    fullName: "",
    sex: "",
  };
  const [errors, setErrors] = useState(tempErrors);
  const validation = () => {
    let tempErrors = {
      fullName: formValues.fullName ? "" : "Add a Full Name",
      sex: formValues.sex !== "none" ? "" : "Select a Gender",
    };

    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x == "");
  };

  //check for clinic Details
  //const  clinicDetails  = useSelector((state) => state.clinic.clincObj.clinic);

  const [imgSrc, setImgSrc] = useState("");

  const openCropper = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      //setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      handleShow();
    }
  };

  //modal

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  //cropper
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var file1 = new File([u8arr], filename, { type: mime });
    var formData = new FormData();
    formData.append("file", file1);

    //MEDIA UPLOAD

    // mediaUpload(formData, getLocalData("user-info")?.userToken)
    //   .then((response) => {
    //     if (response.status === "Success") {
    //       setFormValues({
    //         ...formValues,
    //         pictureId: response.response.pictureId,
    //         pictureUrl: response.response.pictureUrl,
    //       });
    //       setShow(false);
    //     }
    //   })
    //   .catch((err) => {});

    return new File([u8arr], filename, { type: mime });
  }
  const cropperRef = useRef(null);
  const handleCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    //console.log();

    var file1 = dataURLtoFile(
      cropper
        .getCroppedCanvas({ fillColor: "#fff", width: 320, height: 320 })
        .toDataURL(),
      "product-img-320X320"
    );
  };

  return (
    <form className="startup-form" onSubmit={onSubmit}>
      <Row>
        <Col xs={12} className="text-1">
          Create your profile
        </Col>
        <Col xs={12} className="camera">
          <div className="camera-in">
            <img
              src={formValues.pictureUrl ? formValues.pictureUrl : user}
              alt="userschiolete"
            />
            <div>
              <label htmlFor="file">
                <img src={camera} alt="camera" />
              </label>

              <input
                type="file"
                id="file"
                onChange={(e) => openCropper(e)}
                hidden
              />
            </div>
          </div>
        </Col>
        <Col xs={12} className="form-ips">
          <TextField
            {...(errors.fullName !== "" && {
              error: true,
              helperText: errors.fullName,
            })}
            label="Your Full name"
            type="text"
            onChange={handle12Change("fullName")}
            placeholder="Your Full name"
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col xs={12} className="form-ips">
          <TextField
            label="Email Address"
            type="text"
            onChange={handle12Change("email")}
            placeholder="yourname@mail.com"
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Col>
        <Col xs={12} className="form-ips">
          <FormControl className="selectfmc" error={errors.sex !== ""}>
            <InputLabel id="demo-simple-select-autowidth-label" shrink="true">
              Gender
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              className={formValues.sex === "none" ? "selectgray" : ""}
              value={formValues.sex}
              onChange={handle12Change("sex")}
              label="Gender"
            >
              <MenuItem className="d-none" value="none" disabled>
                Select your gender
              </MenuItem>
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"others"}>Others</MenuItem>
            </Select>
            {errors.sex !== "" && <FormHelperText>{errors.sex}</FormHelperText>}
          </FormControl>
        </Col>
        <Col xs={12} className="form-ips">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <InputLabel
              id="demo-simple-select-autowidth-label2"
              shrink="true"
              className="datelabel"
            >
              Date of Birth
            </InputLabel>
            <DesktopDatePicker
              labelId="demo-simple-select-autowidth-label2"
              format="DD/MM/YYYY"
              value={formValues.dob}
              disableFuture={true}
              onChange={(date) => {
                setFormValues({
                  ...formValues,
                  dob: date,
                });
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={onKeyDown}
                  variant="standard"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Col>

        <Col xs={12} className="text-1">
          <button className="btnr btnr-primary" type="submit">
            SAVE
          </button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Modal className="cropper-modal" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              {/* <Modal.Title>{t("ep-t-151")}</Modal.Title> */}
              <Modal.Title>Crop Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Cropper
                src={imgSrc}
                style={{ height: 400, width: "100%" }}
                // Cropper.js options
                initialAspectRatio={1 / 1}
                aspectRatio={1 / 1}
                guides={true}
                //crop={onCrop}
                crop={() => handleCrop}
                ref={cropperRef}
              />
            </Modal.Body>
            <Modal.Footer>
              <button variant="secondary" onClick={handleClose}>
                {t("ep-t-16")}
              </button>
              <button variant="primary" onClick={handleCrop}>
                {t("ep-t-17")}
              </button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </form>
  );
}

export default UserForm;
