import HeaderMenu from "./headercomps/HeaderMenu";
import HeaderTop from "./headercomps/HeaderTop";

function Header() {
  return (
    <header>
      <HeaderTop />
      <HeaderMenu />
    </header>
  );
}

export default Header;
