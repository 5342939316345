import React from "react";
import vc from "../../static/appt/vc.svg";
import ip from "../../static/appt/ip.svg";

import home from "../../static/appt/home.svg";
import loc from "../../static/appt/loc.svg";
import clock from "../../static/appt/clock.svg";
import cal from "../../static/appt/cal.svg";
import moment from "moment";

function AppSummary(props) {
  console.log(props)
  const { apptType, date, selectedSlot, endselectedSlot, processObj, clinicObj } = props;
 
  return (
    <div className="apt-Summary">
      <div className="apt-type">
        {apptType === "InPerson" && (
          <>
            <img src={ip} alt="a" />
            Clinic Visit
          </>
        )}
        {apptType === "VideoCall" && (
          <>
            <img src={vc} alt="a" />
            Video Consultation
          </>
        )}
        {apptType === "Treatment" && (
          <>
            <img src={processObj.pictureUrl} alt="a" />
            {processObj.name}
          </>
        )}
      </div>

      {apptType === "InPerson" && (
        <>
          <div className="icon-text">
            <div className="icon">
              <img className="star" src={home} alt="star" />
            </div>
            <div className="txt">
              <span>Clinic name</span>{clinicObj.clinicName
                ? clinicObj.clinicName
                : "Not Available"}
            </div>
          </div>
          <div className="icon-text">
            <div className="icon">
              <img className="star" src={loc} alt="star" />
            </div>
            <div className="txt">
              <span>Location</span>{clinicObj.location
                ? clinicObj.clinicName
                : "Not Available"}
            </div>
          </div>
        </>
      )}

      <div className="icon-text">
        <div className="icon">
          <img className="star" src={cal} alt="star" />
        </div>
        <div className="txt">
          <span>Appointment date</span>
          {date}
        </div>
      </div>
      <div className="icon-text">
        <div className="icon">
          <img className="star" src={clock} alt="star" />
        </div>
        <div className="txt">
          <span>Appointment Time</span>
          {moment(selectedSlot).format("HH:mm")} to{" "}
          {moment(endselectedSlot).format("HH:mm")}
        </div>
      </div>
    </div>
  );
}

export default AppSummary;
