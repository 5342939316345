import React from 'react';
import avatar from "../../static/chat/avatar.png";

const ReceiveMsg = () => {
    return (
        <div className="chat">
            <img src={avatar} alt="" />
            <div className="chat_text">
                <p className="chat_msg">
                    Hello, I’m FitBot! 👋 I’m your personal sport assistant. How can I help you?
                </p>
                <span>3:14 pm</span>
            </div>
        </div>
    );
};

export default ReceiveMsg;