import React from "react";
import { useSelector } from "react-redux";

function HeaderTop() {
  //check for clinic Details
  const defaultHealthCenter = useSelector(
    (state) => state.clinic.clincObj.defaultHealthCenter
  );
  return (
    <div className="header-top">
      <div className="container">
        {defaultHealthCenter?.address && (
          <span className="placeholdercont">
            <span className="icon-location-line fs-6 pe-2"></span>
            {defaultHealthCenter?.address}
          </span>
        )}
        {defaultHealthCenter?.countryCode && defaultHealthCenter?.phoneNumber && (
          <span className="placeholdercont">
            <span className="icon-phone-solid fs-9 pe-2"></span>
            <a
              href={`tel:${defaultHealthCenter?.countryCode}${defaultHealthCenter?.phoneNumber}`}
            >
              + ({defaultHealthCenter?.countryCode}){" "}
              {defaultHealthCenter?.phoneNumber}{" "}
            </a>
          </span>
        )}
        {defaultHealthCenter?.email && (
          <span className="placeholdercont">
            <span className="icon-mail-line fs-9 pe-2"></span>
            <a href={`mailto:${defaultHealthCenter?.email}`}>
              {defaultHealthCenter?.email}
            </a>
          </span>
        )}
      </div>
    </div>
  );
}

export default HeaderTop;
