import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion";

import avatar from "../../static/login/avatar.svg";
import bookmark from "../../static/login/bookmark.svg";
import support from "../../static/login/support.svg";
import check from "../../static/notif/check.svg";
import belle from "../../static/notif/belle.svg";
import { signoutUser } from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTimeDifference } from "../../utils/timeHelper";
import nonotiff from "../../static/notif/notiff.svg";
import { Skeleton } from "@mui/material";
import { MarkAllReadPatientNotificationAPI } from "../../services/notiffservice";
import { getNotificationsRedux } from "../../redux/homePageSlice";
import moment from "moment";

import appt_booked from "../../static/notif/appt_confirmed.svg";
import appt_reschedule from "../../static/notif/Reschedule.svg";
import appt_followup from "../../static/notif/follow_up_req.svg";
import appt_recommendation from "../../static/notif/recomendation.svg";
import users from "../../static/notif/users.svg";
import promotion_selected from "../../static/notif/doctor_share_post.svg";
import video_fill from "../../static/notif/video_fill.svg";
import patient_end_call from "../../static/notif/y_call-ended.svg";
import joined_call from "../../static/notif/joined_v_call.svg";
import appt_cancle from "../../static/notif/appt_cancel.svg";
import appt_reminder from "../../static/notif/remainder.svg";
import PatientCheckIn from "../../static/notif/patient_check-in.svg";
import treatment_confirm from "../../static/notif/treatment_confirmed.svg";

function NotiffDrop() {
  const [isDropVisible, setIsDropVisible] = useState(false);

  const dispatch = useDispatch();

  const notiffs = useSelector((state) => state.homepage.notifications);

  const containerVariats = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: {
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
    exit: {
      opacity: 0,

      transition: {
        delay: 0.4,
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
  };

  const notiffSkeleton = (
    <div className="notif-item">
      <Row className="align-items-center">
        <Col xs={2} className="text-center img">
          <Skeleton variant="circular" width={45} height={45} />
        </Col>
        <Col xs={8}>
          <div className="title">
            <Skeleton />
          </div>
          <div className="subtitle">
            <Skeleton />
          </div>
        </Col>
        <Col xs={2}>
          <span className="msg-time">
            <Skeleton />
          </span>
        </Col>
      </Row>
    </div>
  );
  //check for clinic Details
  const userDetails = useSelector((state) => state.user);

  const markAllAsRead = () => {
    MarkAllReadPatientNotificationAPI(userDetails.userObj.patientData.id)
      .then((res) => {
        if (res === "Updated") {
          let tempObj = {
            pageNo: 1,
            pageSize: 100,
            patientId: userDetails.userObj.patientData.id,
          };

          //call notifications
          dispatch(getNotificationsRedux(tempObj));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getNotifIcon = (item) => {
    if (item?.subType === "Doctor_Wall_Post") {
      return promotion_selected;
    } else if (item?.subType === "VideoCallAppointmentBooked") {
      return video_fill;
    } else if (item?.subType === "InPersonAppointmentBooked") {
      return users;
    } else if (item?.subType === "TreatmentAppointmentBooked") {
      return users;
    } else if (item?.subType === "CancelAppointment") {
      return appt_cancle;
    } else if (item?.subType === "PatientJoined") {
      return joined_call;
    } else if (item?.subType === "PatientCallEnded") {
      return patient_end_call;
    } else if (item?.subType === "DoctorJoined") {
      return joined_call;
    } else if (item?.subType === "DoctorCallEnded") {
      return patient_end_call;
    } else if (item?.subType === "AppointmentReminder") {
      return appt_reminder;
    } else if (item?.subType === "AppointmentCompleted") {
      return treatment_confirm;
    } else if (item?.subType === "AppointmentReschedule") {
      return appt_reschedule;
    } else if (item?.subType === "AppointmentFollowUp") {
      return appt_followup;
    } else if (item?.subType === "AppointmentRecommendation") {
      return appt_recommendation;
    } else if (item?.subType === "PatientCheckIn") {
      return PatientCheckIn;
    }
  };

  const getColor = (item) => {
    let color = "primaryColor";
    if (item?.subType === "Doctor_Wall_Post") {
      color = "primaryColor";
    } else if (item?.subType === "VideoCallAppointmentBooked") {
      color = "seaGreen";
    } else if (item?.subType === "InPersonAppointmentBooked") {
      color = "seaGreen";
    } else if (item?.subType === "TreatmentAppointmentBooked") {
      color = "seaGreen";
    } else if (item?.subType === "AppointmentReschedule") {
      color = "blue";
    } else if (item?.subType === "AppointmentFollowUp") {
      color = "yellow";
    } else if (item?.subType === "AppointmentRecommendation") {
      color = "purple";
    } else if (item?.subType === "PatientCheckIn") {
      color = "purple";
    } else if (item?.subType === "CancelAppointment") {
      color = "warningColor";
    } else if (item?.subType === "AppointmentCompleted") {
      color = "greenLight";
    } else if (item?.subType === "PatientJoined") {
      color = "seaGreen";
    } else if (item?.subType === "PatientCallEnded") {
      color = "warningColor";
    } else if (item?.subType === "DoctorJoined") {
      color = "greenLight";
    } else if (item?.subType === "DoctorCallEnded") {
      color = "warningColor";
    } else if (item?.subType === "AppointmentReminder") {
      color = "warningColor";
    }
    return color;
  };
  const getnotifStatus = (item) => {
    let status = "";

    if (item?.subType === "Doctor_Wall_Post") {
      status = "New Post";
    } else if (item?.subType === "VideoCallAppointmentBooked") {
      status = "Confirmed";
    } else if (item?.subType === "InPersonAppointmentBooked") {
      status = "Confirmed";
    } else if (item?.subType === "TreatmentAppointmentBooked") {
      status = "Confirmed";
    } else if (item?.subType === "AppointmentReschedule") {
      status = "Rescheduled";
    } else if (item?.subType === "AppointmentFollowUp") {
      status = "Follow-up Request";
    } else if (item?.subType === "AppointmentRecommendation") {
      status = "Recommendation";
    } else if (item?.subType === "PatientCheckIn") {
      status = "Check in";
    } else if (item?.subType === "CancelAppointment") {
      status = "Cancelled";
    } else if (item?.subType === "AppointmentCompleted") {
      status = "Completed";
    } else if (item?.subType === "PatientJoined") {
      status = "joined Call";
    } else if (item?.subType === "PatientCallEnded") {
      status = "Call Ended";
    } else if (item?.subType === "DoctorJoined") {
      status = "joined Call";
    } else if (item?.subType === "DoctorCallEnded") {
      status = "Call ended";
    } else if (item?.subType === "AppointmentReminder") {
      status = "will begin in some time";
    }
    return status;
  };

  return (
    <>
      <div className="notiffdrop-parent d-flex d-sm-none">
        <Link to="/notification" className="notiff-img ">
          <img src={belle} alt="userimg" />
        </Link>
      </div>
      <div
        className={` notiffdrop-parent d-none d-sm-flex`}
        onMouseEnter={() => setIsDropVisible(true)}
        onMouseLeave={() => setIsDropVisible(false)}
      >
        <div className="notiff-img ">
          <img src={belle} alt="userimg" />
        </div>

        <AnimatePresence>
          {isDropVisible && (
            <motion.div
              variants={containerVariats}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="notifdrop-menu"
              onMouseEnter={() => setIsDropVisible(true)}
              onMouseLeave={() => setIsDropVisible(false)}
            >
              <div className="header">
                Notifications
                {!notiffs?.loading && notiffs?.list.length > 0 && (
                  <span className="" onClick={() => markAllAsRead()}>
                    Mark all as read{" "}
                    <img src={check} className="ps-1" alt="sd" />{" "}
                  </span>
                )}
              </div>

              <div className="list">
                {!notiffs?.loading ? (
                  notiffs?.list.length > 0 ? (
                    notiffs.list.map((notif, indx) => (
                      <Link
                        to={`${
                          notif.notificationGroup === "Appointment"
                            ? `/appointmentsDetails/${notif.associatedId}`
                            : ""
                        }${
                          notif.notificationGroup === "Promotions" ? `wall` : ""
                        }`}
                        className={`notif-item ${
                          notif.isRead ? "" : "notread"
                        }`}
                        key={indx}
                      >
                        <Row>
                          <Col xs={2} className="text-center img">
                            {}
                            <img src={getNotifIcon(notif)} alt="notif_icon" />
                            {/* {notif.notificationGroup === "Appointment" && (
                              <span className="icons-nav icon-plus-solid"></span>
                            )}
                            {notif.notificationGroup === "Promotions" && (
                              <span className="icons-nav icon-active  icon-wall-solid"></span>
                            )} */}
                          </Col>
                          <Col xs={8}>
                            <div className="title">
                              {notif.title}{" "}
                              {notif.associatedDateTime && (
                                <>
                                  {`${moment
                                    .utc(notif.associatedDateTime)
                                    .local()
                                    .format("hh:mm a")} `}
                                </>
                              )}
                            </div>
                            <div className={`notif_status ${getColor(notif)}`}>
                              {getnotifStatus(notif)}
                            </div>
                            <div className="subtitle">{notif.messegeBody}</div>
                          </Col>
                          <Col xs={2}>
                            {" "}
                            <span className="msg-time">
                              {getTimeDifference(notif.createdOnUtc)}
                            </span>
                          </Col>
                        </Row>
                      </Link>
                    ))
                  ) : (
                    <div className="no-notiff">
                      <div className="img">
                        <img src={nonotiff} alt="" />
                      </div>
                      <div className="no-notiff-text">
                        <h2>No notifications</h2>
                        <span>
                          You have no notifications yet. <br />
                          Please come back later.
                        </span>
                      </div>
                    </div>
                  )
                ) : (
                  <>
                    {notiffSkeleton}
                    {notiffSkeleton}
                    {notiffSkeleton}
                    {notiffSkeleton}
                    {notiffSkeleton}
                  </>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}

export default NotiffDrop;
