import React from "react";
import { Col, Row } from "react-bootstrap";
import star from "../../static/doctor/star.svg";
import nop from "../../static/doctor/nop.svg";
import exp from "../../static/doctor/exp.svg";
import aoe from "../../static/doctor/aoeicn.svg";
import shape1 from "../../static/doctor/shape1.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDeepLinking,
  updateLoginDrawerState,
} from "../../redux/webAppSlice";

function OurDoctors() {
  //get doctor list from redux
  const doctorList = useSelector((state) => state.homepage.ourDoctorsHome.list);

  //get user detals redux
  const userDetails = useSelector((state) => state.user.userObj);
  //get user detals redux
  const doctorListLayout = useSelector(
    (state) => state.clinic.clincObj.clinic.doctorListLayout
  );

  const hasDoctorFullProfile = useSelector(
    (state) => state.clinic?.clincObj?.clinic?.hasDoctorFullProfile
  );
  //get clinic details from redux
  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  //openLogin drwaer
  const dispatch = useDispatch();
  const openLoginDrawer = (tempObj) => {
    dispatch(updateLoginDrawerState(true));

    dispatch(updateDeepLinking(tempObj));
  };

  const navigate = useNavigate();

  const navigateToBookAppt = (stateforNextPage) => {
    console.log(stateforNextPage)
    if (userDetails !== null) {
      navigate("/bookappt", { state: stateforNextPage });
    } else if (userDetails === null) {
      let tempObj = {
        toLink: "/bookappt",
        state: {
          doctor: stateforNextPage.doctor,
          apptType: stateforNextPage.apptType,
        },
      };
      openLoginDrawer(tempObj);
    }

    //check whether user is Logged in ??
  };

  const getColor = (index) => {
    let color = "";
    if (index % 4 === 0) {
      color = "blue";
    } else if (index % 4 === 1) {
      color = "green";
    } else if (index % 4 === 2) {
      color = "yellow";
    } else if (index % 4 === 3) {
      color = "red";
    }
    return color;
  };
  return (
    <>
      {doctorListLayout === 1 && (
        <section className="doctors-home d-none d-sm-block">
          <div className="container">
            <div className="doctor-layout-1">
              <div className="header">
                <div className="pill">
                  Our Doctors
                </div>
                <div className="title">
                  Expert Doctors at Your Service
                </div>

              </div>
              {doctorList.map((doc, index) => (
                <div className="doctor-layout-card" key={index}>
                  <div className="prfl-img">
                    <img src={doc.pictureUrl} alt="img" />
                  </div>
                  <div className="prfl-desc">
                    <div className="d-Name">{doc.name}</div>
                    <div className="d-Spec">{doc.title}</div>
                    <div className="d-Study">{doc.shortEducation}</div>
                    <div className="v1">
                      {doc.areasOfExpertiseDtos && (
                        <div className="d-tags">
                          {doc.areasOfExpertiseDtos.map((tag, indexin) => (
                            <div
                              key={indexin}
                              className={`tag tag-${getColor(indexin + index)}`}
                            >
                              <img src={aoe} alt="asd" />
                              {tag.value}
                            </div>
                          ))}
                        </div>
                      )}

                      <Row>
                        {doc.rating > 0 && (
                          <Col
                            xs={3}
                            className="d-flex align-items-center doc-stats"
                          >
                            <div className="doc-nav-img">
                              <img src={star} alt="ico" />
                            </div>

                            <div className="flex-grow-1 ms-3">
                              <span className="doc-stats-tit">Rating</span>
                              <span className="doc-stats-txt">
                                {doc.rating} out of 5
                              </span>
                            </div>
                          </Col>
                        )}
                        {doc.experience > 0 && (
                          <Col
                            xs={3}
                            className="d-flex align-items-center doc-stats"
                          >
                            <div className="doc-nav-img">
                              <img src={exp} alt="ico" />
                            </div>

                            <div className="flex-grow-1 ms-3">
                              <span className="doc-stats-tit">Experience</span>
                              <span className="doc-stats-txt">
                                {doc.experience}+ years
                              </span>
                            </div>
                          </Col>
                        )}

                        {doc.patientsServed > 0 && (
                          <Col
                            xs={3}
                            className="d-flex align-items-center doc-stats"
                          >
                            <div className="doc-nav-img">
                              <img src={nop} alt="ico" />
                            </div>

                            <div className="flex-grow-1 ms-3">
                              <span className="doc-stats-tit">Patient</span>
                              <span className="doc-stats-txt">
                                {doc.patientsServed}+
                              </span>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>

                    <div className="v2">
                      {
                        hasDoctorFullProfile && (
                          <Link
                            to={`/doctorprofile/${doc.slug}`}
                            state={{ data: doc }}
                            className="btnr btnr-primary-outline-white btnr-hover"
                          >
                            View Details<span className="arr"></span>
                          </Link>
                        )
                      }
                      {
                        (clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) &&
                        <button
                          onClick={() => navigateToBookAppt({ doctor: doc })}
                          className={`ms-3 btnr btnr-white  btnr-hover`}
                        >
                          Book consultation <span className="arr"></span>
                        </button>
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="shape shape3">
            <img src={shape1} alt="shape" />
          </div>
        </section>
      )}

      {(doctorListLayout === 2 || doctorListLayout === 3) && (
        <section className="doctors-home d-none d-sm-block">
          <div className="backclass"></div>
          <div className="container">
            <div className={`doctor-layout-${doctorListLayout}`}>
              <div className="dc-header">
                <span className="pill">Our Doctors</span>
                <span className="title">Expert Doctors at Your Service</span>
              </div>
              <div className={`layout${doctorListLayout}-track`}>
                {doctorList.map((doc, index) => (
                  <div className="doctor-layout-card" key={index}>
                    <div className="prfl-img">
                      <img src={doc.pictureUrl} alt="img" />
                    </div>
                    <div className="doc-info">
                      <div className="d-Name">{doc.name}</div>
                      <div className="d-Spec">{doc.title}</div>
                      <div className="d-Study">{doc.shortEducation}</div>

                      {doc.areasOfExpertiseDtos && (
                        <div className="d-tags">
                          {doc.areasOfExpertiseDtos.map((tag, indexin) => {
                            if (indexin < 2) {
                              return (
                                <div
                                  key={indexin}
                                  className={`tag tag-${getColor(
                                    indexin + index
                                  )}`}
                                >
                                  <img src={aoe} alt="asd" />
                                  {tag.value}
                                </div>
                              );
                            } else {
                              return "";
                            }
                          })}
                        </div>
                      )}

                      <Row>
                        {doc.rating > 0 && (
                          <Col
                            xs={doctorList.length >= 3 ? 12 : 4}
                            className="d-flex align-items-center doc-stats"
                          >
                            <div className="doc-nav-img">
                              <img src={star} alt="ico" />
                            </div>

                            <div className="flex-grow-1 ms-1">
                              <span className="doc-stats-tit">Rating</span>
                              <span className="doc-stats-txt">
                                {" "}
                                {doc.rating} out of 5
                              </span>
                            </div>
                          </Col>
                        )}
                        {doc.experience >= 0 && (
                          <Col
                            xs={doctorList.length >= 3 ? 6 : 4}
                            className="d-flex align-items-center doc-stats"
                          >
                            <div className="doc-nav-img">
                              <img src={exp} alt="ico" />
                            </div>

                            <div className="flex-grow-1 ms-1">
                              <span className="doc-stats-tit">Experience</span>
                              <span className="doc-stats-txt">
                                {" "}
                                {doc.experience > 0 ? doc.experience : ""}+
                                years
                              </span>
                            </div>
                          </Col>
                        )}
                        {doc.patientsServed >= 0 && (
                          <Col
                            xs={doctorList.length >= 3 ? 6 : 4}
                            className="d-flex align-items-center doc-stats"
                          >
                            <div className="doc-nav-img">
                              <img src={nop} alt="ico" />
                            </div>

                            <div className="flex-grow-1 ms-1">
                              <span className="doc-stats-tit">Patient</span>
                              <span className="doc-stats-txt">
                                {doc.patientsServed}+
                              </span>
                            </div>
                          </Col>
                        )}
                      </Row>
                      <div className="d-btn">

                        {
                          hasDoctorFullProfile && (
                            <Link
                              to={`/doctorprofile/${doc.slug}`}
                              state={{ data: doc }}
                              className="btnr btnr-primary-outline-white mb-3 w-100 btnr-hover-Q"
                            >
                              View Profile
                              <span className="arr"></span>
                            </Link>)
                        }
                        {
                          (clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) &&
                          <button
                            onClick={() => navigateToBookAppt({ doctor: doc })}
                            className={`btnr btnr-primary-light w-100 btnr-hover-Q`}
                          >
                            Book consultation <span className="arr"></span>
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="shape shape1">
            <img src={shape1} alt="shape" />
          </div>
          <div className="shape shape2">
            <img src={shape1} alt="shape" />
          </div>
        </section>
      )}

      <section className="d-block d-sm-none doctor-card-mobile-parent">
        <div className="container">
          <div className={`dcm-sec ${doctorList.length > 1 ? "" : "oneonly"}`}>
            <div className="dc-header">
              <span className="pill">Our Doctors</span>
              <span className="title">Expert Doctors at Your Service</span>
            </div>
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={doctorList.length > 1 ? false : true}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper layoutm-track"
            >
              {doctorList.map((doc, index) => (
                <SwiperSlide className="doctor-layout-card" key={index}>
                  <div className="prfl-img">
                    <img src={doc.pictureUrl} alt="img" />
                  </div>
                  <div className="doc-info">
                    <div className="d-Name">{doc.name}</div>
                    <div className="d-Spec">{doc.title}</div>
                    <div className="d-Study">{doc.study}</div>
                    {doc.areasOfExpertiseDtos && (
                      <div className="d-tags">
                        {doc.areasOfExpertiseDtos.map((tag, index) => (
                          <div
                            key={index}
                            className={`tag tag-${getColor(index)}`}
                          >
                            <img src={aoe} alt="asd" />
                            {/* {tag.value} */}
                            {
                              tag.value.split(" ").length > 2
                                ? tag.value.split(" ").slice(0, 2).join(" ") + " ..."
                                : tag.value
                            }
                          </div>
                        ))}
                      </div>
                    )}

                    <Row>
                      {doc.rating > 0 && (
                        <Col
                          xs={12}
                          className="d-flex align-items-center doc-stats"
                        >
                          <div className="doc-nav-img">
                            <img src={star} alt="ico" />
                          </div>

                          <div className="flex-grow-1 ms-1">
                            <span className="doc-stats-tit">Rating</span>
                            <span className="doc-stats-txt">
                              {doc.rating} out of 5
                            </span>
                          </div>
                        </Col>
                      )}

                      {doc.experience > 0 && (
                        <Col
                          xs={6}
                          className="d-flex align-items-center doc-stats"
                        >
                          <div className="doc-nav-img">
                            <img src={exp} alt="ico" />
                          </div>

                          <div className="flex-grow-1 ms-1">
                            <span className="doc-stats-tit">Experience</span>
                            <span className="doc-stats-txt">
                              {doc.experience}+ years
                            </span>
                          </div>
                        </Col>
                      )}

                      {doc.patientsServed > 0 && (
                        <Col
                          xs={6}
                          className="d-flex align-items-center doc-stats"
                        >
                          <div className="doc-nav-img">
                            <img src={nop} alt="ico" />
                          </div>

                          <div className="flex-grow-1 ms-1">
                            <span className="doc-stats-tit">Patient</span>
                            <span className="doc-stats-txt">
                              {doc.patientsServed}+
                            </span>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <div className="d-btn">

                      {
                        hasDoctorFullProfile && (
                          <Link
                            to="/doctorprofile"
                            state={{ data: doc }}
                            className="btnr btnr-primary-outline mb-3 w-100"
                          >
                            View Profile
                            <span className="arr"></span>
                          </Link>)
                      }
                      {
                        (clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) &&
                        <button
                          onClick={() => navigateToBookAppt({ doctor: doc })}
                          className={`btnr btnr-primary btnr-hover`}
                        >
                          Book consultation <span className="arr"></span>
                        </button>
                      }
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurDoctors;
