import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import img from "../static/PolicyAndTerms/Policy.png"

function PrivacyPolicy() {
  const [isLoading, setIsLoading] = useState(true);

  const para = useSelector(
    (state) => state.clinic.clincObj.termsAndCondition
  ).filter((item) => item.systemName === "PrivacyPolicy")[0];

  useEffect(() => {
    if (para?.content) {
      setIsLoading(false);
    }
  }, [para]);

  console.log(para)

  const { t } = useTranslation();
  return (
    <div className="main-body privacyPolicy">
      <div className="container">
        <div className="tcp">
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-1">Privacy Policy</span>
            </Col>
          </Row>

          <Row className="pb-2">
          <div dangerouslySetInnerHTML={{ __html: para.content }} />
          </Row>
          {/* <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">This is Carrot host’s Privacy Policy</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="text">It Discusses How We Will Collect, Use, Maintain And Disclose The Information Which We Will Collect From Our Website Visitors And Customers. Our Privacy Policy Is Part Of Our Contracts. It Is Incorporated Into Our TOS And Any Other Contract We Have With Users</span>
            </Col>
          </Row>

          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">What Type Of Information Does Collect By Carrothost?</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="text">When A Visitor Visits Our Website Our Web Server Collects Basic User Information About You That Includes Your ISP's Domain, The Time You Accessed Our Web Site And The Pages From Within Our Web Site That You Visit And Is Not Personally Identifiable Information Except As Needed To Prevent Fraud Or Abuse Of The System</span>
            </Col>
          </Row>

          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">When A Customer Orders The Service We Collected The Following Information:</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="text">Traditional Contact Information Such As Mailing Address, Phone Number, And Fax Number. Internet Contact Information Such As Email Addresses.
                Credit Card Information. <br />
                Information About The User's Computer System, Such As The Web Browser Type, IP Address, And Operating System.<br />
                Non-Financial Identification Information, Such As Username And Password Combinations.</span>
            </Col>
          </Row>

          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Why Is This Website Collecting Traditional Contact Information?</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <div className="text">
                <span className="text ">We Collect Traditional Contact Information For The Following Purposes<br /><br /></span>
              </div>

              <ul className="p-list">
                <li className="text">1. Billing And Support Purposes.</li>
                <li className="text">2. Domain Registration Account Establishment.</li>
                <li className="text">3. Website Administration Purposes.</li>
                <li className="text">4. Customize The Website For The User On Multiple Visits To The Site.
                </li>

              </ul>
              <span className="text">How We Use Information</span>
              <span className="text">We Do Not Share, Rent, Sell Or Release Your Personal Information To Any Individual, Entity, Or Third Party<br /><br /></span>

              <span className="text">Carrothost May Use Personally Identifiable Information Collected Through Our Web Site To Contact Users Regarding Products And Services Offered By Carrothost And Its Trusted Affiliates, Independent Contractors, And Business Partners, And Otherwise To Enhance Customers' Experience With Carrothost And Such Affiliates, Independent Contractors, And Business Partners. Carrothost May Also Use Information Collected Through Our Web Site For Research Regarding The Effectiveness Of The Web Site And The Marketing, Advertising, And Sales Efforts of Carrothost, Its Trusted Affiliates, Independent Contractors, And Business Partners; For Example, To Provide You With Information About Products Which Might Be Of Interest To You Or To Enable You To Take Advantage Of Special Partner Programs Or Carrothost Specials. Carrothost May Also Use Customer Information To Provide Customers With System Information Or Information About New Or Upgraded Products.
              <br /><br /></span>

              <span className="text ">We May Disclose Contact Information With Your Permission When Gathering And Posting Testimonials.<br /><br /></span>
              <span className="text ">We Will Not Otherwise Disclose Customer Personal And Account Information Unless We Have Reason To Believe That Disclosing Such Information Is Necessary To Identify, Make Contact With, Or Bring Legal Action Against Someone Who May Be Causing Harm Or Interfering With The Rights Or Property Of Carrothost, Our Customers, Or Others. We May Provide Information To Regulatory Authorities And Law Enforcement Officials In Accordance With Applicable Laws Or Where Carrothost Has A Good Faith Belief That The Law Requires Such Disclosure.
              <br /><br /></span>
            </Col>
          </Row>

          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">What If I Think That You Are Not Adhering To This Statement?</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="text">If You Believe We Are Not Handling Your Information In Accordance With Our Privacy Policy, Please Contact Us. If For Some Reason We Have Violated Our Privacy Policy, We Will Correct The Error As Soon As You Notify Us.</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Changes To Our Privacy Policy</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="text">From Time To Time, We May Change Our Privacy Policy. We Will Post Those Changes On This Page So That Our Customers Will Always Be Aware Of What Information We Collect, How We Use It, And Under What Circumstances We Share The Information With Others.</span>
            </Col>
          </Row> */}

          <div className="p-image">
            <img src={img} alt="" />
          </div>

          {/* <Row>
            <Col md={12} className="para-topic term">
              {isLoading ? (
                <>
                  <Skeleton variant="rectangular" width={400} height={40} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <br />
                  <br />
                  <Skeleton variant="rectangular" width={400} height={40} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <br />
                  <br />
                  <Skeleton variant="rectangular" width={400} height={40} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <br />
                  <br />
                  <Skeleton variant="rectangular" width={400} height={40} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <br />
                  <br />
                </>
              ) : (
                <div className="para">{para.content}</div>
              )}
            </Col>
          </Row> */}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
