// useDocumentTitle.js
import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  const clinicName = useSelector(
    (state) => state.clinic.clincObj.clinic.clinicName
  );

  useEffect(() => {
    document.title = `${clinicName} ${title.length > 0 ? " - " : ""} ${title}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
}

export default useDocumentTitle;
