import React, { useEffect } from "react";
import Banners from "../components/homepagecomps/Banners";
import BannerBraphs from "../components/homepagecomps/BannerBraphs";
import { Col, Row } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App1 from "../static/home/downloadapp1.png";
import App2 from "../static/home/downloadapp2.png";
import App3 from "../static/home/el.svg";
import OurDoctors from "../components/homepagecomps/OurDoctors";
import useDocumentTitle from "../utils/useDocumentTitle";
import ApptSchedule from "../components/homepagecomps/ApptSchedule";
import Testimonials from "../components/homepagecomps/Testimonials";
import Treatments from "../components/homepagecomps/Treatments";
import AboutClinic from "../components/homepagecomps/AboutClinic";
import { useSelector } from "react-redux";
import MsgPop from "../components/headercomps/MsgPop";

function Home() {
  //set titlte
  useDocumentTitle("");

  const contactINFO = useSelector((state) => state.clinic.clinincInfo);
  const clinicDetails = useSelector((state) => state.clinic.clincObj.clinic);

  const hasDownloadAppOption = useSelector((state) => state.clinic.clincObj.clinic.hasDownloadAppOption);

  return (
    <div className="main-body">
      <Banners />
      <BannerBraphs />
      <OurDoctors />
      {
        (clinicDetails.hasVideoCallAppointment && clinicDetails.hasInPersonAppointment) && (
          <ApptSchedule />
        )
      }

      <Treatments />
      <Testimonials />
      <AboutClinic />
      {(hasDownloadAppOption && (contactINFO?.cObject?.playStore?.length > 0 ||
        contactINFO?.cObject?.appStore?.length > 0) && (
          <section className="download-home d-sm-block">
            <div className="container">
              <div className="base-cont">
                <Row>
                  <Col xs={12} md={6}>
                    <div className="left-side">
                      <div className="uorApp_pill">
                        <span className="section-pill section-pill-invert">
                          Our app
                        </span>
                      </div>
                      <div>
                        <span className="section-title section-title-primary">
                          Get right care on the move. Download the app now
                        </span>
                      </div>

                      <span className="para">
                        Our App is simple to navigate and packed with useful
                        features. It also integrates seamlessly regarding booking
                        of a doctor schedule
                      </span>
                      <div className="d-flex">
                        {contactINFO?.cObject?.appStore?.length > 0 && (
                          <a
                            href={contactINFO?.cObject?.appStore}
                            target="_blank"
                            className="btnr btnr-primary btnr-download btnr-hover"
                          >
                            APP STORE <span className="arr"></span>
                          </a>
                        )}
                        {contactINFO?.cObject?.playStore?.length > 0 && (
                          <a
                            href={contactINFO?.cObject?.playStore}
                            target="_blank"
                          className="ms-2 btnr btnr-primary btnr-download btnr-hover"
                          >
                            PLAY STORE <span className="arr"></span>
                          </a>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={5} className="right-side">
                    <img src={App1} className="app1" alt="cu" />
                    <img src={App2} className="app2" alt="cu" />
                  </Col>
                  <Col xs={1}>
                    <img src={App3} className="app2" alt="cu" />
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        ))}
        {/* <MsgPop /> */}
    </div>
  );
}

export default Home;
