import { Link } from "react-router-dom";
import logo from "../static/header/logo.svg";
import { useSelector } from "react-redux";
function Footer() {


  //check for clinic Details
  const defaultHealthCenter = useSelector(
    (state) => state.clinic.clincObj.defaultHealthCenter
  );
  const contactINFO = useSelector((state) => state.clinic.clinincInfo);

  return (
    <footer>
      <div className="top-footer-parent">
        <div className="container">
          <div className="top-footer">
            <div className="f-c-1">
              <img className="logo" src={logo} alt="logo" />
              {contactINFO.isSuccess && (
                <ul className="social-linkss">
                  {contactINFO?.cObject?.instagram?.length > 0 && (
                    <li>
                      <a href={contactINFO?.cObject?.instagram} target="_blank">
                        <span className="icon-inst-solid"></span>
                      </a>
                    </li>
                  )}
                  {contactINFO?.cObject?.facebook?.length > 0 && (
                    <li>
                      <a href={contactINFO?.cObject?.facebook} target="_blank">
                        <span className="icon-fb-solid"></span>
                      </a>
                    </li>
                  )}
                  {contactINFO?.cObject?.twitter?.length > 0 && (
                    <li>
                      <a href={contactINFO?.cObject?.twitter} target="_blank">
                        <span className="icon-twitter-solid"></span>
                      </a>
                    </li>
                  )}
                  {contactINFO?.cObject?.youtube?.length > 0 && (
                    <li>
                      <a href={contactINFO?.cObject?.youtube} target="_blank">
                        <span className="icon-youtube-solid"></span>
                      </a>
                    </li>
                  )}

                  <li className="d-none">
                    <span className="icon-linkedin-solid"></span>
                  </li>
                </ul>
              )}
            </div>
            <div className="f-c-2">
              <span className="header-f">Options</span>
              <ul>
                <li className="d-none">
                  <a href="/">Refund policy</a>
                </li>
                <li>
                  <Link to="/PrivacyPolicy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/TermsConditions">Terms And Conditions</Link>
                </li>
                <li className="d-none">
                  <a href="/">Accessibilty statement</a>
                </li>
                <li className="d-none">
                  <Link to="contact">Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="f-c-3">
              <span className="header-f">Contact</span>
              <ul>
                {defaultHealthCenter?.countryCode &&
                  defaultHealthCenter?.phoneNumber && (
                    <li>
                      <span className="fooicon">
                        <span className="icon-phone-solid fs-9 pe-2"></span>
                      </span>
                      <span>
                        <a
                          href={`tel:${defaultHealthCenter?.countryCode}${defaultHealthCenter?.phoneNumber}`}
                        >
                          + ({defaultHealthCenter?.countryCode}){" "}
                          {defaultHealthCenter?.phoneNumber}{" "}
                        </a>
                      </span>
                    </li>
                  )}
                {defaultHealthCenter?.email && (
                  <li>
                    <span className="fooicon">
                      <span className="icon-mail-line fs-9 pe-2"></span>
                    </span>
                    <span>
                      <a href={`mailto:${defaultHealthCenter?.email}`}>
                        {defaultHealthCenter?.email}
                      </a>
                    </span>
                  </li>
                )}
                {defaultHealthCenter?.address && (
                  <li>
                    <span className="fooicon">
                      <span className="icon-location-line fs-6 pe-2"></span>
                    </span>
                    <span>{defaultHealthCenter?.address}</span>
                  </li>
                )}
              </ul>
            </div>
            <div className="f-c-4">
              <span className="header-f d-none">Locate on map</span>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer-parent">
        <div className="container">
          <div className="bottom-footer">
            Copyright © {defaultHealthCenter.healthCenterName} | All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
