import React, { useState } from 'react';

const Gender = ({ onGenderChange, initFilter }) => {

    const [isOpen, setIsOpen] = useState(false);

    const genderOptions = [
        { id: 1, label: "Male Doctor", att: "male" },
        { id: 3, label: "Other", att: "other" },
        { id: 2, label: "Female Doctor", att: "female" },
    ];

    const [selectedGender, setSelectedGender] = useState(null);

    const handleGenderChange = (id) => {

        onGenderChange(genderOptions.filter((item) => item.id === id)[0].att)
    };

    return (
        <div className='gender_select'>
            <div
                // active accordion
                // className={`gender_title active ${!isOpen ? "active" : ""}`}

                // deactive accordion
                className={`gender_title active`}
            >
                Gender
                <div>
                    <span onClick={() => setIsOpen(!isOpen)} className='icon-arrow-line d-none' />
                </div>
            </div>
            <div
                // active accordion
                // className={`select_box ${!isOpen ? "active" : ""}`}
                
                // deactive accordion
                className={`select_box`}
            >
                {genderOptions.map((item) => (
                    <div
                        key={item.id}
                        onClick={() => handleGenderChange(item.id)}
                        className={`gender-option ${initFilter.gender === item.att ? 'selected' : ''}`}
                    >
                        <label htmlFor={item.id}>{item.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gender;
