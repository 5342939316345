import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Calendar from "../components/microcomponenets/Calendar";
import { useEffect } from "react";
import { useState } from "react";

import star from "../static/appt/Star.svg";
import dlr from "../static/appt/dollar.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import AppSlots from "../components/appt/AppSlots";
import {
  CheckIfDoctorAvailable,
  BookAppointmentAPI,
  GetAppointmentTotalAPI,
  GenerateTokenAPI,
} from "../services/apptservice";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Dependents from "../components/appt/Dependents";
import PaymentSummary from "../components/appt/PaymentSummary";
import AppSummary from "../components/appt/AppSummary";
import { useLayoutEffect } from "react";
import { RescheduleAppointment } from "../services/apptservice";
import GenProfileUpdate from "../components/appt/GenProfileUpdate";
import AnimatedConfirm from "../components/microcomponenets/AnimatedConfirm";

import dependent from "../static/appt/dep.svg";

import vc from "../static/appt/bka-vc.svg";
import ip from "../static/appt/bka-ip.svg";
import clk from "../static/appt/bka-clk.svg";
import cal from "../static/appt/bka-cal.svg";
import PaymentMethods from "../components/appt/PaymentMethods";
import Calendar2 from "../components/microcomponenets/Calendar2";
import NewWindow from "react-new-window";
import useDocumentTitle from "../utils/useDocumentTitle";
import ApptType from "../components/appt/ApptType";

const DocAvailObj = {
  doctorId: 0,
  ScheduleDateAtLocalTime: moment().format(),
  TimeDifferenceFromUtcInMinutes: moment().utcOffset(),
  type: "",
  processId: 0,
};

function BookAppointment() {
  useDocumentTitle("Book Appointment");
  const location = useLocation();

  const clinicDetails = useSelector((state) => state.clinic.clincObj.clinic);

  const [doctorObj, setDoctorObj] = useState(location.state?.doctor);
  const [apptType, setApptType] = useState(
    location.state?.apptType ? location.state?.apptType : (clinicDetails.hasVideoCallAppointment ? 'InPerson': (clinicDetails.hasVideoCallAppointment ? 'VideoCall': ''))
  );

  console.log(apptType,"dssds")

  const [isToBeRescheduled, setIsToBeRescheduled] = useState(
    location.state?.reschedule ? location.state?.reschedule : false
  );

  //followup things
  const [isFollowUp, setIsFollowUp] = useState(
    location.state?.followup ? location.state?.followup : false
  );
  const isFreeFollowUp = location.state?.followup
    ? location.state?.followup
    : false;

  //overall page state
  const [pageState, setPageState] = useState({});

  const user = useSelector((state) => state.user);

  const utoken = user.userObj.token;
  //slots array

  const [slots, setSlots] = useState({ slotlist: [], isLoading: false });
  const [dayInfo, setDayInfo] = useState({
    day: "",
    date: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state?.doctor) {
      navigate("/");
    } else if (user.userObj === null) {
      navigate("/selectdoctor");
    } else {
      let tempDocAvailObj = {
        ...DocAvailObj,
        doctorId: doctorObj.id,
        type: location.state?.apptType ? location.state?.apptType : (clinicDetails.hasVideoCallAppointment ? 'InPerson': (clinicDetails.hasVideoCallAppointment ? 'VideoCall': '')),
        processId: location.state?.processId ? location.state?.processId : 0,
      };

      if(location.state.dateTimeObj){
        tempDocAvailObj = {
          ...tempDocAvailObj,
          ScheduleDateAtLocalTime: location.state.dateTimeObj
        }

        setDayInfo({
          day: moment(location.state.dateTimeObj).utc().local().format("dddd"),
          date: moment(location.state.dateTimeObj).utc().local().format("DD MMMM YYYY"),
        });
      }else{
        setDayInfo({
          day: moment().format("dddd"),
          date: moment().format("DD MMMM YYYY"),
        });
      }
      
      

      callCheckIfDoctorAvailable(tempDocAvailObj);
    }
  }, []);

  // Function For ON DATE CHANGE
  const onDateChange = (date) => {
    let tempDocAvailObj = {
      ...DocAvailObj,
      ...pageState,
      ScheduleDateAtLocalTime: date,
    };

    setDayInfo({
      day: moment(date).format("dddd"),
      date: moment(date).format("DD MMMM YYYY"),
    });

    callCheckIfDoctorAvailable(tempDocAvailObj);
  };

  //Generic API CALL
  const callCheckIfDoctorAvailable = (tempDocAvailObj) => {
    setSlots({ ...slots, isLoading: true });
    CheckIfDoctorAvailable(tempDocAvailObj)
      .then((res) => {
        if (res.status === "Success") {
          setSlots({ slotlist: res.response.slots, isLoading: false });
          setPaymentDetails({
            ...res.response.paymentDetails,
          });
        }
        setPageState({ ...tempDocAvailObj });
      })
      .catch((err) => {
        if (err.response.data.status === "Fail") {
          setSlots({ slotlist: [], isLoading: false });

          setPageState({ ...tempDocAvailObj });
          setSelectedSlot("");
          setEndSelectedSlot("");
        }
      });
  };

  //getDateFromCalandar
  const getDateFromCalandar = (date) => {
    onDateChange(date);
  };

  //appt type change
  const onApptTypeChange = (selectedType) => {
    let tempDocAvailObj = {
      ...DocAvailObj,
      ...pageState,
      type: selectedType,
    };

    setApptType(selectedType);

    callCheckIfDoctorAvailable(tempDocAvailObj);
  };

  // selectedSlot
  const [selectedSlot, setSelectedSlot] = useState("");
  const [endselectedSlot, setEndSelectedSlot] = useState("");

  const [ispageOneActive, setIspageOneActive] = useState(true);

  const [isGenDrawerOpen, setIsGenDrawerOpen] = useState(false);

  // go to page 2
  const goTOpageTwo = () => {
    if (apptType === "") {
      swal({
        icon: "error",
        title: "No Appointment Type Selected",
        text: "Please Select an Appointment Type",
      });
    } else {
      if (selectedSlot === "") {
        swal({
          icon: "error",
          title: "No Slot Selected",
          text: "Please Select a Slot",
        });
      } else {
        if (
          user.userObj.patientData.dob === null ||
          user.userObj.patientData.dob === "" ||
          user.userObj.patientData.sex === null ||
          user.userObj.patientData.sex === ""
        ) {
          setIsGenDrawerOpen(true);
        } else {
          setIspageOneActive(false);
        }
      }
    }
  };

  //depandants logic

  const [dependant, setDependant] = useState(0);

  //currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  const paymentMethodList = useSelector(
    (state) => state.clinic.clincObj.avaiablePaymentMethods
  );

  //payment summary
  const [paymentDetails, setPaymentDetails] = useState({
    paymentType: paymentMethodList[0]?.systemName,
    fee: 0,
    tax: 0,
    serviceFee: 0,
  });

  //conform booking Logic

  const [isAppointmentBooked, setIsAppointmentBooked] = useState(false);
  const [confirmBookingLoader, setConfirmBookingLoader] = useState(false);

  //appt ID
  const apptIDForReschedule = location.state?.apptId;

  const [bookedState, setBookedState] = useState(null);

  const confirmBooking = () => {
    setConfirmBookingLoader(true);

    if (isToBeRescheduled) {
      let tempObj = {
        appointmentId: apptIDForReschedule,
        date: selectedSlot,
        cancelledBy: "Patient",
        cancelledById: user.userObj.patientData.id,
        timeDifferenceFromUtcInMinutes: moment().utcOffset(),
      };

      RescheduleAppointment(tempObj, utoken)
        .then((res) => {
          console.log(res);
          if (res.status === "Success") {
            // setIsAppointmentBooked(true);
            setConfirmBookingLoader(false);
            //(res.response.appointment);

            Swal.fire({
              icon: "success",
              title: "Done",
              text: "Your Appointment has been rescheduled",
              allowOutsideClick: false,
              timer: 5000,
              customClass: {
                confirmButton: "btnr btnr-primary",
              },
            }).then((result) => {
              navigate(`/appointmentsDetails/${tempObj.appointmentId}`);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setConfirmBookingLoader(false);
          swal({
            icon: "error",
            title: err.response.data.response.message,
            text: "Please try again",
          });
        });
    } else if (isFollowUp) {
      let tempObj = {
        scheduledAtUtc: selectedSlot,
        doctorId: doctorObj.id,
        patientId: user.userObj.patientData.id,
        type: apptType,
        isFollowUp: true,
        followupAppointmentId: apptIDForReschedule,
        treatmentId: location.state?.processId ? location.state?.processId : 0,
        appointmentForId: dependant,
        paymentDetails: {
          ...paymentDetails,
        },
      };

      BookAppointmentAPI(tempObj, utoken)
        .then((res) => {
          if (res.status === "Success") {
            setIsAppointmentBooked(true);
            setConfirmBookingLoader(false);
            setBookedState(res.response.appointment);
          }
        })
        .catch((err) => {
          console.log(err);
          setConfirmBookingLoader(false);
          swal({
            icon: "error",
            title: err.response.data.response.message,
            text: "Please try again",
          });
        });
    } else {
      let tempObj = {
        scheduledAtUtc: selectedSlot,
        doctorId: doctorObj.id,
        patientId: user.userObj.patientData.id,
        type: apptType,
        isFollowUp: false,
        treatmentId: location.state?.processId ? location.state?.processId : 0,
        appointmentForId: dependant,
        paymentDetails: {
          ...paymentDetails,
        },
      };

      if (paymentDetails.paymentType === "Payment.MPesa") {
        setConfirmBookingLoader(false);
        let getApptTotalsObj = {
          doctorId: doctorObj.id,
          patientId: user.userObj.patientData.id,
          type: apptType,
          isFollowUp: false,
          processId: location.state?.processId ? location.state?.processId : 0,
          paymentType: "Payment.MPesa",
        };

        GetAppointmentTotalAPI(getApptTotalsObj, utoken)
          .then((res) => {
            console.log(res);
            if (res.status === "Success") {
              setConfirmBookingLoader(false);
              let tokenObj = {
                paymentMethodSystemName: "Payment.MPesa",
                doctorId: doctorObj.id,
                type: apptType,
                processId: location.state?.processId
                  ? location.state?.processId
                  : 0,
                isFollowUp: false,
              };
              GenerateTokenAPI(tokenObj, utoken)
                .then((data) => {
                  if (data.status === "Success") {
                    createPopup(data.response.paymentUrl);
                  }
                })
                .catch((err) => {});
            }
          })
          .catch((err) => {
            setConfirmBookingLoader(false);
            swal({
              icon: "error",
              title: err.response.data.response.message,
              text: "Please try again",
            });
          });

        // swal({
        //   icon: "error",
        //   title: "Functionalty in Progress",

        // })
      } else {
        BookAppointmentAPI(tempObj, utoken)
          .then((res) => {
            if (res.status === "Success") {
              setIsAppointmentBooked(true);
              setConfirmBookingLoader(false);
              setBookedState(res.response.appointment);
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data.response.message);
            setConfirmBookingLoader(false);
            swal({
              icon: "error",
              title: err.response.data.response.message,
              text: "Please try again",
            });
          });
      }
    }
  };

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [ispageOneActive, isAppointmentBooked]);

  const processObj = location.state?.processObj
    ? location.state?.processObj
    : null;

  const navigateToUploadReports = (stateforNextPage) => {
    let newState = {
      id: 0,
      appointmentId: bookedState.id,
      reasonForVisit: "",
      attachments: [],
    };
    console.log(newState);
    navigate("/uploadreports", {
      state: newState,
    });

    //check whether user is Logged in ??
  };

  const [isPopupActive, setIsPopupActive] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");

  const handleMessage = (e) => {
    console.log(e);

    if (typeof e.data === "string" || e.data instanceof String) {
      if (JSON.parse(e.data).token) {
        console.log(JSON.parse(e.data).token);
        // setCreateOrderItems({
        //   ...createOrderItems,
        //   paymentToken: JSON.parse(e.data).token,
        // });

        setIsPopupActive(false);
      }
    }

    window.removeEventListener("message", handleMessage);
  };

  useEffect(() => {
    if (paymentUrl.length > 0) {
      setIsPopupActive(true);
      window.addEventListener("message", handleMessage);
    }
  }, [paymentUrl]);

  const createPopup = (getUrl) => {
    let url = `https://${getUrl}`;

    setPaymentUrl(url);
  };

  const clinicObj = useSelector(
    (state) => state.clinic.clincObj.clinic
  );


  return (
    user.isSuccess && (
      <div className="main-body">
        <div className="container">
          <div className="appt-main">
            {!isAppointmentBooked && (
              <div className="bk-grid">
                <div className={`bkgleft ${ispageOneActive ? "" : "hide"}`}>
                  <div className="d-sm-none d-block hus">Select Doctor</div>
                  <div className="appt-doc-profile">
                    <div className="img">
                      <img src={doctorObj?.pictureUrl} />
                    </div>
                    <div className="body">
                      <div className="name">{doctorObj?.name}</div>
                      <div className="designation">{doctorObj?.title}</div>
                      <div className="qualification">
                        {doctorObj?.qualification}
                      </div>
                      {doctorObj.rating >= 0 && (
                        <div className="icon-text">
                          <div className="icon">
                            <img className="star" src={star} alt="star" />
                          </div>
                          <div className="txt">
                            <span>Rating</span>
                            {doctorObj.rating.toFixed(2)} out of 5
                          </div>
                        </div>
                      )}

                      {(apptType !== "") && (doctorObj.inPersonConsultationFee || doctorObj.videoCallConsultationFee || processObj.price) && (
                        <div className="icon-text">
                          <div className="icon">
                            <img className="dlr" src={dlr} alt="dlr" />
                          </div>
                          <div className="txt">
                            <span>Fees</span>
                            {(apptType === "InPerson" && doctorObj.inPersonConsultationFee) &&
                              "Consultation " +
                                currencySymbol +
                                doctorObj.inPersonConsultationFee}
                            {(apptType === "VideoCall" && doctorObj.videoCallConsultationFee) &&
                              "Consultation " +
                                currencySymbol +
                                doctorObj.videoCallConsultationFee}
                            {(apptType === "Treatment" && processObj.price) &&
                              "Treatment " + currencySymbol + processObj.price}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {ispageOneActive && (
                  <>
                    <div className="bkgcenter addmargin">
                      {/* <div
                        className={` d-flex appt-cal ${isFollowUp ? "disabled" : ""}`}
                      >
                        <Calendar outputDate={getDateFromCalandar} />
                        
                      </div> */}
                      <div
                        className={` d-flex appt-cal ${
                          isFollowUp ? "disabled" : ""
                        }`}
                      >
                        <Calendar2 inputDate={location.state.dateTimeObj} outputDate={getDateFromCalandar} />
                      </div>
                    </div>
                    <div className="bkgright">
                      <ApptType
                        apptType={apptType}
                        processObj={processObj}
                        outPutFn={(selectedType) => {
                          onApptTypeChange(selectedType);
                        }}
                        reschedule={isToBeRescheduled}
                        followUP={isFollowUp}
                      />
                      <AppSlots
                        slots={slots.slotlist}
                        isLoading={slots.isLoading}
                        dayInfo={dayInfo}
                        opSlot={(slot, eslot) => {
                          setSelectedSlot(slot);
                          setEndSelectedSlot(eslot);
                        }}
                      />
                      <div className="d-flex justify-content-center">
                        <button
                          className="btnr btnr-primary btnr-hover"
                          onClick={() => goTOpageTwo()}
                        >
                          proceed to book
                          <span className="arr"></span>
                        </button>
                      </div>
                    </div>
                    <GenProfileUpdate
                      setIspageOneActive={setIspageOneActive}
                      user={user}
                      isGenDrawerOpen={isGenDrawerOpen}
                      setIsGenDrawerOpen={setIsGenDrawerOpen}
                    />
                  </>
                )}
                {!ispageOneActive && (
                  <>
                    <div className="bkgcenter">
                      <div
                        className={`${
                          !isToBeRescheduled && !isFollowUp ? "" : "disabled"
                        } apt-dependents`}
                      >
                        <Dependents
                          user={user}
                          outputDependantId={(depId) => setDependant(depId)}
                        />
                      </div>

                      <PaymentSummary
                        paymentDetails={paymentDetails}
                        setPaymentDetails={setPaymentDetails}
                        apptType={apptType}
                        doctorObj={doctorObj}
                        processObj={processObj}
                        isFreeFollowUp={isFreeFollowUp}
                        isFollowUp={isFollowUp}
                      />
                    </div>
                    <div
                      className={`bkgright ${ispageOneActive ? "" : "hide"}`}
                    >
                      <AppSummary
                        date={dayInfo.date}
                        endselectedSlot={endselectedSlot}
                        selectedSlot={selectedSlot}
                        apptType={apptType}
                        processObj={processObj}
                        clinicObj={clinicObj}
                      />

                      <PaymentMethods
                        setPaymentDetails={setPaymentDetails}
                        paymentDetails={paymentDetails}
                      />

                      <div className="d-flex justify-content-center">
                        <button
                          disabled={confirmBookingLoader}
                          className="btnr btnr-primary btnr-hover"
                          onClick={() => confirmBooking()}
                        >
                          {confirmBookingLoader && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Confirm Booking
                          <span className="arr"></span>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {isAppointmentBooked && (
              <Row>
                <Col xs={12} className=" apptbkd">
                  <div className="appt-det-parent">
                    <div className="animation">
                      <AnimatedConfirm />
                    </div>
                    <div className="tit">Booking Confirmed</div>
                    <div className="details">
                      <div className="title">Booking Summary</div>
                      <Row>
                        <Col xs={12} sm={4}>
                          <div className="icon-text">
                            <div className="icon rnd">
                              <img
                                className="star"
                                src={bookedState.patientPictureUrl}
                                alt="star"
                              />
                            </div>
                            <div className="txt">
                              <span>Patient name</span>
                              {bookedState.patientName}
                            </div>
                          </div>
                        </Col>
                        {bookedState.appointmentForId && (
                          <Col xs={12} sm={4}>
                            <div className="icon-text">
                              <div className="icon">
                                <img
                                  className="star"
                                  src={dependent}
                                  alt="star"
                                />
                              </div>
                              <div className="txt">
                                <span>Depandant name</span>
                                {bookedState.name}
                              </div>
                            </div>
                          </Col>
                        )}

                        <Col xs={12} sm={4}>
                          <div className="icon-text">
                            <div className="icon">
                              <img className="star" src={clk} alt="star" />
                            </div>
                            <div className="txt">
                              <span>Time</span>
                              {moment
                                .utc(bookedState.scheduledAtUtc)
                                .local()
                                .format("hh:mm A")}
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={4}>
                          <div className="icon-text">
                            <div className="icon">
                              <img className="star" src={cal} alt="star" />
                            </div>
                            <div className="txt">
                              <span>Date</span>
                              {moment
                                .utc(bookedState.scheduledAtUtc)
                                .local()
                                .format("DD, MMMM YY")}
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={4}>
                          <div className="icon-text">
                            <div className="icon rnddoc">
                              <img
                                className="star"
                                src={bookedState.doctorPictureUrl}
                                alt="star"
                              />
                            </div>
                            <div className="txt">
                              <span>Doctor name</span>
                              {bookedState.doctorName}
                            </div>
                          </div>
                        </Col>

                        {bookedState.appointmentType !== "Treatment" && (
                          <Col xs={12} sm={4}>
                            <div className="icon-text">
                              <div className="icon">
                                {bookedState.appointmentType === "InPerson" && (
                                  <img className="star" src={ip} alt="star" />
                                )}
                                {bookedState.appointmentType ===
                                  "VideoCall" && (
                                  <img className="star" src={vc} alt="star" />
                                )}
                                {bookedState.appointmentType ===
                                  "Treatment" && (
                                  <img className="star" src={star} alt="star" />
                                )}
                              </div>
                              <div className="txt">
                                <span>Appointment type</span>
                                {bookedState.appointmentType === "InPerson" && (
                                  <>In Person</>
                                )}
                                {bookedState.appointmentType ===
                                  "VideoCall" && <>Video Call</>}
                                {bookedState.appointmentType ===
                                  "Treatment" && <>Treatment</>}
                              </div>
                            </div>
                          </Col>
                        )}

                        {bookedState.treatment && (
                          <Col xs={12} sm={4}>
                            <div className="icon-text">
                              <div className="icon rnddoc">
                                <img
                                  className="star"
                                  src={bookedState.treatment.pictureUrl}
                                  alt="star"
                                />
                              </div>
                              <div className="txt">
                                <span>Treatment Name</span>
                                {bookedState.treatment.name}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <div className="btn-tray">
                      <Link to="/" className="btnr btnr-primary-outline btnr-hover">
                        Go Back To Home <span className="arr"></span>
                      </Link>
                      <button
                        onClick={() => navigateToUploadReports(null)}
                        className="btnr btnr-primary btnr-hover"
                      >
                        Upload Documents <span className="arr"></span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
        <div className={isPopupActive ? "payment-overlay" : "d-none"}></div>
        {isPopupActive && (
          <NewWindow
            url={paymentUrl}
            onUnload={() => setIsPopupActive(false)}
            features={{
              left: window.screenX + (window.outerWidth - 800) / 2,
              top: window.screenY + (window.outerHeight - 600) / 2.5,
              width: 800,
              height: 600,
            }}
          />
        )}
      </div>
    )
  );
}

export default BookAppointment;
