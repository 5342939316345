import React from "react";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import img from "../static/PolicyAndTerms/Term.png";

function TermsConditions() {
  const [isLoading, setIsLoading] = useState(true);

  const para = useSelector(
    (state) => state.clinic.clincObj.termsAndCondition
  ).filter((item) => item.systemName === "TermsAndConditions")[0];

  useEffect(() => {
    if (para?.content) {
      setIsLoading(false);
    }
  }, [para]);

  console.log(para)

  const { t } = useTranslation();
  return (
    <div className="main-body TermsConditions">
      <div className="container">
        <div className="tcp">
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-1">Terms And Condition</span>
            </Col>
          </Row>
          <Row className="pb-2 pt-5 mt-3">
          <div dangerouslySetInnerHTML={{ __html: para.content }} />
          </Row>
          {/* <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Service Contract</span>
              <span className="text">
                CarrotHost Service Contract Where the content states: “We” or “Us” or “Our” includes CarrotHost or any party acting on CarrotHost’s implicit instructions. “You” or “Your” or “Reseller” or “client” or “customer” includes the person purchasing the services and/or any party acting on the customer’s instructions. “Member” includes the purchaser of services and/or any party acting on the purchaser’s instructions. “The Registrant” includes the person applying for a domain name or any party acting on the Registrant’s instructions. “The Registry” refers to the relevant domain names Registry. “Server” means the computer server equipment in connection with the provision of the Services. “Web Site” means the area on the Server allocated by us to you for use by you as a site on the Internet. “TOS” includes this agreement. ExonHost reserves the right to modify this policy at any time, effective immediately upon posting of the modification to this URL: http://www.carrothost.com/tos.htm. In consideration of the mutual covenants herein, the parties agree to the following, which shall apply during the term of this agreement. By using any portion of CarrotHost’s service, you are agreeing to this service agreement on this document entirely.<br /><br />

                This document is the contract between You and CarrotHost.<br /><br />

                <span className="link">
                  For domain related agreements please visit  <Link to="https://www.carrothost.com/registration-agreement.html" target="_blank" rel="noopener noreferrer">
                    https://www.carrothost.com/registration-agreement.html
                  </Link>
                </span>
              </span>
            </Col>
          </Row>

          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Refund Policy</span>
              <span className="text">
                If you are unhappy with any aspect of our service, please contact our support department who will attempt to rectify the situation. If the problem cannot be fixed and you are unhappy with the service you are entitled to a refund within 30 Days. There are no refunds past the 30 Days period. <br /><br />

                Refunds are not available for.COM, .NET, .ORG or any other domain names, SSL Certificates, Domain Transfer In fees, Domain Transfer Out fees, or any other products or services provided by CarrotHost through other companies.<br /><br />

                No refunds are provided for VPS or Dedicated servers under any circumstances.<br /><br />

                No refunds will be made if we terminate your hosting or other services due to violation of our Acceptable Use Policy or any other violation of our Terms and Conditions, except as may be provided therein. This does not affect your statutory rights.<br /><br />

                Credit on your account cannot be refunded and must be used for purchases and renewals.<br /><br />

                <span className="deff">
                  Setup fees are non-refundable.<br /><br />
                </span>

                Accounts purchased under a promotion, with or without a promo code, are excluded from the 30 Days money-back guarantee.<br /><br />

                <span className="deff">
                  Any funds sent to CarrotHost which are in excess of the amount owed can only be reimbursed in credit and cannot be refunded.<br /><br />
                </span>

              </span>
            </Col>
          </Row>

          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">General Terms and Policies</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">
                You agree to comply with these Terms and Policies. Any violation of either, these Terms and Policies or the other rules, regulations, or policies noted above may serve as cause for CarrotHost to suspend or terminate your account. You agree that CarrotHost has the right, with or without notice, to suspend or terminate your account upon the first or subsequent occurrence of any of the following
                <br /><br />

                * Using the Service in a way, which constitutes a violation of any applicable statute, law, court order, tariff, regulation, or treaty (including, but not limited to, intellectual property, communications, privacy, criminal and international law)<br /><br />

                * Hosting websites dedicated to pornography. Legal adult content is allowed however it cannot be the main focus of your website.<br /><br />

                * Using the Service in a manner intended to abuse or violate the privacy or property rights of others, including but not limited to sending of unsolicited bulk e-mail (“spamming”); this ground for suspension or termination is separate from and in addition to the fees which will result from such activity. You agree to pay a clean-up fee of $50 per hour if any of our server IP addresses appear on a blacklist as a result of spam from your account.<br /><br />

                * Using your hosting as a file or image hosting service.<br /><br />

                * Using the Service in an attempt to break security, or so as to actually break the security of any computer network (including the Service itself), or to access an account, message, or file which does not belong to you.<br /><br />

                * Using your account as a backup service. All files must be part of your website and must be linked to your account. Dedicated server clients are exempt from this.<br /><br />

                * Using the Service in such a way as to forge or misrepresent headers, addresses, or other identification in electronic mail or websites, or using any other method to disguise the sender’s identity or location. This includes the hosting of Proxy Server applications.<br /><br />

                * Uploading, linking to, or storing warez, cracks, or other pirated software.<br /><br />

                * Assisting in or directly distributing copyrighted material.<br /><br />

                * Excessively using the Service in such a way as to limit the bandwidth available to others.<br /><br />

                * Providing fake or incomplete contact details, including name, postal address, and telephone number.<br /><br />

                * Using the Service to operate server programs, including, but not limited to mail servers, IRC servers, game servers, FTP servers, Web servers, or streaming audio/video servers.<br /><br />

                * Using the Service for unauthorized relays through any third-party systems.<br /><br />

                * Attempting, in any way, to interfere with or deny service to any user or any host on the Internet.<br /><br />

                * Performing a chargeback on any transactions past or present will result in account suspension until resolved. If a chargeback is issued we reserve the right to remove your account and deny any future business.<br /><br />

                * Using the Service for mail bombing, which includes any instance where multiple messages are sent to a specific destination with the intent to render the recipient and/or the electronic system serving that recipient dysfunctional.<br /><br />

                * Using the Service to add or attempt to add addresses to any mailing list (yours or a third party’s) without the explicit positive consent of the addressee(s).<br /><br />

                * Attempting to cancel, supersede, or otherwise interfere with email other than your own.<br /><br />

                * Engaging in harassment, whether through language, frequency, or size of messages, either with email or website content.<br /><br />

                * Using the Service to engage in syn flood attacks, which are defined as overburdening a recipient computer system by sending a high volume of spurious data which effectively impedes or totally disables functionality of the recipient system(s) or any other methods of denial-of-service attacks.<br /><br />

                * Using the service to host phishing content.<br /><br />

                * Furnishing false data on your sign-up form, contract, or online application, including providing fraudulent credit card or other payment information.<br /><br />

                * No bill credit will be given for a period of suspension. In the event of termination of your use of the Service under this section, CarrotHost may at its sole discretion retain any or all amounts you have paid for use of the Service as liquidated damages for your actions.<br /><br />

                * Security – You agree not to access or attempt to access private areas of the Service. You agree to notify CarrotHost as soon as you become aware of unauthorized use of your account and/or any breach or attempted breach of security on the Service.<br /><br />

                * Intellectual Property – CarrotHost does not undertake to examine or review messages, files, or other materials, which are accessible through, pass through, or reside on the Service. Complaints regarding alleged copyright infringement can be sent to abuse@carrothost.com.<br /><br />

                * Continuing a specific identification of the allegedly infringing material and the location(s) on CarrotHost facilities where the materials are to be found. Upon receipt of such written notice, CarrotHost will expeditiously remove or block access to the allegedly infringing material, and provide notice to the person who had posted that material. If CarrotHost receives a notification from that person indicating that the claim of infringement was based upon mistake or misidentification, CarrotHost will send you a copy of that notification. Unless you notify CarrotHost of appropriate court action to restrain the alleged infringement, the challenged material will then be restored or otherwise made accessible again.<br /><br />

                * Liability – You agree that the use of the Service is at your own risk. Except for information, products, or services clearly identified as being supplied by CarrotHost, neither CarrotHost nor any of its affiliate controls, provides, operates, or is in any way responsible for any information, products, or services accessible through the Service. CarrotHost neither endorses nor is responsible for the accuracy of third-party material(s), and you agree that CarrotHost is not responsible for any loss or damage caused by your use of, or reliance on, such material(s). You understand and agree that you have sole responsibility for your posting of any information or material to any site or newsgroup on the Internet, including but not limited to postings to Web sites, whether residing on CarrotHost equipment or not, postings to newsgroups, and participation in any on-line chat sessions. You agree to indemnify and hold harmless CarrotHost and its employees, and other customers and subscribers from and against any claims, losses, costs, liability, damages, or expenses arising out of your postings.<br /><br />

                * You agree that, should you use the Service to send or receive voice communications, CarrotHost is not acting as a telecommunications carrier or telephone, that there is no representation made by CarrotHost as to the suitability of the Service for such use, and that all risk of connection, transmission quality, and accuracy of communications is solely on you, and that CarrotHost has no liability of any sort for the failure or lack of quality of such use of the Service.<br /><br />

                * You agree to be liable for any damages or loss of service which results in damages to CarrotHost as a result of any spamming or other violations of Section 5 above. These damages include, but are not limited to, system shut downs, retaliatory attacks or data flooding, and loss of peering arrangements. You agree that CarrotHost may pursue any such claims against you in Court.<br /><br />

                * You agree that in no event will CarrotHost have financial liability to you.<br /><br />

                * Warranty – CarrotHost MAKES NO EXPRESS OR IMPLIED WARRANTIES, INCLUDING WARRANTIES OF TITLE, NON-INFRINGEMENT, AND WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, REGARDING THE SERVICE, OR ANY SOFTWARE OR OTHER MATERIAL AVAILABLE THROUGH THE SERVICE, REGARDLESS OF WHAT YOU MAY HAVE TOLD ExonHost ABOUT YOUR INTENDED USE OF THE SERVICE. NO ORAL ADVICE OR INFORMATION GIVEN BY CarrotHost OR ITS EMPLOYEES SHALL CREATE A WARRANTY OR OTHERWISE ALTER THE PROVISIONS OF THIS SECTION.<br /><br />

                * THE SERVICE AND ANY SOFTWARE PROVIDED TO YOU BY CarrotHost ARE PROVIDED ON AN “AS IS, AS AVAILABLE” BASIS; CarrotHost DOES NOT WARRANT THAT THEY WILL BE ERROR-FREE AND/OR UNINTERRUPTED, OR THAT DEFECTS WILL BE CORRECTED. CarrotHost DOES NOT GUARANTEE THE SECURITY OR BACK-UP OF ANY OF YOUR DATA WHICH MAY BE STORED ON THE SERVICE.<br /><br />
              </span>
            </Col>
          </Row>

          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Content</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">You understand and agree that information and access available through the Service may include controversial, sexually explicit, or other material that may be offensive to you or users for whom you are responsible. CarrotHost has no responsibility for or control over such materials, and you take sole responsibility for using any available screening software or other methods of limiting access (specifically including the access of minors) to any material you may find objectionable.</span>

            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Governing Law</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">These Terms and Policies are governed in all respects under Bangladeshi laws and International Laws</span>

            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Cancellation Procedure</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">Cancellation requests must be submitted through the client area. To do this, click the icon next to the package you wish to cancel then press ‘Request Cancellation’. You must then complete and submit the form.<br /><br />

                Choosing an immediate cancellation means your service will be terminated with 24 hours of submitting the form.<br /><br />

                It is your responsibility to ensure any PayPal subscriptions you have set up are canceled.<br /><br />

                No other forms of cancellation are accepted.<br /><br />

                Cancellations must be submitted before the invoice for that billing cycle is due. Failure to do so will result in the invoice being due. Cancellation requests can only be submitted if the account has no due invoices.<br /><br />

                Dedicated server cancellations must be submitted a minimum of 15 days prior to the renewal date or you may be charged for the next billing period.</span>

            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Resellers</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">Resellers may use their website for personal use or sell to their own clients. Resellers cannot host free accounts under any circumstances.</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Transfer</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">Resellers may use their website for personal use or sell to their own clients. Resellers cannot host free accounts under any circumstances.</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Discount Vouchers</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">Only one voucher can be used per transaction. <br />
                If the order value is lower than the value of the voucher, the remaining amount is lost. <br />
                Discount vouchers are restricted to one per customer. <br />
                Vouchers can be redeemed against the purchase of any Shared, Reseller, or Dedicated service.</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Account Payments</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">If a shared/reseller account has a past-due invoice, we will perform the following actions.<br />
                1-3 Days – No Action Taken<br />
                4 Days – Accounts are suspended<br />
                4 Days – Late Fee Added<br />
                30 Days – Accounts will be terminated.<br /><br />

                If a VPS has a past due invoice, we will perform the following actions.<br />
                1-3 Days – No Action Taken<br />
                4 Days – Accounts are suspended<br />
                7 Days – Accounts are terminated<br /><br />

                Dedicated servers will be suspended as soon as an invoice is overdue.</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Over usage</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">Per GB Disk space over usages fee $1 Per 10 GB Bandwidth over usages fee $2</span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={12} className="d-flex flex-column">
              <span className="header-2">Amendments</span>
            </Col>
            <Col md={12} className="d-flex flex-column">
              <span className="text">These Terms and Policies may be amended in any respect at any time by CarrotHost upon the posting of the amended Terms and Policies on the terms of use section of the Service. Your continued use of the Service will be deemed consent to any such amended Terms and Policies. If you do not wish to continue to use the Service as a result of any such amended Terms and Policies, you may provide notice of your wish to terminate your use of the Service to CarrotHost.</span>
            </Col>
          </Row> */}

          <div className="p-image">
            <img src={img} alt="" />
          </div>


          {/* <Row>
            <Col md={12} className="para-topic">
              {isLoading ? (
                <>
                  <Skeleton variant="rectangular" width={400} height={40} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <br />
                  <br />
                  <Skeleton variant="rectangular" width={400} height={40} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <br />
                  <br />
                  <Skeleton variant="rectangular" width={400} height={40} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <br />
                  <br />
                  <Skeleton variant="rectangular" width={400} height={40} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <br />
                  <br />
                </>
              ) : (
                <div className="para">{para.content}</div>
              )}
            </Col>
          </Row> */}
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
