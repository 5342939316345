import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { authentication } from "../../utils/firebase";
import OtpInput from "react-otp-input";
import Spinner from "react-bootstrap/Spinner";

//import { clientLoginPhoneNumber } from "../../services/UserService";
import { setLocalData } from "../../utils/localStorage";

import { useDispatch, useSelector } from "react-redux";
//import { updateUser } from "../../redux/userSlice";

//import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";

import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import CountryCodeSelector from "../microcomponenets/CountryCodeSelector";
import ExternalAuth from "./ExternalAuth";

import { AnimatePresence, motion } from "framer-motion";

import moment from "moment";

import UserForm from "./UserForm";
import { updateLoginDrawerState } from "../../redux/webAppSlice";
import { getUserDetailsRedux } from "../../redux/userSlice";

function Phone(props) {
  const { setIsSetupFormActivated, isSetupFormActivated } = props;
  const { t } = useTranslation();

  //animation
  const containerVariats1 = {
    hidden: {
      opacity: 0,
      x: "100vw",
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 0,
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
    exit: {
      opacity: 0,

      x: "-460px",
      transition: {
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
  };

  const containerVariats = {
    hidden: {
      opacity: 0,
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.4,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
    exit: {
      opacity: 0,

      x: "-460px",
      transition: {
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
  };

  let startDate = React.useRef(Date.now());

  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: `${process.env.REACT_APP_DEFAULT_COUNTRYCODE}`,
    number: "",
  });

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recapta-verify",
      {
        size: "invisible",
        callback: (response) => {
          return new Promise(function (resolve, reject) {
            //Your code logic goes here

            //Instead of using 'return false', use reject()
            //Instead of using 'return' / 'return true', use resolve()

            resolve();
          }); //end promise
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      authentication
    );
  };

  const getOtp = (e) => {
    e.preventDefault();

    setLoading(true);
    if (!window.recaptchaVerifier) {
      generateRecaptcha();
    }

    let appVerifier = window.recaptchaVerifier;
    let finalPhone = "+" + phoneNumber.countryCode + phoneNumber.number;

    signInWithPhoneNumber(authentication, finalPhone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).

        window.confirmationResult = confirmationResult;
        setOptGenerated(true);
        setLoading(false);
        window.recaptchaVerifier = null;
        startDate.current = Date.now();
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: t("lcp-t-1"),
          text: t("lcp-t-2"),
        });
        console.log(error);
        window.recaptchaVerifier.render().then(function (widgetId) {
          window.recaptchaVerifier.recaptcha.reset(widgetId);
        });
        setLoading(false);
      });
  };

  const [loading, setLoading] = useState(false);
  const [optGenerated, setOptGenerated] = useState(false);
  const [otp, setOtp] = useState("");

  const [verifyOptLoading, setVerifyOptLoading] = useState(false);

  const handleChange = (otpN) => {
    setOtp(otpN);
  };

  const dispatch = useDispatch();

  const verifyOtp = (e) => {
    e.preventDefault();

    setVerifyOptLoading(true);
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;

      confirmationResult
        .confirm(otp)
        .then((result) => {
          let loginObj = {
            loginType: "Phone",
            countryCode: phoneNumber.countryCode,
            phoneNumber: phoneNumber.number,
            isPhoneVerified: true,
            externalIdentifier: result.user.uid,
            oAuthAccessToken: result._tokenResponse.idToken,
          };

          dispatch(getUserDetailsRedux(loginObj));
        })
        .catch((error) => {
          setVerifyOptLoading(false);
          console.log(error)
          swal({
            icon: "error",
            title: "Invalid OTP",
            text: "Enter Correct OTP",
          });
        });
    }
  };

  const changeNumber = () => {
    setPhoneNumber({
      countryCode: `${process.env.REACT_APP_DEFAULT_COUNTRYCODE}`,
      number: "",
    });
    setOtp("");
    setOptGenerated(false);
    window.recaptchaVerifiertwo = null;
  };

  const regenerateRecaptcha = () => {
    window.recaptchaVerifiertwo = new RecaptchaVerifier(
      "recapta-verify",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      authentication
    );
  };

  const resendOtp = () => {
  

    if (!window.recaptchaVerifiertwo) {
      regenerateRecaptcha();
    }

    let appVerifier2 = window.recaptchaVerifiertwo;
    let finalPhone = "+" + phoneNumber.countryCode + phoneNumber.number;

    signInWithPhoneNumber(authentication, finalPhone, appVerifier2)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).

        window.confirmationResult = confirmationResult;

        startDate.current = Date.now();
        //window.recaptchaVerifier = null;
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: t("lcp-t-1"),
          text: t("lcp-t-5"),
        });
        window.recaptchaVerifiertwo.render().then(function (widgetId) {
          window.recaptchaVerifiertwo.recaptcha.reset(widgetId);
        });
      });
  };

  const checkNumber = (e) => {
    let tempNumber = e.target.value;
    let isnum = /^\d+$/.test(tempNumber);

    if (isnum || tempNumber === "") {
      setPhoneNumber({ ...phoneNumber, number: e.target.value });
    }
  };

  //form

  const userDetails = useSelector((state) => state.user);

  //deeplinking
  const deepLinkingObj = useSelector((state) => state.webapp.deepLinking);
  const navigate = useNavigate();

  //verification successfull
  useEffect(() => {
    if (userDetails.isSuccess) {
      window.recaptchaVerifier = null;
      setVerifyOptLoading(false);
      //is new user
      // if (result._tokenResponse.isNewUser) {
      //   setIsSetupFormActivated(true);
      // } else {
      //   dispatch(updateLoginDrawerState(false));
      // }

      if (deepLinkingObj.toLink.length > 0) {
        navigate(deepLinkingObj.toLink, { state: deepLinkingObj.state });
      }

      dispatch(updateLoginDrawerState(false));
    }
  }, [userDetails.isSuccess]);

  return (
    <div className="combinedlogin">
      <div className="combinedlogin-c">
        <AnimatePresence>
          {!optGenerated && !isSetupFormActivated && (
            <motion.div
              variants={containerVariats1}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <div className="login-phone">
                <span className="phone-title">Your phone number</span>
                <form onSubmit={getOtp} className="row form-login-phone">
                  <Col md={12} className="text-center">
                    <div id="recapta-verify"></div>
                  </Col>

                  <Col md={12}>
                    <Row className="phone-no-grp">
                      <Col xs={3}>
                        <CountryCodeSelector
                          defaultValue={
                            process.env.REACT_APP_DEFAULT_COUNTRYCODE
                          }
                          phoneNumber={phoneNumber}
                          setPhoneNumber={setPhoneNumber}
                        />
                      </Col>
                      <Col xs={9} className="inputmix">
                        <span></span>
                        <input
                          placeholder="987632XX"
                          className="phoneNumber-login"
                          value={phoneNumber.number}
                          type="text"
                          onChange={(e) => checkNumber(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="text-center">
                    <button
                      type="submit"
                      disabled={
                        loading || phoneNumber.number.length < 1
                          ? "disabled"
                          : ""
                      }
                      className="btnr btnr-primary mt-3"
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      <span className="visually-hidden">Loading...</span>
                      Get OTP
                    </button>
                  </Col>
                </form>
              </div>
              <div className="login-external">
                <ExternalAuth />
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {optGenerated && !isSetupFormActivated && (
            <motion.div
              variants={containerVariats}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <form onSubmit={verifyOtp} className="verify-form">
                <Row>
                  <Col md={12} className="text-center">
                    <div id="recapta-verify"></div>
                  </Col>
                  <Col xs={12} className="text-1">
                    Time to get it Verified!
                  </Col>
                  <Col xs={12} className="text-2">
                    Please enter the 6 Digit OTP code sent to
                  </Col>

                  <Col xs={12} className="text-3">
                    <span>
                      {phoneNumber.countryCode} {phoneNumber.number}
                    </span>{" "}
                    through SMS
                  </Col>

                  <Col md={12} className="text-center otp-boxes mb-4">
                    <OtpInput
                      isInputNum={true}
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      renderInput={(props) => <input {...props} />}
                    />
                  </Col>
                  <Col
                    xs={12}
                    className="verify-4 d-none"
                    onClick={() => changeNumber()}
                  >
                    change number
                  </Col>
                  <Col xs={12} className="text-center">
                    <button type="submit" className="btnr btnr-primary mt-2">
                      {verifyOptLoading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      VERIFY
                    </button>
                  </Col>
                  <Col xs={12} className="text-center">
                    <span className="resend-code" onClick={() => resendOtp()}>
                      Resend Code
                    </span>
                  </Col>
                </Row>
              </form>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {optGenerated && isSetupFormActivated && (
            <motion.div
              variants={containerVariats1}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <UserForm />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {!isSetupFormActivated && (
        <div className="login-pp">
          By logging, you agree to our <a href="/TermsConditions">Terms & Conditions</a> and{" "}
          <a href="/PrivacyPolicy">PrivacyPolicy</a>.
        </div>
      )}
    </div>
  );
}

export default Phone;
