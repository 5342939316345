import axios from "axios";
const clinicId = process.env.REACT_APP_CLINIC_ID;

export const GetPatientNotificationWithPaginationAPI = async (obj) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetPatientNotificationWithPagination/`,
    {
      ...obj,
      clinicId,
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};


export const MarkAllReadPatientNotificationAPI = async (patinetId) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/MarkAllReadPatientNotification?patientId=${patinetId}`
    
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};


// export const CancelAppointment = async (obj, token) => {
//   const promise = axios.post(
//     `/${process.env.REACT_APP_API_VERSION_V1}/CancelAppointment`,
//     {
//       clinicId,
//       ...obj,
//     },
//     {
//       headers: { Authorization: `Bearer  ${token}` },
//     }
//   );

//   const dataPromise = promise.then((response) => response.data);

//   return dataPromise;
// };
