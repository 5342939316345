import React from "react";
import GoogleLogin from "./GoogleLogin";

function ExternalAuth() {
  return (
    <>
      <span className="or-login">Signin / Signup</span>
      <GoogleLogin />
    </>
  );
}

export default ExternalAuth;
