import React from "react";
import lottie from "lottie-web";
import * as animationData from "../../static/Animation/confirmed.json";
import { useEffect } from "react";

function AnimatedConfirm() {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: animationData,
      renderer: "svg", // "canvas", "html"
      loop: false, // boolean
      autoplay: true, // boolean
    });
  }, []);

  return <div id="react-logo" />;
}

export default AnimatedConfirm;
