import React, { useEffect, useState } from "react";
import { MeetingAppProvider } from "../VideoSDK/MeetingAppContextDef";
import { MeetingProvider } from "@videosdk.live/react-sdk";

import { MeetingContainer } from "../VideoSDK/meeting/MeetingContainer";

const rtc = {
  client: null,

  localAudioTrack: null,
  localVideoTrack: null,
  remoteVideoTrack: null,
};
// AgoraRTC.setLogLevel(4);

// let agoraCommandQueue = Promise.resolve();

// const createAgoraClient = ({ onVideoTrack, onUserDisconnected }) => {
//   const client = createClient({
//     mode: "rtc",
//     codec: "vp8",
//   });

//   let tracks;

//   const waitForConnectionState = (connectionState) => {
//     return new Promise((resolve) => {
//       const interval = setInterval(() => {
//         if (client.connectionState === connectionState) {
//           clearInterval(interval);
//           resolve();
//         }
//       }, 200);
//     });
//   };

//   const connect = async (APP_ID, CHANNEL, TOKEN, UID) => {
//     await waitForConnectionState("DISCONNECTED");

//     const uid = await client.join(APP_ID, CHANNEL, TOKEN, parseInt(UID));

//     //console.log(uid, UID)

//     client.on("user-published", (user, mediaType) => {
//       client.subscribe(user, mediaType).then(() => {
//         if (mediaType === "video") {
//           onVideoTrack(user);
//         }
//       });
//     });

//     client.on("user-left", (user) => {
//       onUserDisconnected(user);
//     });

//     tracks = await AgoraRTC.createMicrophoneAndCameraTracks();

//     await client.publish(tracks);

//     return {
//       tracks,
//       uid,
//     };
//   };

//   const disconnect = async () => {
//     await waitForConnectionState("CONNECTED");
//     client.removeAllListeners();
//     for (let track of tracks) {
//       track.stop();
//       track.close();
//     }
//     await client.unpublish(tracks);
//     await client.leave();
//   };

//   return {
//     disconnect,
//     connect,
//   };
// };


const VideoCallScreen = (props) => {
 
  const { tokenResponse, changeState,apptDetails } = props;

  const AGORA_TOKEN = tokenResponse.token;
  const AGORA_CHANNEL = tokenResponse.channel;
  const AGORA_APPID = tokenResponse.appID;
  const AGORA_UID = tokenResponse.uid;

  console.log(tokenResponse.token);
  console.log(tokenResponse.roomId);

  const [token, setToken] = useState(tokenResponse.token);
  const [meetingId, setMeetingId] = useState(tokenResponse.roomId);
  const [participantName, setParticipantName] = useState("");
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );

  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(true);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);

  useEffect(() => {
    
  if(isMeetingLeft){
    changeState();
  }
  }, [isMeetingLeft])
  
  

  return (
    <>
    {isMeetingStarted && (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : "TestUser",

              multiStream: true,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              onMeetingLeave={() => {
                setToken("");
                setMeetingId("");
                setParticipantName("");
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
              apptDetails={apptDetails}
            />
          </MeetingProvider>
        </MeetingAppProvider>
      )}
    </>
  );
};

export default VideoCallScreen;
