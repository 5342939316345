import React from "react";
import cv from "../../static/home/cv.png";
import vv from "../../static/home/vv.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const appScheduleList = [
  {
    title: "Book Your Clinic Visit",
    txt: "Experience high-quality healthcare with ongoing support during each visit to our clinic",
    cta: "Schedule Visit",
    virutal: false,
  },
  {
    title: "Virtual Visit on Video Call",
    txt: "Get quality healthcare from anywhere with convenient video consultations and expert guidance",
    cta: "Book Consultation",
    virutal: true,
  },
];
function ApptSchedule() {
  const dispatch = useDispatch();

  return (
    appScheduleList.length > 0 && (
      <section>
        <div className="container">
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={false}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            breakpoints={{
              992: {
                slidesPerView: 2,
                spaceBetween: 20,
                centeredSlides: false,
              },
              1200: {
                slidesPerView: 2,
                spaceBetween: 40,
                centeredSlides: false,
              },
              1480: {
                slidesPerView: 2,
                spaceBetween: 50,
                centeredSlides: false,
              },
            }}
            className="app-sch-card-parent mySwiper"
          >
            <>
              {appScheduleList.map((appt, i) => (
                <SwiperSlide
                  className={`app-sch-card ${appt.virutal ? "type1" : "type2"}`}
                  key={i}
                >
                  <div className="header">
                    {appt.virutal ? (
                      <>Virtual Visit on Video Call</>
                    ) : (
                      <>Book Your Clinic Visit</>
                    )}
                  </div>
                  <div className="slots d-none d-sm-block">{appt.txt}</div>
                  <div>
                    <Link
                      to="/selectdoctor"
                      state={{
                        data: {
                          type: !appt.virutal ? "InPerson" : "VideoCall",
                        },
                      }}
                      className="btnr btnr-primary btnr-hover"
                    >
                      {appt.cta} <span className="arr"></span>
                    </Link>
                  </div>
                  <img
                    className="backimg"
                    src={appt.virutal ? vv : cv}
                    alt="sd"
                  />
                </SwiperSlide>
              ))}
            </>
          </Swiper>
        </div>
      </section>
    )
  );
}

export default ApptSchedule;
