import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import lab from "../../static/home/lab.svg";
import pser from "../../static/home/pser.svg";
import equip from "../../static/home/equip.svg";
import team from "../../static/home/team.svg";

function AboutClinic() {
  //check for clinic Details
  const defaultHealthCenter = useSelector(
    (state) => state.clinic.clincObj.defaultHealthCenter
  );

  const clinicObj = useSelector(
    (state) => state.clinic.clincObj.clinic
  );
  // slick settings
  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: 1,
    slidesToScroll: 1,

    arrows: false,
    autoplay: false,
    speed: 450,
    autoplaySpeed: 1110,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const hasAboutClinicPage = useSelector((state) => state.clinic.clincObj.clinic.hasAboutClinicPage);

  return hasAboutClinicPage && (
    <section className="aboutus-home">
      <div className="container">
        <div className="about-grid">
          <div className="abslider">

            <Slider {...settings}>
              { }

              <img src={defaultHealthCenter?.profilePicture} alt="img" />

            </Slider>

          </div>
          <div className="abcontent">
            <div>
              <span className="section-pill">About clinic</span>
            </div>
            <div>
              <span className="section-title section-title-primary">
                {defaultHealthCenter?.healthCenterName}
              </span>
            </div>

            <span className="para">{clinicObj?.about}</span>
            <Link to="/clinic" className="btnr btnr-primary btnr-hover">
              VIEW DETAILS <span className="arr"></span>
            </Link>
          </div>
          <div className="abstrip">
            <Row>
              {
                clinicObj?.team && (
                  <Col sm={3} xs={6} className="abs_grid">
                    <img src={team} alt="sd" />
                    <div className="count">{clinicObj?.team}</div>
                    <div className="txt">Professional Medical team</div>
                  </Col>
                )
              }
              {
                clinicObj?.equipment && (
                  <Col sm={3} xs={6} className="abs_grid">
                    <img src={equip} alt="sd" />
                    <div className="count">{clinicObj?.equipment}</div>
                    <div className="txt">Hi-tech Medical Tools</div>
                  </Col>
                )
              }
              {
                clinicObj?.labs && (
                  <Col sm={3} xs={6} className="abs_grid">
                    <img src={lab} alt="sd" />
                    <div className="count">{clinicObj?.labs}</div>
                    <div className="txt">Laboratory for medical test</div>
                  </Col>
                )
              }
              {
                clinicObj?.patientsServed && (
                  <Col sm={3} xs={6} className="abs_grid">
                    <img src={pser} alt="sd" />
                    <div className="count">
                      {clinicObj?.patientsServed}
                    </div>
                    <div className="txt">Patients served so far</div>
                  </Col>
                )
              }




            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutClinic;
