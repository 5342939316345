import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { Button } from 'react-bootstrap';
import red_cross from "../../static/chat/red_cross.svg";
import war_avatar from "../../static/chat/war_avatar.png";

const AgreementModal = ({ show, onHide, handleAgree }) => {


    return (
        <Modal show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="agreement_modal">
                    <div className='closeModal' onClick={onHide}>
                        <img src={red_cross} alt="" />
                    </div>
                    <div className="modal_header">
                        <h4>Chat With Doctor</h4>
                        <p>
                            You are about to start a chat with doctor. Please review and accept the following rules and terms before proceeding.
                        </p>
                    </div>
                    <div className='rules_container'>
                        <div className="rules">
                            <h5>Rules of Chat</h5>
                            <ul>
                                <li>Chat responsibly and respectfully</li>
                                <li>Do not share sensitive information</li>
                                <li>In case of emergencies, call 911 or visit the nearest hospital.</li>
                                <li>Doctors will respond during their working hours.</li>
                                <li>Responses may take some time, please be patient</li>
                            </ul>
                        </div>
                        <div className='img'>
                            <img src={war_avatar} alt="" />
                        </div>
                    </div>
                    <div className="button_area">
                        <button className='btnr btnr-white cancle' onClick={onHide}>Close</button>
                        <button className='btnr btnr-primary' onClick={()=>handleAgree()}>Agree</button>
                    </div>
                    <div className='bottom_text'>
                        <p>By clicking “Agree”  you’re about to agree with the terms of service and consent to chat with this doctor</p>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default AgreementModal;