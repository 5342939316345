import React from 'react';
import reset from "../../static/selectdoctors/reset.svg";

const FilterButton = ({onreset}) => {
    return (
        <div className="text-center filter-sub">
            {/* <button
                className="btnr btnr-primary"
            >
                Apply Filter
            </button> */}
            <button
                className="btnr btnr-secondary"
                onClick={()=>onreset()}
            >
                <img src={reset} alt="" />
                Reset
            </button>
        </div>
    );
};

export default FilterButton;