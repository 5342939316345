import axios from "axios";

const clinicId = process.env.REACT_APP_CLINIC_ID;
const oAuthTokenInstanceName = process.env.FIREBASE_PROJECTID;

export const clientLogin = async (loginObj) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/PatientLogin`,
    {
      ...loginObj,
      clinicId,
      oAuthTokenInstanceName,
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const UpdatePatientProfile = async (userData, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/UpdatePatientProfile`,
    {
      ...userData,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const mediaUpload = async (formData, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/Media/Upload/${clinicId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer  ${token}`,
      },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const GetPatientFamilyMembers = async (token) => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetPatientFamilyMembers`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer  ${token}`,
      },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const SavePatientFamilyMember = async (token, memberObj) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/SavePatientFamilyMember`,
    {
      ...memberObj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

// export const SavePatientFamilyMember = async (
//   token,
//   memberObj
// ) => {
//   const promise = axios.post(
//     `/${process.env.REACT_APP_API_VERSION_V1}/SavePatientFamilyMember`,
//     {
//       ...memberObj
//     },
//     {
//       headers: {   Authorization: `Bearer  ${token}`  },
//     }
//   );

//   const dataPromise = promise.then((response) => response.data);

//   return dataPromise;
// };
