import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetClincDetails, GetContactInformation } from "../services/clinicservice";

export const getClinicDetailsRedux = createAsyncThunk(
  "clinic/getClinicDetailsRedux",
  async (payload, thunkAPI) => {
    try {
      let response = await GetClincDetails();

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getContactInformationRedux = createAsyncThunk(
  "clinic/getContactInformationRedux",
  async (payload, thunkAPI) => {
    try {
      let response = await GetContactInformation();

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

const initialState = {
  clincObj: {},
  isSuccess: false,
  loading: false,
  message: "",
  clinincInfo:{
    cObject: {},
    isSuccess: false,
    loading: false,
    message: "",
  },
  features:{
    
     
      isAboutClinicEnabled: true,
      supportsInPersonAppointments: true,
      supportsVideoCallAppointments:true,
      supportsTreatment: true,
  }
};

export const clinicSlice = createSlice({
  name: "clinic",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getClinicDetailsRedux.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getClinicDetailsRedux.fulfilled, (state, action) => {
        const { payload } = action;
        state.clincObj = payload;
        state.loading = false;
        state.isSuccess = true;
      })
      .addCase(getClinicDetailsRedux.rejected, (state, action) => {
        const { payload } = action;
        state.message = payload;

        state.loading = false;
        state.isSuccess = false;
      })


      .addCase(getContactInformationRedux.pending, (state, action) => {
        state.clinincInfo.loading = true;
      })
      .addCase(getContactInformationRedux.fulfilled, (state, action) => {
        const { payload } = action;
        state.clinincInfo.cObject = payload;
        state.clinincInfo.loading = false;
        state.clinincInfo.isSuccess = true;
      })
      .addCase(getContactInformationRedux.rejected, (state, action) => {
        const { payload } = action;
        state.clinincInfo.message = payload;

        state.clinincInfo.loading = false;
        state.clinincInfo.isSuccess = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = clinicSlice.actions;

export default clinicSlice.reducer;
