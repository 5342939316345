import axios from "axios";

const clinicId = process.env.REACT_APP_CLINIC_ID;

export const GetClincDetails = async () => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetClinicDetails/${clinicId}`
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};
export const GetContactInformation = async () => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/Clinic/GetContactInformation/${clinicId}`
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const GetHealthCenterDetails = async (healthCenterId) => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetHealthCenterDetails/${healthCenterId}`
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const Getfacility = async () => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/clinic/${clinicId}/facility`
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const Getstaff = async () => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/clinic/${clinicId}/staff`
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const GetService = async () => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/clinic/${clinicId}/service`
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};
