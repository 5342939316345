import React from "react";
import { Col, Row } from "react-bootstrap";
import quotes from "../../static/home/quotes.png";
import quote from "../../static/home/quote.svg";
import star from "../../static/home/Star.svg";
import Carousel from "react-bootstrap/Carousel";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Testimonials() {
  //get banners list from redux
  const comments = useSelector((state) => state.homepage.testimonials.list);

  const hasTestimonials = useSelector((state) => state.clinic.clincObj.clinic.hasTestimonials);

  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  const ratingGenerator = (rating) => {
    let content = [];
    for (let i = 0; i < rating; i++) {
      content.push(
        <div className="star" key={i}>
          <img src={star} alt="start" />
        </div>
      );
    }
    return content;
  };
  return hasTestimonials && (comments.length > 0 && (
    <section className="testimonialsparent">
      <div className="container">
        <Row>
          <Col sm={6} xs={12} className="para">
            <span className="section-pill testimonials-pill section-pill-invert">
              Testimonials
            </span>
            <div className="section-title section-title-primary">
              Here's what People say about us
            </div>
            <div className="txt">
              Discover the stories and experiences of our satisfied patients. Read their testimonials and find out how our clinic has made a positive impact on their lives.
            </div>
            {
              (clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) &&

              <Link to="/selectdoctor" className="btnr btnr-primary-outline btnr-hover">
                BOOK APPOINTMENT <span className="arr"></span>
              </Link>
            }
          </Col>
          <Col sm={6} xs={12}>
            <div className="testicard">
              <div className="topcard">
                <img src={quote} className="top-qt" alt="quate" />
                <img src={quote} className="btm-qt" alt="quate" />
              </div>

              {comments.length > 0 && (
                // interval={null}
                <Carousel
                  interval={null}
                  controls={true}
                  touch={true}
                  className="slider-parent carousel-zoom"
                >
                  {comments.map((comment, index) => (
                    <Carousel.Item key={index}>
                      <div className="comment">{comment.review}</div>
                      <div className="img">
                        <img src={comment.patientPictureUrl} alt="sd" />
                      </div>
                      <div className="stars-parent">
                        {ratingGenerator(comment.rating)}
                      </div>
                      <div className="name"> {comment.patientName}</div>
                      <div className="designnation"> Patient</div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
              <div></div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="back-icon">
        <img src={quotes} alt="back" />
      </div>
    </section>
  ));
}

export default Testimonials;
