import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// export const getAllBannerAsync = createAsyncThunk(
//   "homepage/getAllBannerAsync",
//  async (payload, thunkAPI) => {
//     try {
//      let response = await  getBanners()

//       return response

//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       return thunkAPI.rejectWithValue(err.response);
//     }
//   }
// )

const initialState = {
  isLoginDrawerActive: false,
  deepLinking: {
    toLink: "",
    state: null,
  },
  layout: "",
};

export const webAppSlice = createSlice({
  name: "webApp",
  initialState,
  reducers: {
    updateLoginDrawerState: (state, action) => {
      state.isLoginDrawerActive = action.payload;
    },
    updateDeepLinking: (state, action) => {
      state.deepLinking = {
        ...action.payload,
      };
    },
    updateLayout: (state, action) => {
      state.layout = action.payload;
    },
  },

  extraReducers: {
    // START -- Banner Extra Reducers
    // [getAllBannerAsync.pending]: (state, { payload }) => {
    //   state.bannersHome.loading = true;
    //   state.bannersHome.wasInit = true;
    // },
    // [getAllBannerAsync.fulfilled]: (state, { payload }) => {
    //   state.bannersHome.bannersList = payload;
    //   state.bannersHome.loading = false;
    //   state.bannersHome.isSuccess = true;
    //   if(state.bannersHome.wasInit && state.categoryHome.wasInit && state.brandsHome.wasInit && state.feauredProductsHome.wasInit &&  state.offerProductsHome.wasInit){
    //     state.isHomeLoaded = true
    //   }
    // },
    // [getAllBannerAsync.rejected]: (state, { payload }) => {
    //   state.bannersHome.message = payload;
    //   state.bannersHome.loading = false;
    //   state.bannersHome.isSuccess = false;
    // },
    // END   -- Banner Extra Reducers
  },
});

// Action creators are generated for each case reducer function
export const { updateLoginDrawerState, updateDeepLinking, updateLayout } =
  webAppSlice.actions;

export default webAppSlice.reducer;
