import React, { useEffect, useState } from "react";
import useDocumentTitle from "../utils/useDocumentTitle";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import cal1 from "../static/profile/cal1.svg";
import cal2 from "../static/profile/cal2.svg";
import phone from "../static/profile/phone.svg";
import mail from "../static/profile/mail.svg";
import back from "../static/profile/bac.svg";
import gender from "../static/profile/gender.svg";

import lgout from "../static/profile/lgout.svg";
import { Divider } from "@mui/material";
import { signoutUser } from "../redux/userSlice";
import { AnimatePresence, motion } from "framer-motion";
import UserForm from "../components/profile/UserForm";
import { Link } from "react-router-dom";
import moment from "moment";
import FamilyMembers from "../components/profile/FamilyMembers";
import { GetPatientFamilyMembers } from "../services/patientService";
import { GetPreviousAppointments } from "../services/apptservice";

function Profile() {
  useDocumentTitle("Profile");
  const dispatch = useDispatch();

  //get user details fom redux
  const userDetails = useSelector((state) => state.user.userObj.patientData);
  const token = useSelector((state) => state.user.userObj.token);

  //state for dit profile
  const [isEditProfileActivated, setIsEditProfileActivated] = useState(false);

  const onCancelPress = () => {
    setIsEditProfileActivated(false);
  };

  const containerVariats = {
    hidden: {
      opacity: 0,
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
    exit: {
      opacity: 0,

      x: "-150px",
      transition: {
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
  };
  const containerVariats1 = {
    hidden: {
      opacity: 0,
      x: "100px",
      y: "0px",
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        delay: 0,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
  };

  //family memebers
  const [dependantsList, setDependantsList] = useState([]);

  const [totalAppointments, setTotalAppointments] = useState(0);
  const userToken = useSelector((state) => state.user.userObj.token);

  useEffect(() => {
    GetPatientFamilyMembers(token)
      .then((res) => {
        if (res.status === "Success") {
          setDependantsList(res.response.patientData);
        }
      })
      .catch((err) => {
        console.log(err);
      });

      let tempObj = {
        doctorId: 0,
        pageNo: 1,
        healthCenterId: 1,
        pageSize: 1,
        imageSize: 0,
        patientId: userDetails.id,
        type: "",
        appointmentType: ""
    }

      GetPreviousAppointments(tempObj, userToken)
        .then((res) => {
          setTotalAppointments(res.count)
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  return (
    <div className="main-body">
      <section>
        <div className="container">
          <Row className="profilemain">
            <Col sm={3} xs={12}>
              <div className="profile-nav">
                <button
                  className="btnr btnr-primary-outline btnr-sm edit-btnn"
                  onClick={() => {
                    setIsEditProfileActivated(true);
                  }}
                >
                  Edit profile
                </button>
                <img className="elp-img" src={back} alt="as" />
                <img className="elp-img2" src={back} alt="sd" />
                <div className="profile-container">
                  <div className="pavatar">
                    <div className="img">
                      <img src={userDetails.pictureUrl} alt="usereimg" />
                    </div>
                    <span className="username">{userDetails.name}</span>
                  </div>
                  <div className="plinks">
                    <Row>
                      {userDetails.dob ? (
                        <Col xs={12} sm={6}>
                          <div className="item-us">
                            <div className="img">
                              <img src={cal1} alt="dob" />
                            </div>
                            <div className="txt">
                              {moment(
                                userDetails.dob,
                                "YYYY-MM-DDTHH:mm:ss"
                              ).format("DD, MMMM YY")}
                            </div>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      {userDetails.sex && (
                        <Col xs={12} sm={6}>
                          <div className="item-us">
                            <div className="img">
                              <img src={gender} alt="dob" />
                            </div>
                            <div className="txt">{userDetails.sex}</div>
                          </div>
                        </Col>
                      )}

                      {userDetails.phoneNumber && (
                        <Col xs={12} sm={12}>
                          <div className="item-us">
                            <div className="img">
                              <img src={phone} alt="dob" />
                            </div>
                            <div className="txt">{userDetails.countryCode}{" "}{userDetails.phoneNumber}</div>
                          </div>
                        </Col>
                      )}
                      {userDetails.email && (
                        <Col xs={12} sm={12}>
                          <div className="item-us">
                            <div className="img">
                              <img src={mail} alt="dob" />
                            </div>
                            <div className="txt">{userDetails.email}</div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <div className="appts">
                    <img className="img" src={cal2} alt="dfsd" />
                    <div className="t1">{totalAppointments}</div>
                    <div className="t2">
                      Total <br />
                      Appointmnets
                    </div>
                  </div>
                  <div className="pbtn d-none">
                    <span onClick={() => dispatch(signoutUser())}>
                      <img src={lgout} alt="logout" /> Sign out
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={9} xs={12} className="col-right-profile">
              <AnimatePresence initial={false}>
                {!isEditProfileActivated && (
                  <motion.div
                    className="profile-rightwe"
                    variants={containerVariats}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <FamilyMembers
                      dependantsList={dependantsList}
                      setDependantsList={setDependantsList}
                    />
                  </motion.div>
                )}
              </AnimatePresence>

              <AnimatePresence>
                {isEditProfileActivated && (
                  <motion.div
                    className="profile-right profile-r-edi"
                    variants={containerVariats}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <Row>
                      <Col xs={12} className="heading">
                        Edit Profile
                        <Divider />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="formparent">
                        <UserForm
                          dohaveCancelBtn={true}
                          onCancelPress={onCancelPress}
                        />
                      </Col>
                    </Row>
                  </motion.div>
                )}
              </AnimatePresence>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}

export default Profile;
