import React from 'react';
import { useSelector } from 'react-redux';

const ConsultationFee = ({ initialFilter, doctor, processObj }) => {

    const currencySymbol = useSelector(
        (state) => state.clinic.clincObj.clinic.currencyDto.symbol
    );

    const getPrice = () => {
        if (initialFilter.appointmentType === "VideoCall") {
            if (doctor.videoCallConsultationFee !== null) {
                return doctor.videoCallConsultationFee;
            }else{
                return "---";
            }

        } else if (initialFilter.appointmentType === "InPerson") {
            if (doctor.inPersonConsultationFee !== null) {
                return doctor.inPersonConsultationFee;
            }else{
                return "---";
            }

        } else if (initialFilter.appointmentType === "Treatment") {
            if (doctor.treatmentConsultationFee !== null) {
                return doctor.treatmentConsultationFee;
            }else{
                return "---";
            }

        } else {
            return "---";
        }
    }

    return (
        <>
            <div className="icon-text">
                <div>
                    <span>{
                        currencySymbol +
                        " " + getPrice()}</span>
                    {" "}
                    Consultation fee
                </div>
            </div>

            {
                // initialFilter.appointmentType === "VideoCall" &&
                //     doctor.videoCallConsultationFee && (
                //         <div className="icon-text">
                //             {/* <img alt="dollar" src={dollar} /> */}
                //             <div>
                //                 <span>{initialFilter.type === "VideoCall" &&
                //                     currencySymbol +
                //                     " " + doctor.videoCallConsultationFee}</span>
                //                 {" "}
                //                 Consultation fee
                //             </div>
                //         </div>
                //     )
            }
            {
                // initialFilter.appointmentType === "InPerson" &&
                //     doctor.inPersonConsultationFee && (
                //         <div className="icon-text" >
                //             {/* <img alt="dollar" src={dollar} /> */}
                //             <div>
                //                 <span>{initialFilter.appointmentType === "InPerson" &&
                //                     currencySymbol +
                //                     " " +
                //                     doctor.inPersonConsultationFee}</span>
                //                 {" "}
                //                 Consultation fee
                //             </div>
                //         </div>
                //     )
            }
            {
                // initialFilter.appointmentType === "Process" &&
                //     processObj?.price && (
                //         <div className="icon-text">
                //             {/* <img alt="dollar" src={dollar} /> */}
                //             <div>
                //                 <span>{initialFilter.type === "Process" &&
                //                     currencySymbol + " " + processObj.price}</span>
                //                 Consultation fee
                //             </div>
                //         </div>
                //     )
            }
        </>
    );
};

export default ConsultationFee;