import React, { useState } from "react";

import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { updateLoginDrawerState } from "../redux/webAppSlice";
import logo from "../static/header/logo.svg";

import hippa from "../static/login/hippa.png";
import gdpr from "../static/login/gdpr.png";
import pci from "../static/login/pci.png";
import Phone from "./login/Phone";

// Provider
// Phone
// Email
// Facebook
// Google
// Apple

function Login() {
  const dispatch = useDispatch();
  const loginDrawerState = useSelector(
    (state) => state.webapp.isLoginDrawerActive
  );

  const [isSetupFormActivated, setIsSetupFormActivated] = useState(false);

  return (
    <div>
      <Drawer
        className={`logincomp ${isSetupFormActivated ? "activated" : ""}`}
        anchor="right"
        open={loginDrawerState}
        onClose={() => {
          dispatch(updateLoginDrawerState(false));
          setIsSetupFormActivated(false);
          window.grecaptcha = null;
          window.recaptcha = null;
        }}
      >
        <button type="button" className="btn-close closeBtn d-block d-sm-none" onClick={()=> {
          dispatch(updateLoginDrawerState(false));
          setIsSetupFormActivated(false);
          window.grecaptcha = null;
          window.recaptcha = null;
        }}></button>

        <div className="login-logo">
          <img src={logo} alt="logo" />
        </div>

        <Phone
          setIsSetupFormActivated={setIsSetupFormActivated}
          isSetupFormActivated={isSetupFormActivated}
        />

        {!isSetupFormActivated && (
          <div className="login-badges">
            <img src={hippa} alt="hippa" />
            <img src={pci} alt="pci" />
            <img src={gdpr} alt="gdpr" />
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default Login;
