import React from 'react';
import sent_arrow from "../../static/chat/sent_arrow.svg";
import attechment from "../../static/chat/attechment.svg";

const InputArea = () => {
    return (
        <div className='input_area'>
            <div className='inputs'>
                <div className='attechment'>
                    <img src={attechment} alt="" />
                </div>
                <input type="text" placeholder='Type a message...' />
            </div>
            <div className='sent_arrow'>
                <img src={sent_arrow} alt="" />
            </div>
        </div>
    );
};

export default InputArea;