import { red, yellow } from '@mui/material/colors';
import React from 'react';

const AppStatus = ({ appt }) => {

  const getClass = () => {
    if (
      appt.status === "Cancelled" ||
      appt.status === "No show" ||
      appt.status === "Patient No show" ||
      appt.status === "Doctor No show"
    ) {
      return "red"
      // Status which show red color

    } else if (
      appt.status === "Completed" ||
      appt.status === "Booked"||
      appt.status === "Patient Checked In"||
      appt.status === "No one joined"
    ) {
      // status which show in green color
      return "green";

    } else if (
      appt.status === "Recommendation sent" ||
      appt.status === "Recommendation not sent" ||
      appt.status === "Rescheduled"
    ) {
      // status which show ing skyblue color
      return "skyBlue"

    } else if (
      appt.status === "Follow-Up Request sent" ||
      appt.status === "In-progress"|| 
      appt.status === "Patient is waiting" ||
      appt.status === "Doctor is waiting"
    ) {
      // status which show in yellow color
      return "yellow"
    }
  }

  const getStatus = () => {
    if (appt.status === "Cancelled") {
      return "Cancelled"
    } else if (appt.status === "No show") {
      return "No show"
    } else if (appt.status === "Patient No show") {
      return "Patient No show"
    } else if (appt.status === "Doctor No show") {
      return "Doctor No show"
    } else if (appt.status === "Completed") {
      return "Completed"
    } else if (appt.status === "Booked") {
      return "Booked"
    }else if(appt.status === "Patient Checked In"){
      return "Patient Checked In"
    } else if (appt.status === "Recommendation sent") {
      return "Received Recommendation"
    } else if (appt.status === "Recommendation not sent") {
      return "No Recommendation"
    } else if (appt.status === "Rescheduled") {
      return "Rescheduled"
    } else if (appt.status === "Follow-Up Request sent") {
      return "Follow-Up"
    }else if(appt.status === "In-progress"){
      return "In-progress"
    }else if(appt.status === "No one joined"){
      return "No one joined"
    }else if(appt.status === "Patient is waiting"){
      return "Patient is waiting"
    }else if(appt.status === "Doctor is waiting"){
      return "Doctor is waiting"
    }
  }


  return (
    <span className={`status ${getClass()}`}>
      {getStatus()}
    </span>
  );
};

export default AppStatus;