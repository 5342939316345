import axios from "axios";

const clinicId = process.env.REACT_APP_CLINIC_ID;

export const GetAllClinicPromotions = async (promotionObj) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetAllClinicPromotions/`,
    {
      ...promotionObj,
      clinicId,
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const LikeOnPromotion = async (likeObj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/LikeOnPromotion/`,
    {
      ...likeObj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const CommentOnPromotion = async (likeObj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/CommentOnPromotion/`,
    {
      ...likeObj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const DeleteCommentPromotion = async (commentId, token) => {
  try {
    const response = await axios.delete(
      `/${process.env.REACT_APP_API_VERSION_V1}/DeleteCommentById/${commentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (error) {

    console.error('Error deleting comment:', error);
  }
};

export const GetTestimonialsApi = async (limit, imgSize) => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/clinic/${clinicId}/testimonials/?limit=${limit}&imgSize=${imgSize}`
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};
