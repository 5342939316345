import React from "react";
import { useEffect } from "react";
import { GetPatientFamilyMembers } from "../../services/patientService";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import dependent from "../../static/appt/dep.svg";
import AddDependent from "./AddDependent";

function Dependents(props) {
  const { user, outputDependantId } = props;

  const [dependantsList, setDependantsList] = useState([]);

  useEffect(() => {
    GetPatientFamilyMembers(user.userObj.token)
      .then((res) => {
        if (res.status === "Success") {
          setDependantsList(res.response.patientData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [depIdState, setDepIdState] = useState(0);

  const setDependant = (depId) => {
    outputDependantId(depId);
    setDepIdState(depId);
  };

  //drawer related changes
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const triggerUpdate = (newId) => {
    GetPatientFamilyMembers(user.userObj.token)
      .then((res) => {
        if (res.status === "Success") {
          setDependantsList(res.response.patientData);
          setDependant(newId);
          setIsDrawerOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="header">
        Add patient
        <span className="addmem" onClick={() => setIsDrawerOpen(true)}>
          + Add Member
        </span>
      </div>
      <div className="body">
        <Row>
          <Col xs={12} sm={4}>
            <div
              className={`dependant ${depIdState === 0 && "checked"}`}
              onClick={() => setDependant(0)}
            >
              <img src={user.userObj.patientData.pictureUrl} alt="dep" />
              <span title={user.userObj.patientData.name}>{user.userObj.patientData.name}</span>
            </div>
          </Col>
          {dependantsList.map((dependant, index) => (
            <Col xs={12} sm={4} key={index}>
              <div
                className={`dependant ${
                  depIdState === dependant.id && "checked"
                }`}
                onClick={() => setDependant(dependant.id)}
              >
                <img src={dependent} alt="dep" />
                <span title={dependant.name}>{dependant.name}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <AddDependent
        setIsSaving={setIsSaving}
        isSaving={isSaving}
        triggerUpdate={triggerUpdate}
        token={user.userObj.token}
        patientId={user.userObj.patientData.id}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </>
  );
}

export default Dependents;
