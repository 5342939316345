import axios from "axios";
const clinicId = process.env.REACT_APP_CLINIC_ID;

export const GetAllSpecialties = async () => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetAllSpecialties/`,
    {
      clinicId,
      search: null,
      pageNo: 1,
      pageSize: 100
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};


export const GetSpecialtiesDetails = async (slug) => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/Specialty/${slug}/`
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};
