import React, { useEffect, useRef } from "react";
import logo from "../../static/header/logo.svg";
import bar from "../../static/header/bar.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updateLoginDrawerState } from "../../redux/webAppSlice";
import { useDispatch, useSelector } from "react-redux";
import UserDrop from "./UserDrop";
import NotiffDrop from "./NotiffDrop";
import { Button, Col, Offcanvas } from "react-bootstrap";
import { signoutUser } from "../../redux/userSlice";
import Megamenu from "./Megamenu";
import MegamenuHOC from "./MegamenuHOC";
import ChatDrop from "./ChatDrop";

const EnhancedComponentMegaMenu = MegamenuHOC(Megamenu);

function HeaderMenu() {
  const navRef = useRef(null);

  const homeRef = useRef(null);
  const apptRef = useRef(null);
  const dotorRef = useRef(null);
  const clinicRef = useRef(null);
  const wallRef = useRef(null);
  const contactRef = useRef(null);
  const speciaRef = useRef(null);

  const [activeNavItem, setActiveNavItem] = React.useState('home');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let offWidth = 0;
    let offLeft = 0;

    if (location.pathname === "/") {
      homeRef.current.classList.add("active");
      offWidth = homeRef.current.offsetWidth;
      offLeft = homeRef.current.offsetLeft;
    } else if (location.pathname === "/selectdoctor") {
      dotorRef.current.classList.add("active");
      offWidth = dotorRef.current.offsetWidth;
      offLeft = dotorRef.current.offsetLeft;
    } else if (location.pathname === "/clinic") {
      clinicRef.current.classList.add("active");
      offWidth = clinicRef.current.offsetWidth;
      offLeft = clinicRef.current.offsetLeft;
    } else if (
      location.pathname === "/appointments" ||
      location.pathname === "/appointmentsDetails"
    ) {
      apptRef.current.classList.add("active");
      offWidth = apptRef.current.offsetWidth;
      offLeft = apptRef.current.offsetLeft;
    } else if (location.pathname === "/wall") {
      wallRef.current.classList.add("active");
      offWidth = wallRef.current.offsetWidth;
      offLeft = wallRef.current.offsetLeft;
    } else if (location.pathname === "/contact") {
      contactRef.current.classList.add("active");
      offWidth = contactRef.current.offsetWidth;
      offLeft = contactRef.current.offsetLeft;
    }else if (location.pathname === "/selectspaciality") {
      speciaRef.current.classList.add("active");
      offWidth = speciaRef.current.offsetWidth;
      offLeft = speciaRef.current.offsetLeft;
    }

    navRef.current.style.width = `${offWidth + 0}px`;
    navRef.current.style.left = `${offLeft - 0}px`;
  }, []);

  const navChange = (e, navName) => {
    //remove Class from refs
    homeRef.current.classList.remove("active");
    apptRef.current.classList.remove("active");
    dotorRef.current.classList.remove("active");
    clinicRef.current.classList.remove("active");
    wallRef.current.classList.remove("active");
    contactRef.current.classList.remove("active");
    speciaRef.current.classList.remove("active");

    setActiveNavItem(navName);

    navRef.current.style.width = `${e.target.offsetWidth + 6}px`;
    navRef.current.style.left = `${e.target.offsetLeft - 3}px`;

    if (navName === "home") {
      homeRef.current.classList.add("active");
      navigate("/");
    } else if (navName === "selectdoctor") {
      dotorRef.current.classList.add("active");
      navigate("/selectdoctor");
    } else if (navName === "appointments") {
      apptRef.current.classList.add("active");
      navigate("/appointments");
    } else if (navName === "clinic") {
      clinicRef.current.classList.add("active");
      navigate("/clinic");
    } else if (navName === "wall") {
      wallRef.current.classList.add("active");
      navigate("/wall");
    } else if (navName === "contact") {
      contactRef.current.classList.add("active");
      navigate("/contact");
    } else if (navName === "selectspaciality") {
      speciaRef.current.classList.add("active");
      navigate("/selectspaciality");
    }
  };

  //openLogin drwaer
  const dispatch = useDispatch();
  const openLoginDrawer = () => {
    dispatch(updateLoginDrawerState(true));
  };

  //is User Logged In
  const userDetails = useSelector((state) => state.user.userObj);

  //onchange nav indicator on internal pages
  useEffect(() => {
    homeRef.current.classList.remove("active");
    apptRef.current.classList.remove("active");
    dotorRef.current.classList.remove("active");
    clinicRef.current.classList.remove("active");
    wallRef.current.classList.remove("active");
    contactRef.current.classList.remove("active");

    let offWidth = 0;
    let offLeft = 0;

    if (location.pathname === "/") {
      homeRef.current.classList.add("active");
      offWidth = homeRef.current.offsetWidth;
      offLeft = homeRef.current.offsetLeft;
    } else if (
      location.pathname === "/selectdoctor" ||
      location.pathname === "/bookappt"
    ) {
      dotorRef.current.classList.add("active");
      offWidth = dotorRef.current.offsetWidth;
      offLeft = dotorRef.current.offsetLeft;
    } else if (
      location.pathname === "/appointments" ||
      location.pathname === "/appointmentsDetails"
    ) {
      apptRef.current.classList.add("active");
      offWidth = apptRef.current.offsetWidth;
      offLeft = apptRef.current.offsetLeft;
    } else if (location.pathname === "/clinic") {
      clinicRef.current.classList.add("active");
      offWidth = clinicRef.current.offsetWidth;
      offLeft = clinicRef.current.offsetLeft;
    } else if (location.pathname === "/wall") {
      wallRef.current.classList.add("active");
      offWidth = wallRef.current.offsetWidth;
      offLeft = wallRef.current.offsetLeft;
    } else if (location.pathname === "/contact") {
      contactRef.current.classList.add("active");
      offWidth = contactRef.current.offsetWidth;
      offLeft = contactRef.current.offsetLeft;
    } else if (location.pathname === "/profile") {
      offWidth = 0;
      offLeft = 0;
    }

    navRef.current.style.width = `${offWidth + 0}px`;
    navRef.current.style.left = `${offLeft - 0}px`;
  }, [location.pathname, userDetails]);

  const [showSidebar, setShowSidebar] = React.useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const hasAboutClinicPage = useSelector((state) => state.clinic.clincObj.clinic.hasAboutClinicPage);
  const hasDoctorWall = useSelector((state) => state.clinic.clincObj.clinic.hasDoctorWall);

  return (
    <div className="header-menu-p">
      <div className="container">
        <div className="header-menu">
          <div className="bar">
            <div className="d-md-none">
              <Button variant="link" onClick={toggleSidebar}>
                <img alt="bar-c" src={bar} />
              </Button>
            </div>

            <Link className="logo" to="/">
              <img alt="logo" src={logo} />
            </Link>
          </div>

          <div className="nav">
            <ul className="navparent">
              <li ref={homeRef} >
                <span className="nav-text" onClick={(e) => navChange(e, "home")}>Home</span>
                <span className="icons-nav icon-home-line" onClick={(e) => navChange(e, "home")}></span>
                <span className="icons-nav icon-active  icon-home-solid"></span>
              </li>
              <li
                ref={apptRef}
                className={` ${userDetails ? "" : "d-none"}`}

              >
                <span className="nav-text" onClick={(e) => navChange(e, "appointments")}>Appointments</span>
                <span className="icons-nav icon-calendar-line" onClick={(e) => navChange(e, "appointments")}></span>
                <span className="icons-nav icon-active  icon-calendar-solid"></span>
              </li>
              <li ref={dotorRef} className="d-sm-flex">
                <span className="nav-text" onClick={(e) => navChange(e, "selectdoctor")}>Doctors</span>
                <span className="icons-nav doc_line" onClick={(e) => navChange(e, "selectdoctor")}></span>
                <span className="icons-nav icon-active doc_fill"></span>
                <span className="ps-1 d-none d-sm-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M3.33464 6L8.0013 10.6667L12.668 6" stroke="#4A5567" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <EnhancedComponentMegaMenu type="doctor" />

              </li>
              <li ref={speciaRef} className="d-none d-sm-flex o-none">
                <span className="nav-text" onClick={(e) => navChange(e, "selectspaciality")}>Speciality</span>
                <span className="icons-nav doc_line" onClick={(e) => navChange(e, "selectspaciality")}></span>
                <span className="icons-nav icon-active doc_fill"></span>
                <span className="ps-1 d-none d-sm-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M3.33464 6L8.0013 10.6667L12.668 6" stroke="#4A5567" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <EnhancedComponentMegaMenu type="speciality" />

              </li>


              <li className="d-none d-sm-flex" >
                <span className="nav-text">Treatments</span>
                <span className="icons-nav icon-plus-solid" ></span>
                <span className="icons-nav icon-active  icon-plus-solid"></span>
                <span className="ps-1 d-none d-sm-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M3.33464 6L8.0013 10.6667L12.668 6" stroke="#4A5567" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>

                <EnhancedComponentMegaMenu type="treatment" />
              </li>

              <li ref={clinicRef} className={!hasAboutClinicPage ? 'd-none' : ''} >
                <span className="nav-text" onClick={(e) => navChange(e, "clinic")}>Our clinic</span>
                <span className="icons-nav icon-plus-solid" onClick={(e) => navChange(e, "clinic")}></span>
                <span className="icons-nav icon-active  icon-plus-solid"></span>
              </li>
              <li className={!hasDoctorWall ? 'd-none' : ''} ref={wallRef} >
                <span className="nav-text" onClick={(e) => navChange(e, "wall")}>Doctors wall</span>
                <span className="icons-nav icon-wall-line" onClick={(e) => navChange(e, "wall")}></span>
                <span className="icons-nav icon-active  icon-wall-solid"></span>
              </li>

              <li
                className="d-none"
                ref={contactRef}

                onClick={(e) => navChange(e, "contact")}
              >
                <span className="nav-text">Contact us</span>
                <span className="icons-nav icon-chat-line"></span>
                <span className="icons-nav icon-active  icon-chat-solid"></span>
              </li>
              <span ref={navRef} className="nav-indicator"></span>
            </ul>
          </div>
          <div className="toolbar">

            {userDetails ? (
              <>
                <ChatDrop />
                <NotiffDrop />
                <UserDrop />
              </>
            ) : (
              <button
                className="btnr btnr-primary btnr-hover"
                onClick={() => openLoginDrawer()}
              >
                Sign In <span className="arr"></span>
              </button>
            )}
          </div>
        </div>

        <Offcanvas show={showSidebar} onHide={toggleSidebar} placement="start" className="custom-offcanvas d-md-none">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="c_logo">
              <Link className="logo" to="/">
                <img alt="logo" src={logo} />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="nav_items">

            <div className="user_info">
              {
                userDetails ? (
                  <>
                    <div className="img">
                      <img src={userDetails.patientData.pictureUrl} alt="" />
                    </div>
                    <div className="text">
                      <span>Welcome back,</span>
                      <span className="name">{userDetails.patientData.name}</span>
                    </div>
                  </>
                ) : (
                  <div className="text">
                    <span>Welcome</span>
                  </div>
                )
              }

            </div>

            <div className="nav">
              <ul className="navparent">
                <li
                  // ref={homeRef}
                  className={activeNavItem === 'home' ? 'active' : ''}
                  onClick={(e) => { navChange(e, "home"); setShowSidebar(false) }}
                >
                  <span className="nav-text">Home</span>
                </li>
                <li
                  // ref={apptRef}
                  className={` ${userDetails ? "" : "d-none"} ${activeNavItem === 'appointments' ? 'active' : ''}`}
                  onClick={(e) => { navChange(e, "appointments"); setShowSidebar(false) }}
                >
                  <span className="nav-text">Appointments</span>
                </li>
                <li
                  // ref={dotorRef}
                  className={activeNavItem === 'selectdoctor' ? 'active' : ''}
                  onClick={(e) => { navChange(e, "selectdoctor"); setShowSidebar(false) }}
                >
                  <span className="nav-text">Doctors</span>

                </li>
                <li
                  // ref={dotorRef}
                  className={activeNavItem === 'selectspaciality' ? 'active' : ''}
                  onClick={(e) => { navChange(e, "selectspaciality"); setShowSidebar(false) }}
                >
                  <span className="nav-text">Speciality</span>

                </li>
                <li
                  // ref={clinicRef}
                  className={`${activeNavItem === 'clinic' ? 'active' : ''} ${!hasAboutClinicPage ? 'd-none' : ''}`}
                  onClick={(e) => { navChange(e, "clinic"); setShowSidebar(false) }}
                >
                  <span className="nav-text">Our clinic</span>
                </li>
                <li
                  // ref={wallRef} 
                  className={`${activeNavItem === 'wall' ? 'active' : ''} ${!hasDoctorWall ? 'd-none' : ''}`}
                  onClick={(e) => { navChange(e, "wall"); setShowSidebar(false) }}
                >
                  <span className="nav-text">Doctors wall</span>
                </li>

                <li
                  // ref={contactRef}
                  className={`d-none ${activeNavItem === 'contact' ? 'active' : ''}`}
                  onClick={(e) => { navChange(e, "contact"); setShowSidebar(false) }}
                >
                  <span className="nav-text">Contact us</span>
                </li>
                {/* <span ref={navRef} className="nav-indicator"></span> */}
              </ul>
              {
                userDetails && (
                  <div className="signout-section">
                    <Col
                      xs={12}
                      className="menu-list-logout"
                      onClick={() => dispatch(signoutUser())}
                    >
                      <span className="icon-logout-left-line"></span> Sign Out
                    </Col>
                  </div>
                )
              }

            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div >
  );
}

export default HeaderMenu;
