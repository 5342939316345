import React from 'react';
import red_dot from "../../static/chat/red_dot.gif";
import avatar from "../../static/chat/avatar.png";
import active from "../../static/chat/activeShap.svg";

const ChatDoc = ({ doc, handleSelectDoctor, handleChatbox }) => {
    return (
        <div
            className={`massage ${doc.isReaded && "active"}`}
            onClick={handleSelectDoctor}
        >
            <div className="msg">
                <div className="img">
                    <img src={avatar} alt="" />
                </div>
                <div className="msg_text">
                    <p className="doc_name">Alex Linderson</p>
                    <p className="last_msg">How are you today?</p>
                </div>
            </div>
            <div className="indicat_msg">
                {
                    doc.isActive &&
                    <div className='status'>
                        <img src={active} alt="" />
                        <span>Active</span>
                    </div>
                }

                {doc.isReaded &&
                    <div className='unread'>
                        <img src={red_dot} alt="" />
                    </div>
                }

            </div>
        </div>
    );
};

export default ChatDoc;