import moment from "moment";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import noSlot from "../../static/Appointments/amico.png";

function AppSlots(props) {
  const { slots, isLoading, dayInfo, opSlot } = props;

  const [selectedSlot, setSelectedSlot] = useState("");

  const updateSlotStatus = (slotUTC, endslotUTC) => {
    setSelectedSlot(slotUTC);
    opSlot(slotUTC, endslotUTC);
  };
  return (
    <div className="appt-slots">
      <div className="day">{dayInfo.day}</div>
      <div className="date">{dayInfo.date}</div>
      <div className="timeslotslistparent">
        <div className="h1">Select time slot</div>

        <div className="list row">
          {!isLoading ? (
            <>
              {slots.length > 0 ? (
                <>
                  {slots.map((slot, index) => (
                    <Col xs={6} key={index}>
                      <div
                        className={`slot ${
                          selectedSlot === slot.startAtUtc && "checked"
                        }`}
                        onClick={() =>
                          updateSlotStatus(slot.startAtUtc, slot.endAtUtc)
                        }
                      >
                        {moment.utc(slot.startAtUtc).local().format("HH:mm")} -{" "}
                        {moment.utc(slot.endAtUtc).local().format("HH:mm")}
                      </div>
                    </Col>
                  ))}
                </>
              ) : (
                <Col xs={12} className="noSlot">
                  <img src={noSlot} alt="" />
                  <span>No Slots Available!</span>
                </Col>
              )}
            </>
          ) : (
            <Col xs={12}>Loading</Col>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppSlots;
