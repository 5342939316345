import React from "react";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { authentication } from "../../utils/firebase";
import google from "../../static/login/google.svg";

import { useDispatch } from "react-redux";
import { getUserDetailsRedux } from "../../redux/userSlice";
//import swal from "sweetalert";

function GoogleLogin() {
  const dispatch = useDispatch();

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((result) => {
        let loginObj = {
          loginType: "Google",
          name: result._tokenResponse.fullName,
          email: result._tokenResponse.email,
          isEmailVerified: true,
          externalIdentifier: result.user.uid,
          oAuthAccessToken: result._tokenResponse.idToken,
        };

        dispatch(getUserDetailsRedux(loginObj));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <button className="btnr btnr-primary-google" onClick={signInWithGoogle}>
      {" "}
      <img src={google} alt="google" /> CONTINUE WITH GOOGLE
    </button>
  );
}

export default GoogleLogin;
