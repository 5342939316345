import React, { useState } from 'react'
import { useSelector } from 'react-redux';

function PaymentMethods(props) {

  const {paymentDetails, setPaymentDetails} = props;

  const paymentMethodList = useSelector((state) => state.clinic.clincObj.avaiablePaymentMethods);


  const [isActive, setIsActive] = useState(false);


  const validateisActive = (boolValue) => { 
    if(!isActive){
      setIsActive(boolValue)
    }
   }

  return (paymentMethodList.length > 0) && (
    <div className={`payment-method-comp ${isActive ? '': 'd-none'}`}>
      <span className='title'>Payment Methods</span>
      <div className='pay-list'>
        {
          console.log(paymentMethodList)
        }
       { paymentMethodList.map((method)=>(
        <div key={method.id} onClick={()=> setPaymentDetails({...paymentDetails, paymentType: method.systemName})} className={`pay-item ${method.systemName === paymentDetails.paymentType ? 'selected' : ''}`}>
          {
            method.displayName
          }
          {validateisActive(method.isActive)}
        </div>
       ))}
      </div>
    </div>
  )
}

export default PaymentMethods