import React, { useState } from 'react';

function PriceInput() {
  const initialMinPrice = 0;
  const initialMaxPrice = 100;
  const maxPriceLimit = 300;
  const step = 1;

  const [minPrice, setMinPrice] = useState(initialMinPrice);
  const [maxPrice, setMaxPrice] = useState(initialMaxPrice);

  const rangeMin = 1; // Change the rangeMin to 1

  const handleMinInputChange = (e) => {
    const newMinPrice = parseInt(e.target.value);
    if (newMinPrice >= 0 && newMinPrice <= maxPriceLimit && newMinPrice <= maxPrice - 1) {
      setMinPrice(newMinPrice);
    }
  };

  const handleMaxInputChange = (e) => {
    const newMaxPrice = parseInt(e.target.value);
    if (newMaxPrice >= minPrice + rangeMin && newMaxPrice <= maxPriceLimit) {
      setMaxPrice(newMaxPrice);
    }
  };

  const updateRange = () => {
    if (maxPrice - minPrice < rangeMin) {
      setMinPrice(maxPrice - rangeMin);
    }
  };

  return (
    <div className="price_range">
      <div className="range-slider">
        <span
          className="range-selected"
          style={{ left: `${(minPrice / maxPriceLimit) * 100}%`, right: `${(1 - maxPrice / maxPriceLimit) * 100}%` }}
        ></span>
      </div>
      <div className="range-input">
        <input
          type="range"
          className="min"
          min="0"
          max={maxPriceLimit}
          value={minPrice}
          step={step}
          onChange={handleMinInputChange}
          onBlur={updateRange}
        />
        <input
          type="range"
          className="max"
          min="0"
          max={maxPriceLimit}
          value={maxPrice}
          step={step}
          onChange={handleMaxInputChange}
          onBlur={updateRange}
        />
      </div>
      <div className="range-price">
        <span>${minPrice} - ${maxPrice}</span>
      </div>
    </div>
  );
}

export default PriceInput;
