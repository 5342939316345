import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Box, Skeleton } from "@mui/material";

function BannerBraphs() {
  const services = useSelector((state) => state.homepage.services);

  const showCaseArray = [
    {
      icon: "https://i.ibb.co/BPyfSYF/Icon.png",
      title: "Sports Medicine",
      txt: "Providing expert diagnosis and treatment",
    },
    {
      icon: "https://i.ibb.co/VMcYMfK/Group-1000005189.png",
      title: "Musculoskeletal Injuries",
      txt: "Sprains, strains, ligament tears, and tendon injuries",
    },
    {
      icon: "https://i.ibb.co/y6sZ4Lj/Group-1000005190.png",
      title: "Fracture Care",
      txt: "Providing expert diagnosis and treatment",
    },
    {
      icon: "https://i.ibb.co/y8SPDqh/Group-1000005191.png",
      title: "Joint Replacement",
      txt: "Providing expert diagnosis and treatment",
    },
  ];
  const hasServices = useSelector((state) => state.clinic.clincObj.clinic.hasServices);

  return hasServices && (!services.loading ? (
    services.isSuccess && (
      <>
        {services.list.length > 0 && (
          <section className="bannersgraphs-home">
            <div className="container">
              <Row>
                <Col xs={12} className="bannerbraps-card-parent">
                  <Row className="bannerbrapsrow">
                    {services.list.map((item, index) => {
                      if (index < 4) {
                        return (
                          <Col md={3} xs={6} key={index}>
                            <div
                              className="swc-parent"
                              data-aos-delay="300"
                              data-aos="zoom-out"
                              data-aos-once="true"
                            >
                              <div className="swc-card ">
                                <div className="card-img">
                                  <img src={item.pictureUrl} alt={item.name} />
                                </div>

                                <div className="card-title">{item.name}</div>
                                <div className="card-txt">{item.title}</div>
                              </div>
                            </div>
                          </Col>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </Row>
                </Col>
              </Row>
            </div>
          </section>
        )}
      </>
    )
  ) : (
    <section className="skeleton-section">
      <div className="container">
        <Row>
          {[...Array(4)].map((_, index) => (
            <Col md={3} xs={6} className="bannerbraps-card-parent" key={index}>
              <Box
                className="swc-parent"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: 200,
                  p: 2,
                }}
              >
                <Skeleton
                  variant="circular"
                  width={56}
                  height={56}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="80%"
                  height={30}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  height={20}
                  animation="wave"
                />
              </Box>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  ) );
}

export default BannerBraphs;
