import React from 'react';

const ShearchIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.6541 16.595L20.5836 19.523C20.7202 19.6645 20.7958 19.8539 20.7941 20.0506C20.7924 20.2472 20.7135 20.4353 20.5745 20.5744C20.4354 20.7134 20.2473 20.7923 20.0507 20.794C19.854 20.7957 19.6646 20.7201 19.5231 20.5835L16.5936 17.654C14.6793 19.2942 12.204 20.1291 9.68734 19.9836C7.17065 19.8382 4.80812 18.7236 3.0955 16.8737C1.38287 15.0239 0.453292 12.5827 0.501808 10.0622C0.550324 7.54181 1.57317 5.13813 3.35571 3.35559C5.13825 1.57305 7.54193 0.550202 10.0624 0.501686C12.5828 0.453169 15.024 1.38275 16.8739 3.09537C18.7237 4.808 19.8383 7.17053 19.9838 9.68722C20.1292 12.2039 19.2943 14.6791 17.6541 16.5935V16.595ZM10.2501 18.5C12.4382 18.5 14.5366 17.6308 16.0838 16.0836C17.6309 14.5365 18.5001 12.438 18.5001 10.25C18.5001 8.06196 17.6309 5.96354 16.0838 4.41637C14.5366 2.86919 12.4382 2 10.2501 2C8.06209 2 5.96367 2.86919 4.41649 4.41637C2.86932 5.96354 2.00012 8.06196 2.00012 10.25C2.00012 12.438 2.86932 14.5365 4.41649 16.0836C5.96367 17.6308 8.06209 18.5 10.2501 18.5Z"  />
        </svg>
    );
};

export default ShearchIcon;