import React from 'react';
import avatar from "../../static/chat/avatar.png";
import greenDot from "../../static/chat/green_dot.svg";
import { Link } from 'react-router-dom';
import ReceiveMsg from './ReceiveMsg';
import UserMsg from './UserMsg';
import sent_arrow from "../../static/chat/sent_arrow.svg";
import attechment from "../../static/chat/attechment.svg";
import InputArea from './InputArea';
import chat_line from "../../static/chat/iconoir_chat-lines.svg";

const ChatRightSide = ({ show, setModalShow, isChatActive, isChatboxActive }) => {

    const [isActive, setIsActive] = React.useState(true);
    const [isAdmin, setIsAdmin] = React.useState(true);
    // const [isChatActive, setIsChatActive] = React.useState(false);

    return (
        <div className={`right_side ${isChatboxActive && "chat_open"}`}>
            <div className="doc">
                <div className="img">
                    <img src={avatar} alt="" />
                </div>
                <div className="doc_text">
                    {
                        isAdmin ?
                            <p className="doc_name">Admin</p>
                            :
                            <Link className="doc_name">
                                {/* it will dynamic */}
                                Admin
                            </Link>
                    }
                    {
                        isActive ?
                            <p className="status">
                                <img src={greenDot} alt="" />
                                {
                                    isAdmin ?
                                        "Always Active"
                                        :
                                        "Active"
                                }
                            </p>
                            :
                            <p className="status">1 minute ago</p>
                    }
                </div>
            </div>
            <div className='chatContainer'>


                <div className='conversation_area'>
                    <div className='msg_time'>
                        <span className='msg_time_text'>Today, 10:30 AM</span>
                    </div>

                    <ReceiveMsg />
                    <UserMsg />
                    <ReceiveMsg />
                    <UserMsg />
                    <ReceiveMsg />
                    <UserMsg />
                </div>

            </div>

            {
                isChatActive ?
                    <InputArea />
                    :
                    <div className='start_chat_btn'>
                        <button className='btnr btnr-primary' onClick={() => setModalShow(!show)}>
                            <img src={chat_line} alt="" />
                            START CONVERSATION
                        </button>
                    </div>
            }

        </div>
    );
};

export default ChatRightSide;