import React from 'react';
import pana from "../../static/chat/pana.png";
import chat_line from "../../static/chat/iconoir_chat-lines.svg";
import cancle from "../../static/chat/cancle_icon.svg";
import { Link } from 'react-router-dom';

const MsgPop = () => {

    const [isPopUpVisible, setIsPopUpVisible] = React.useState(false);

    const handleCancle = () => {
        setIsPopUpVisible(false);
    }

    const handlePopUp = () => {
        setIsPopUpVisible(!isPopUpVisible);
    }

    return (
        <>
            <div className={`msgPop ${isPopUpVisible ? "visible" : ""}`}>
                <div className="header">
                    <div className="cancle" onClick={handleCancle}>
                        <img src={cancle} alt="" />
                    </div>
                    <div className="title">
                        Chat with receptionist
                    </div>
                    <div className="header_top">
                        <div className="img">
                            <img src={pana} alt="" />
                        </div>
                        <div className="header_text">
                            <p>Chat with our receptionist for general inquiries or booking an appointment. </p>
                            <Link to="/chat" className="btnr btnr-primary">
                                <img src={chat_line} alt="" />
                                CHAT
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pop_button" onClick={handlePopUp}>
                <button className="btnr btnr-primary">
                    <img src={chat_line} alt="" />
                    CHAT
                </button>
            </div>
        </>
    );
};

export default MsgPop;