import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion";

import avatar from "../../static/login/avatar.svg";
import bookmark from "../../static/login/bookmark.svg";
import support from "../../static/login/support.svg";
import arrowdn from "../../static/login/arrowdn.svg";
import { signoutUser } from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function UserDrop() {
  const [isDropVisible, setIsDropVisible] = useState(false);

  const dispatch = useDispatch();

  const containerVariats = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: {
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
    exit: {
      opacity: 0,

      transition: {
        delay: 0.4,
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
  };

  const userDetails = useSelector((state) => state.user.userObj.patientData);

  return (
    <div
      className={`${userDetails.name ? "" : "noname"} userdrop-parent`}
      onMouseEnter={() => setIsDropVisible(true)}
      onMouseLeave={() => setIsDropVisible(false)}
    >
      <div className="userdrop-img">
        <img src={userDetails.pictureUrl} alt="userimg" />
      </div>
      <div className="userdrop-name">{userDetails.name}</div>
      <div className="userdrop-btn">
        <img alt="ico" src={arrowdn} />
      </div>
      <AnimatePresence>
        {isDropVisible && (
          <motion.div
            variants={containerVariats}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="userdrop-menu"
            onMouseEnter={() => setIsDropVisible(true)}
            onMouseLeave={() => setIsDropVisible(false)}
          >
            <Link
              to="/profile"
              className="row menu-list"
              onClick={() => setIsDropVisible(false)}
            >
              <Col xs={3} className="menu-list-icon">
                <img alt="ico" src={avatar} />
              </Col>
              <Col xs={9} className="menu-list-name">
                My profile
              </Col>
            </Link>
            <Link
              className="row menu-list d-none"
              onClick={() => setIsDropVisible(false)}
            >
              <Col xs={3} className="menu-list-icon">
                <img alt="ico" src={bookmark} />
              </Col>
              <Col xs={9} className="menu-list-name">
                Saved post
              </Col>
            </Link>
            <Link
              className="row menu-list d-none"
              onClick={() => setIsDropVisible(false)}
            >
              <Col xs={3} className="menu-list-icon">
                <img alt="ico" src={support} />
              </Col>
              <Col xs={9} className="menu-list-name">
                Support
              </Col>
            </Link>
            <Row className="menu-list ">
              <Col
                xs={12}
                className="menu-list-logout"
                onClick={() => dispatch(signoutUser())}
              >
                <span className="icon-logout-left-line"></span> Sign Out
              </Col>
            </Row>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default UserDrop;
