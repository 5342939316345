import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Popper from "@mui/material/Popper";

function TreatmentSelector(props) {
  const treatmentList = useSelector((state) => state.homepage.treatments.list);

  const { onTreatmentChange, processId } = props;
  const [ccode, setCcode] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (processId !== 0) {
      if (treatmentList.length > 0) {
        setCcode(
          treatmentList.filter(
            (treatment) => treatment.id === parseInt(processId)
          )[0]
        );

        setInitialized(true);
      }
    } else {
      setInitialized(true);
      setCcode(null);
    }
  }, [treatmentList, processId]);

  ////currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  return (
    initialized && (
      <>
        {treatmentList.length > 0 && (
          <div className="treatmentParent">
            <span className="h1">Treatments</span>
            <div>
              <Autocomplete
                id="country-select-demo"
                className="dropper"
                options={treatmentList}
                autoHighlight
                getOptionLabel={(option) => option.name}
                value={ccode}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value?.id;
                }}
                disableClearable
                onChange={(event, newValue) => {
                  onTreatmentChange(newValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={option.name}
                  >
                    <div className="img">
                      <img loading="lazy" src={`${option.pictureUrl}`} alt="" />
                    </div>
                    <span className="name">{option.name}</span>
                    <span className="price">
                      {currencySymbol}
                      {option.price}
                    </span>
                  </Box>
                )}
                renderInput={(params) => {
                  let code = params.inputProps.value.split("*");

                  let inputProps = {
                    ...params.inputProps,
                    value: code[0],
                  };

                  let tempParams = {
                    ...params,
                    inputProps,
                  };
                  return (
                    <TextField
                      className="phone-tf"
                      {...tempParams}
                      variant="standard"
                      value={code[0]}
                      placeholder="select a treatment"
                      fullWidth
                    />
                  );
                }}
                PopperComponent={(props) => (
                  <Popper {...props} className="treatment-popper">
                    {props.children}
                  </Popper>
                )}
              />
            </div>
          </div>
        )}
      </>
    )
  );
}

export default TreatmentSelector;
