import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import moment from "moment";
import MobileCalendar from "./MobileCalendar";

// must manually import the stylesheets for each plugin
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";

export default class Calendar2 extends React.Component {
  calendarComponentRef = React.createRef();

  state = {
    calendarWeekends: true,
    calendarEvents: [],

    selectedDate: this.props.inputDate ? moment(this.props.inputDate).utc().local().format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"), // Add selectedDate to state
    isCalInit: false
  };

  constructor(props) {
    super(props);

  }
  componentDidMount() {
   console.log(this.state.selectedDate)
   this.initializeCalendar();
  }

  

  componentDidUpdate(prevProps, prevState) {
   
  }

  initializeCalendar = () => {
    if (this.calendarComponentRef.current) {
      const initialDate = this.calendarComponentRef.current.getApi().getDate(); // Get the initial date from the FullCalendar instance

      const pastdayslastMonth = this.calendarComponentRef.current
        .getApi()
        .view.calendar.el.getElementsByClassName(
          "fc-day-other fc-day-past"
        ).length;

      const initialDateElement = this.calendarComponentRef.current
        .getApi()
        .view.calendar.el.getElementsByClassName("fc-daygrid-day")[
        pastdayslastMonth + initialDate.getDate() - 1
      ]; // Get the element of the initial date

      if (initialDateElement) {
        initialDateElement.classList.add("selected-date");
      }
      
    }
  }

  render() {
    const { outputDate } = this.props;
    return   (
      <>
      
        <div className="fullcalendarcust d-none d-md-block">
          <FullCalendar
            headerToolbar={{
              left: "prev",
              center: "title",
              right: "next",
            }}
            height="auto"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            ref={this.calendarComponentRef}
            weekends={this.state.calendarWeekends}
            events={this.state.calendarEvents}
            dateClick={this.handleDateClick}
            initialDate={this.state.selectedDate}
          />
        </div>
        <MobileCalendar goToDate={this.goToDate} selectedDate2={this.state.selectedDate}/>
       
      </>
    );
  }

  toggleWeekends = () => {
    this.setState({
      // update a property
      calendarWeekends: !this.state.calendarWeekends,
    });
  };

  goToDate = (date) => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate(date); // call a method on the Calendar object;

    calendarApi.removeAllEventSources(); // Remove previously selected dates

    // Clear previously selected date cells
    var selectedCells = document.querySelectorAll(".selected-date");
    selectedCells.forEach(function (cell) {
      cell.classList.remove("selected-date");
    });

    const initialDate = this.calendarComponentRef.current.getApi().getDate();

    const pastdayslastMonth = this.calendarComponentRef.current
      .getApi()
      .view.calendar.el.getElementsByClassName(
        "fc-day-other fc-day-past"
      ).length;

    const initialDateElement = this.calendarComponentRef.current
      .getApi()
      .view.calendar.el.getElementsByClassName("fc-daygrid-day")[
      pastdayslastMonth + initialDate.getDate() - 1
    ]; // Get the element of the initial date

    if (initialDateElement) {
      initialDateElement.classList.add("selected-date");
    }

    const today = new Date();
    let tds = today.toDateString();

    let clanDate = moment(initialDate, "YYYY-MM-DD").format("ddd MMM DD YYYY");
    // 👇️ Today's date

    if (tds === clanDate) {
      this.outputDate(moment().format());
    } else {
      this.outputDate(
        moment(initialDate, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ssZ")
      );
    }
  };

  outputDate = (date) => {
    this.props.outputDate(date);
  };

  handleDateClick = (info) => {
    const today = new Date();
    const clickedDate = info.date;


    this.setState({
      selectedDate: moment(clickedDate).format("YYYY-MM-DD"),
    });

    

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isPast = clickedDate < yesterday;

    if (!isPast) {
      let calendarApi = this.calendarComponentRef.current.getApi();

      calendarApi.removeAllEventSources(); // Remove previously selected dates

      // Clear previously selected date cells
      var selectedCells = document.querySelectorAll(".selected-date");
      selectedCells.forEach(function (cell) {
        cell.classList.remove("selected-date");
      });

      // Add custom class to the selected date cell

      var selectedCell = info.dayEl;
      if (selectedCell) {
        selectedCell.classList.add("selected-date");
      }

      let tds = today.toDateString();

      let clanDate = moment(info.dateStr, "YYYY-MM-DD").format(
        "ddd MMM DD YYYY"
      );
      // 👇️ Today's date

      if (tds === clanDate) {
        this.outputDate(moment().format());
      } else {
        this.outputDate(
          moment(info.dateStr, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ssZ")
        );
      }
    }
  };

  
}
