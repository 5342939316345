import { BrowserRouter } from "react-router-dom";
import Router from "./router/Router";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "swiper/css";

import "swiper/css/pagination";
import "./styles/animate.min.css";
import "./styles/style.scss";


import AOS from "aos";
import "aos/dist/aos.css";

import { useEffect } from "react";
import { getLocalData } from "./utils/localStorage";
import { addUserFromLocal } from "./redux/userSlice";
import { useDispatch } from "react-redux";

import runOneSignal from "./utils/onesignal";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    //initialize AOS
    setTimeout(() => {
      AOS.init();
    }, 1000);

    //check something is in local storage
    let userObj = getLocalData("userObj");
    if (userObj) {
      dispatch(addUserFromLocal(userObj));
    }
  }, []);

  useEffect(() => {
    runOneSignal();
  });

  // const onHandleTag = (tag) => {
  //   console.log('Tagging');
  //   OneSignal.sendTag("tech", tag).then(() => {
  //     console.log("Sent tag: " + tag);
  //   });
  // }

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
