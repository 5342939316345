import React from 'react';
import ChatDoc from './ChatDoc';

import pana from "../../static/chat/pana.png";
import chat_line from "../../static/chat/iconoir_chat-lines.svg";
import no_doc from "../../static/chat/no_doc.png";
import arrow from "../../static/chat/arrow.svg";

const ChatLeftSide = ({ doctors, handleChatbox }) => {
    // const [doctors, setDoctors] = React.useState([]);


    const [isActive, setIsActive] = React.useState(true);
    const [isReaded, setIsReaded] = React.useState(true);

    const handleSelectDoctor = (id) => {
        // setDoctors(doctors.map((doc) => doc.id === id ? {...doc, isActive: true} : {...doc, isActive: false}));
        handleChatbox();

    }

    return (
        <div className='left_side'>
            <div className="header">
                <div className="title">
                    Chat
                </div>
                <div className="header_top">
                    <div className="img">
                        <img src={pana} alt="" />
                    </div>
                    <div className="header_text">
                        <p>Chat with our receptionist for general inquiries or booking an appointment. </p>
                        <button className="btnr btnr-primary">
                            <img src={chat_line} alt="" />
                            CHAT
                        </button>
                    </div>
                </div>
            </div>
            <div className="chatList">
                <div className="msg_list">

                    {/* if any message */}
                    <span className="title">Doctors</span>
                    {
                        doctors.length > 0 ?
                            <div className="massage_box">
                                {
                                    doctors.map((doc) => (
                                        <ChatDoc
                                            key={doc.id}
                                            doc={doc}
                                            handleSelectDoctor={handleSelectDoctor}
                                        />
                                    ))
                                }
                            </div>
                            :
                            <div className='no_doc'>
                                <img src={no_doc} alt="" />
                                <span className='noDoc_text'>No doctor is available for chat!</span>
                                <span className='noDoc_small'>Once you book an appointment can chat with <br />the doctor</span>
                                <button className='btnr btnr-primary'>
                                    Book appointment
                                    <img src={arrow} alt="" />
                                </button>
                            </div>
                    }

                </div>
            </div>
        </div>
    );
};

export default ChatLeftSide;