import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

import file from "../../static/upload/file.svg";

function PatientDocs(props) {
  const { patientInformationDto, appointmentId } = props;
  const navigate = useNavigate();
  const navigateToUploadReports = (stateforNextPage) => {
    
    if (stateforNextPage) {
      let tempFilesArray = stateforNextPage.attachments.map((attach) => {
        let tempAttachment = {
          ...attach,
          isUploading: false,
          isUploaded: true,
        };

        return tempAttachment;
      });

      let modifiedState = {
        ...stateforNextPage,
        attachments: [...tempFilesArray],
      };

      navigate("/uploadreports", { state: modifiedState });
    } else {
   
        let newState = { id: 0, appointmentId: appointmentId, reasonForVisit: "", attachments: [] };
        console.log(newState)
      navigate("/uploadreports", {
        state: newState,
      });
    }

    //check whether user is Logged in ??
  };

  return (
    <div className="patinetDocs mt-3">
      <div className="title mb-3">Uploaded medical reports</div>
      {patientInformationDto ? (
        <Row>
          {patientInformationDto.attachments.length > 0 ? (
            <>
              <Col xs={6} className="file-status">
                <img src={file} alt="file" />
                <span className="counter">
                  {patientInformationDto.attachments.length}
                </span>
                <span className="sub">
                  File{patientInformationDto.attachments.length > 1 ? "s" : ""}
                </span>
              </Col>
              <Col xs={6}>
                <button
                  className="btnr btnr-primary-outline  btnr-sm "
                  onClick={() =>
                    navigateToUploadReports({
                      ...patientInformationDto,
                    })
                  }
                >
                  View All <span className="arr"></span>
                </button>
              </Col>
            </>
          ) : (
            <Col xs={12} className="text-center">
              <button
                className="btnr btnr-primary-outline  btnr-sm "
                onClick={() =>
                  navigateToUploadReports({
                    ...patientInformationDto,
                  })
                }
              >
                Upload Documents <span className="arr"></span>
              </button>
            </Col>
          )}
          {patientInformationDto.reasonForVisit.length > 0 && (
            <Col xs={12} className="mt-4">
              <div className="rov-parent">
                <div className="rov">Reason of visit</div>
                <div className="para">
                  {patientInformationDto.reasonForVisit}
                </div>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col xs={12}>
            <button
              className="btnr btnr-primary-outline btnr-hover  btnr-sm "
              onClick={() => navigateToUploadReports(null)}
            >
              View All <span className="arr"></span>
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default PatientDocs;
