import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TreatmentTrack from "./treatmentTrack/TreatmentTrack";

function Treatments() {
  const treatmentsList = useSelector((state) => state.homepage.treatments.list);
  return (
    treatmentsList.length > 0 && (
      <section className="treatment-home">
        <div className="container">
          <Row>
            <Col xs={12}>
              <span className="section-pill">Treatments</span>
            </Col>
            <Col xs={12}>
              <span className="section-title section-title-primary">
                Explore our Treatments
              </span>
            </Col>
            <Col xs={12}>
              <TreatmentTrack treatmentsList={treatmentsList} />
            </Col>
          </Row>
        </div>
      </section>
    )
  );
}

export default Treatments;
