import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function PaymentSummary(props) {
  const {
    apptType,
    doctorObj,
    paymentDetails,
    setPaymentDetails,
    processObj,
    isFollowUp,
    isFreeFollowUp,
  } = props;
  //clinic payment settings
  const paymentSettings = useSelector(
    (state) => state.clinic.clincObj.paymentSettings
  );

  //currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  // useEffect(() => {
  //   let baseAmt = 0;
  //   let taxAmt = 0;
  //   let shippingAmt = 0;
  //   console.log(paymentSettings);
  //   if (isFollowUp) {
  //     if (isFreeFollowUp) {
  //       //do nothing
  //     } else {
  //       if (apptType === "InPerson") {
  //         baseAmt = parseFloat(doctorObj.inPersonConsultationFee);
  //       } else if (apptType === "VideoCall") {
  //         baseAmt = parseFloat(doctorObj.videoCallConsultationFee);
  //       } else if (apptType === "Treatment") {
  //         baseAmt = parseFloat(processObj.price);
  //       }

  //       if (paymentSettings.isTaxEnabled) {
  //         if (paymentSettings.isTaxBasedOnNetAmount) {
  //           taxAmt = parseFloat(paymentSettings.taxNetAmount);
  //         } else if (paymentSettings.isTaxBasedOnPercentage) {
  //           taxAmt =
  //             baseAmt * (parseFloat(paymentSettings.taxPercentage) / 100);
  //         }
  //       }

  //       if (paymentSettings.isServiceChargeEnabled) {
  //         if (paymentSettings.isServiceChargeBasedOnNetAmount) {
  //           shippingAmt = parseFloat(paymentSettings.serviceChargeNetAmount);
  //         } else if (paymentSettings.isServiceChargeBasedOnPercentage) {
  //           shippingAmt =
  //             baseAmt *
  //             (parseFloat(paymentSettings.serviceChargePercentage) / 100);
  //         }
  //       }
  //     }
  //   } else {
  //     if (apptType === "InPerson") {
  //       baseAmt = parseFloat(doctorObj.inPersonConsultationFee);
  //     } else if (apptType === "VideoCall") {
  //       baseAmt = parseFloat(doctorObj.videoCallConsultationFee);
  //     } else if (apptType === "Treatment") {
  //       baseAmt = parseFloat(processObj.price);
  //     }

  //     if (paymentSettings.isTaxEnabled) {
  //       if (paymentSettings.isTaxBasedOnNetAmount) {
  //         taxAmt = parseFloat(
  //           paymentSettings.taxNetAmount ? paymentSettings.taxNetAmount : 0
  //         );
  //       } else if (paymentSettings.isTaxBasedOnPercentage) {
  //         taxAmt =
  //           baseAmt *
  //           (parseFloat(
  //             paymentSettings.taxPercentage ? paymentSettings.taxPercentage : 0
  //           ) /
  //             100);
  //       }
  //     }

  //     if (paymentSettings.isServiceChargeEnabled) {
  //       if (paymentSettings.isServiceChargeBasedOnNetAmount) {
  //         shippingAmt = parseFloat(
  //           paymentSettings.serviceChargeNetAmount
  //             ? paymentSettings.serviceChargeNetAmount
  //             : 0
  //         );
  //       } else if (paymentSettings.isServiceChargeBasedOnPercentage) {
  //         shippingAmt =
  //           baseAmt *
  //           (parseFloat(
  //             paymentSettings.serviceChargePercentage
  //               ? paymentSettings.serviceChargePercentage
  //               : 0
  //           ) /
  //             100);
  //       }
  //     }
  //   }

  //   setPaymentDetails({
  //     ...paymentDetails,
  //     fee: baseAmt,
  //     tax: taxAmt,
  //     serviceFee: shippingAmt,
  //   });
  // }, []);

  return (
    <div className="apt-paysummary">
      <div className="header">Payment Summary</div>
      <div className="body">
        <Row>
          <Col xs={8} className="ps-key">
            Consultation charges
          </Col>
          <Col xs={4} className="ps-value">
            {currencySymbol} {paymentDetails.fee}
          </Col>

          {paymentSettings.isTaxEnabled && (
            <>
              <Col xs={8} className="ps-key two">
                Tax
              </Col>
              <Col xs={4} className="ps-value two">
                {" "}
                {currencySymbol} {paymentDetails.tax}
              </Col>
            </>
          )}
          {paymentSettings.isServiceChargeEnabled && (
            <>
              <Col xs={8} className="ps-key two">
                Service Charge
              </Col>
              <Col xs={4} className="ps-value two">
                {currencySymbol} {paymentDetails.serviceFee}
              </Col>
            </>
          )}
          <Col xs={12} className="payment-divider"></Col>
          <Col xs={8} className="ps-key-bold">
            Payment Total
          </Col>
          <Col xs={4} className="ps-value-bold">
            {currencySymbol}{" "}
            {paymentDetails.fee +
              paymentDetails.tax +
              paymentDetails.serviceFee}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PaymentSummary;
