import React from 'react';
import avatar from "../../static/chat/avatar.png";

const UserMsg = () => {
    return (
        <div className="chat user_side">
            <div className="chat_text">
                <p className="chat_msg">Hello, I’m FitBot! 👋 I’m your personal sport assistant. How can I help you?</p>
                <span>3:14 pm</span>
            </div>
            <img src={avatar} alt="" />
        </div>
    );
};

export default UserMsg;