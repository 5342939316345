import React, { useEffect, useRef, useState } from "react";
import { getLocalData } from "../../utils/localStorage";
import { mediaUpload } from "../../services/patientService";
import { Col, Row, Spinner } from "react-bootstrap";

import plus from "../../static/upload/plus.svg";
import pdf from "../../static/upload/pdf.svg";
import jpg from "../../static/upload/jpg.svg";
import unkn from "../../static/upload/unkn.svg";
import png from "../../static/upload/png.svg";
import del from "../../static/upload/del.svg";

import { fileSzeGenerator } from "../../utils/fileSzeGenerator";

function FileUploadCTX({ files, setFiles }) {
  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    addFiles(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    addFiles(selectedFiles);
  };

  const addFiles = (newFiles) => {
    const totalFiles = files.length;
    const remaingFiles = 5 - totalFiles;

    if (remaingFiles > 0) {
      const filesTOBeAdded = newFiles.slice(0, remaingFiles);
      let tempFiles = filesTOBeAdded.map((file) => {
        let tempFile = {
          file: file,
          fileName: file.name,
          isUploading: true,
          isUploaded: false,
        };

        return tempFile;
      });
      const combinedFiles = [...files, ...tempFiles];

      setFiles(combinedFiles);
      uploadFiles(combinedFiles);

      // if (totalFiles > 5) {
      //   const excessFiles = newFiles.slice(5 - files.length);
      //   alert(`You can only add ${5 - files.length} more files. The following files were not added: ${excessFiles.map(file => file.name).join(', ')}`);
      // }
    } else {
      alert("You have reached the maximum limit of 5 files.");
    }
  };

  const uploadFiles = async (files) => {
    const tempFiles = [];

    for (const file of files) {
      if (file.isUploading) {
        const formData = new FormData();
        formData.append("file", file.file);

        try {
          const response = await mediaUpload(
            formData,
            getLocalData("user-info")?.userToken
          );

          if (response.status === "Success") {
            tempFiles.push({
              fileName: file.file.name,
              isUploading: false,
              isUploaded: true,
              attachmentUrl: response.response.pictureUrl,
              fileSize: response.response.size,
              attachmentId: response.response.pictureId,
            });
          }
        } catch (err) {
          // Handle errors as needed
          console.error(err);
          tempFiles.push(file); // Push the original file if upload fails
        }
      } else {
        tempFiles.push(file); // Push the original file if it's not uploading
      }
    }

    setFiles(tempFiles);
  };

  const getExtenson = (url) => {
    let extension = url.split(/[#?]/)[0].split(".").pop().trim();
    if (extension === "PDF" || extension === "pdf") {
      return "pdf";
    }else if (extension === "JPG" || extension === "jpg" || extension === "JPEG" || extension === "jpeg" ) {
      return "jpg";
    }else if (extension === "PNG" || extension === "png") {
      return "png";
    }else{
      return "unkn";
    }
  };
  return (
    <div className="file-upload-container">
      <label
        onClick={handleFileSelect}
        htmlFor="fileInput"
        className={`file-upload-dropzone ${
          files.length ? "file-selected" : ""
        }`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="labelftc">
          <img src={plus} alt="plux" />
          <br />
          <span className="filepond--label-action">
            Click to add document/image <br />
            <span className="bel-upload">
              Supported file format jpeg, png, jpg, pdf
            </span>
          </span>
        </div>
      </label>

      {files.length > 0 && (
        <Row className="cust-uploader-list">
          {files.map((file, index) => (
            <Col xs={12} sm={4} key={index}>
              <div
                className={`cust-item  ${file.isUploading ? "loading" : ""}`}
              >
                <Row>
                  <Col xs={2}>

                  {file.isUploaded && (
                      <div className="spfuimg">
                        {getExtenson(file.attachmentUrl) === 'pdf' && (
                          <img src={pdf} alt="sad" />
                        )}
                        {getExtenson(file.attachmentUrl) === 'png' && (
                          <img src={png} alt="sad" />
                        )}
                        {getExtenson(file.attachmentUrl) === 'jpg' && (
                          <img src={jpg} alt="sad" />
                        )}
                        {getExtenson(file.attachmentUrl) === 'unkn' && (
                          <img src={unkn} alt="sad" />
                        )}
                      </div>
                    )}
                  </Col>
                  <Col xs={8} className="file_name">
                  <a href={file.attachmentUrl} target="_blank" className="name">
                    {file.isUploaded && (
                      <>
                        {file.fileName}
                        <br />
                     
                        <span>{fileSzeGenerator(file.fileSize)}</span>
                      </>
                    )}
                  </a>
                  </Col>
                  <Col xs={2}>
                    {!file.isUploading ? (
                      <span onClick={() => handleRemoveFile(index)}>
                       <img className="del-btn" src={del} alt="del" />
                      </span>
                    ): ( 
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    )}
                  </Col>
                </Row>
            

              
                
              </div>
            </Col>
          ))}
        </Row>
      )}

      <input
        id="fileInput"
        type="file"
        style={{ display: "none" }}
        multiple
        onChange={handleFileInputChange}
      />
    </div>
  );
}

export default FileUploadCTX;
