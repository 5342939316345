import axios from "axios";
const clinicId = process.env.REACT_APP_CLINIC_ID;

export const GetBanners = async () => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetClinicBanners/`,
    {
      clinicId,
      imageSize: 0,
      platformType: null,
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};
