import React from 'react';
import { Col } from 'react-bootstrap';
import image from '../../static/Spaciality/treatment.png';
import img2 from '../../static/Spaciality/2nd_img.png';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';

const Spaciality = ({ spec }) => {

    return (
        <Col xs={12} md={6} lg={4} className='sp_item'>
            <div className='spaciality'>
                <div className="img">
                    <img src={spec.pictureUrl} alt="" />
                    {/* <img src={image} alt="" /> */}
                </div>

                <div className='card_info'>
                    <div className='info_top'>
                        <img src={spec.iconUrl} alt="" />
                        <h3>{spec.name}</h3>
                    </div>
                    <div className='info_text'>

                        <p
                            dangerouslySetInnerHTML={{
                                __html:
                                    spec.description,
                            }}></p>
                    </div>
                </div>
                <Link
                    to={`/spaciality/${spec.slug}`}
                    className='view_btn btnr btnr-primary-outline btnr-hover'>
                    View more
                    <span className='arr'></span>
                </Link>
            </div>
        </Col>
    );
};

export default Spaciality;