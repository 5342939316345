import { configureStore } from "@reduxjs/toolkit";
import homePageReducer from "./homePageSlice";
import webAppReducer from "./webAppSlice";
import clinicReducer from "./clinicSlice";
import userReducer from "./userSlice";

export const store = configureStore({
  reducer: {
    clinic: clinicReducer,
    homepage: homePageReducer,
    webapp: webAppReducer,
    user: userReducer,
  },
});
