import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import chat from "../../static/chat/chat.svg";
import pana from "../../static/chat/pana.png";
import chat_line from "../../static/chat/iconoir_chat-lines.svg";
import blue_dot from "../../static/chat/blue_dot.svg";
import red_dot from "../../static/chat/red_dot.gif";
import avatar from "../../static/chat/avatar.png";
import ChatDoc from "../chat/ChatDoc";
import noMsg from "../../static/chat/no_msg.png"




function ChatDrop() {
  const [isDropVisible, setIsDropVisible] = useState(false);

  const dispatch = useDispatch();

  const [massages, setMassages] = useState([]);

  const containerVariats = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,

      transition: {
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
    exit: {
      opacity: 0,

      transition: {
        delay: 0.4,
        duration: 0.3,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
  };

  //check for clinic Details
  const userDetails = useSelector((state) => state.user); 


  return (
    <>
      <div className="chatDrop-parent d-flex d-sm-none">
        <Link to="/chat" className="notiff-img ">
          <img src={chat} alt="userimg" />
        </Link>
      </div>
      <div
        className={`chatDrop-parent d-none d-sm-flex`}
        onMouseEnter={() => setIsDropVisible(true)}
        onMouseLeave={() => setIsDropVisible(false)}
      >
        <div className="notiff-img ">
          <img src={chat} alt="userimg" />
        </div>


        <AnimatePresence>
          {isDropVisible && (
            <motion.div
              variants={containerVariats}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="chatDrop-menu"
              onMouseEnter={() => setIsDropVisible(true)}
              onMouseLeave={() => setIsDropVisible(false)}
            >
              <div className="header">
                <div className="title">
                  Chat
                </div>
                <div className="header_top">
                  <div className="img">
                    <img src={pana} alt="" />
                  </div>
                  <div className="header_text">
                    <p>Chat with our receptionist for general inquiries or booking an appointment. </p>
                    <button className="btnr btnr-primary">
                      <img src={chat_line} alt="" />
                      CHAT
                    </button>
                  </div>
                </div>
              </div>

              <div className="msg_list">

                {
                  // if there is any message
                  massages.length > 0 ?
                    <div className="massage_box">
                      <span className="title">Messages</span>

                      <ChatDoc isActive={true} />
                      <ChatDoc isActive={false} />
                      <ChatDoc isActive={false} />

                    </div>
                    :
                    // if there is no message
                    <div className="massage_btn">
                      <img src={noMsg} alt="" />

                      <span className="title">No Messages</span>

                      <button className="btnr btnr-primary btnr-hover">
                        CHAT WITH DOCTOR
                        <span className="arr"></span>
                      </button>
                    </div>
                }
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}

export default ChatDrop;