import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import userApp from "../static/userapp/userapp.svg";
import { useEffect } from "react";
import {
  GetAppointments,
  GetPreviousAppointments,
  GetUpcomingAppointments,
} from "../services/apptservice";
import { useState } from "react";
import moment from "moment";

import Pagination2 from "@mui/material/Pagination";

import vc from "../static/appt/vc.svg";
import cal from "../static/userapp/cal.svg";
import clksm from "../static/userapp/clk-sm.svg";
import ip from "../static/appt/ip.svg";
import gender from "../static/userapp/gender.svg";
import noUpAppoinment from "../static/Appointments/amico.png";
import prevAppoinment from "../static/Appointments/rafiki.png";
import date_icon from "../static/Appointments/date.svg";
import time_icon from "../static/Appointments/time.svg";
import arr from "../static/btn/arr.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/scss/navigation";
import { Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import FollowUpList from "../components/appt/FollowUpList";
import { Skeleton } from "@mui/material";
import AppStatus from "../components/appt/AppStatus";

const PAGE_SIZE = 10;
const APP_OBJ = {
  doctorId: 0,
  pageNo: 1,
  healthCenterId: 0,
  pageSize: PAGE_SIZE,
  imageSize: 0,
  patientId: 2,
  type: "",
  appointmentType: "",
};

function UserAppointment() {
  const userDetails = useSelector((state) => state.user.userObj.patientData);
  const userToken = useSelector((state) => state.user.userObj.token);
  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  const healthCenterId = useSelector(
    (state) => state.clinic.clincObj.defaultHealthCenter.id
  );

  const [previousAppointment, setPreviousAppointment] = useState({
    list: [],
    isLoading: false,
    isSuccess: false,
    msg: "",
    pageNo: 1,
    noOfPages: 1,
    count: 0,
  });
  const [upcommingAppointment, setUpcommingAppointment] = useState({
    list: [],
    isLoading: false,
    isSuccess: false,
    msg: "",
    pageNo: 1,
    noOfPages: true,
    count: 0,
  });

  useEffect(() => {
    let temmObjPrevious = {
      ...APP_OBJ,
      healthCenterId,
      type: "Previous",
      pageNo: previousAppointment.pageNo,
      patientId: userDetails.id,
    };

    fetchAppointments(temmObjPrevious);

    let temmObjUpcomming = {
      ...APP_OBJ,
      healthCenterId,
      type: "UpComing",
      pageNo: upcommingAppointment.pageNo,
      pageSize: 100,
      patientId: userDetails.id,
    };
    fetchAppointments(temmObjUpcomming);
  }, []);

  const fetchAppointments = (obj) => {
    if (obj.type === "Previous") {
      setPreviousAppointment({
        ...previousAppointment,
        isLoading: true,
      });

      let tempObj = {
        ...obj,
        type: "",
      };
      GetPreviousAppointments(tempObj, userToken)
        .then((res) => {
          let tempPrevObj = {
            ...previousAppointment,
            pageNo: obj.pageNo,
            noOfPages: Math.ceil(res.count / PAGE_SIZE),
            isLoading: false,
            isSuccess: true,
            count: res.count,
            list: [...res.appointments],
          };

          setPreviousAppointment({
            ...tempPrevObj,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (obj.type === "UpComing") {
      setUpcommingAppointment({
        ...upcommingAppointment,
        isLoading: true,
      });

      let tempObj = {
        ...obj,
        type: "",
      };
      GetUpcomingAppointments(tempObj, userToken)
        .then((res) => {
          let tempPrevObj = {
            ...upcommingAppointment,

            isLoading: false,
            isSuccess: true,
            count: res.count,
            list: [...res.appointments],
          };

          setUpcommingAppointment({
            ...tempPrevObj,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (event, value) => {
    let temmObjPrevious = {
      ...APP_OBJ,
      healthCenterId,
      type: "Previous",
      patientId: userDetails.id,
      pageNo: value,
    };
    fetchAppointments(temmObjPrevious);
  };

  let dragging = false;
  const settings = {
    dots: true,
    infinite: false,
    arrows: false,

    slidesToShow: 2,
    slidesToScroll: 2,

    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),

    autoplay: false,
    speed: 400,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          infinite: false,
          centerPadding: "20px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const upcommingAppointmentSkeleton = (
    <Col xs={12} md={5} className="up_skeleton">
      <div className="doc">
        <div className="img">
          <Skeleton variant="rectangular" width={60} height={60} />
        </div>
        <div className="txt">
          <div className="name">
            <Skeleton variant="text" width={100} />
          </div>
          <div className="title">
            <Skeleton variant="text" width={80} />
          </div>
          <div className="date">
            <Skeleton variant="text" width={80} />
          </div>
        </div>
        <div className="icn">
          <Skeleton variant="text" width={80} />
        </div>
      </div>
      <div className="blwbody">
        <div className="cldr">
          <div className="img">
            <Skeleton variant="rectangular" width={30} height={30} />
          </div>
          <div className="text">
            <div className="title">
              <span><Skeleton variant="text" width={50} /></span>
            </div>
          </div>
        </div>
        <div className="">
          <button className="btnr btnr-primary ms-2 d-none">
            <Skeleton variant="text" width={100} />
          </button>
          <Skeleton variant="text" width={100} />
        </div>
      </div>
    </Col>
  );

  const previousSkeleton = (
    <Row className="privgr-d-row previous-skeleton">
      <Col xs={1} className="mathod">
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton className="txt" variant="text" width={80} height={20} />
      </Col>
      <Col xs={2} className="stat-p">
        <Skeleton variant="text" width={100} height={30} />
      </Col>
      <Col xs={2} className="time-p">
        <div className="dt">
          <Skeleton variant={100} className="img" width={30} height={30} />
          <div>
            <span className="time">
              <Skeleton variant="text" width={100} height={20} />
            </span>
            <span className="date">
              <Skeleton variant="text" width={100} height={20} />
            </span>
          </div>
        </div>
      </Col>
      <Col xs={2} className="duration">
        <div className="dt">
          <Skeleton variant="rectangle" width={30} height={30} />
          <div>
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="text" width={100} height={20} />
          </div>
        </div>
      </Col>
      <Col xs={2} className="relation-p ">
        <div className="relation">
          <div className="txt">
            <span className="name">
              <Skeleton variant="text" width={100} height={20} />
            </span>
            <span className="rel_type">
              <Skeleton variant="text" width={100} height={20} />
            </span>
          </div>
        </div>
      </Col>
      <Col xs={3} className="name-p justify-content-start">
        <div className="dctr">
          <Skeleton variant="circular" width={40} height={40} />
          <div className="d-t">
            <Skeleton variant="text" width={150} height={20} />
            <Skeleton variant="text" width={120} height={20} />
          </div>
        </div>
      </Col>
      <Col xs={2} className="btn-p">
        <Skeleton variant="rectangular" width={50} height={30} />
      </Col>
    </Row>
  );


  const previousSkeletonRows = () => {
    const reapetCount = 10;
    const rows = [];

    for (let index = 0; index < reapetCount; index++) {
      rows.push(previousSkeleton);
    }
    return rows;
  };

  return (
    <div className="main-body">
      <div className="container">
        <div className="userappt-main">
          <Row>
            <Col sm={3} xs={12}>
              <div className="sidenav">
                <img className="img" src={userDetails.pictureUrl} alt="user" />
                <div className="name">{userDetails.name}</div>
                <div className="icon-text">
                  <div className="icon">
                    <img className="star" src={gender} alt="gender" />
                  </div>
                  <div className="txt">
                    <span>Gender</span>
                    {userDetails.sex}
                  </div>
                </div>
                <div className="icon-text">
                  <div className="icon">
                    <img className="star" src={cal} alt="gender" />
                  </div>
                  <div className="txt">
                    <span>Date of BIrth</span>
                    {moment(userDetails.dob).format("DD, MMMM YY")}
                  </div>
                </div>
                <div className="noofappt">
                  <img src={userApp} alt="icn" />
                  <span>{previousAppointment.count} Appointments</span>
                </div>
              </div>
              <FollowUpList patientId={userDetails.id} />
            </Col>
            <Col sm={9} xs={12}>
              <div className="appt-parent">
                <div className="uppc">
                  <div className="header">
                    <div className="h1">Upcoming Appointments
                      <span>{upcommingAppointment?.list.length > 0 && ` (${upcommingAppointment.list.length})`}</span>
                    </div>
                  </div>
                  <div className="body">
                    <div className="list">
                      {!upcommingAppointment.isLoading ? (
                        <Swiper
                          slidesPerView={"auto"}
                          centeredSlides={false}
                          spaceBetween={20}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination, Navigation]}
                          navigation={true}

                          className="app-sch-card-parent mySwiper"
                        >
                          {upcommingAppointment.list.length > 0 ? (
                            upcommingAppointment.list.map((appt, index) => {
                              return (
                                <SwiperSlide
                                  className={`apt-card ${appt.appointmentType === "VideoCall"
                                    ? "vc"
                                    : ""
                                    }`}
                                  key={index}
                                >
                                  <div className="doc">
                                    <div className="img">
                                      <img
                                        src={appt.doctorDto.pictureUrl}
                                        alt="doc"
                                      />
                                    </div>
                                    <div className="txt">
                                      <div className="name">
                                        {appt.doctorDto.name}
                                      </div>
                                      <div className="title">
                                        {appt.doctorDto.title}
                                      </div>
                                      <div className="date">
                                        {moment
                                          .utc(appt.scheduledAtUtc)
                                          .local()
                                          .format("DD, MMMM YY")}
                                      </div>
                                    </div>
                                    <div className="icn">
                                      {appt.appointmentType === "InPerson" && (
                                        <>In-Person</>
                                      )}
                                      {appt.appointmentType === "VideoCall" && (
                                        <>Video call</>
                                      )}
                                      {appt.appointmentType === "Treatment" && (
                                        <>Treatment</>
                                      )}
                                    </div>
                                  </div>
                                  <div className="blwbody">
                                    <div className="cldr">
                                      <div className="img">
                                        <img src={clksm} alt="sd" />
                                      </div>
                                      <div className="text">
                                        <div className="title">
                                          Starts at{" "}
                                          <span>
                                            {`${moment
                                              .utc(appt.scheduledAtUtc)
                                              .local()
                                              .format("hh:mm a")} `}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="">
                                      <button className="btnr btnr-primary ms-2 d-none">
                                        Book Follow Up <span></span>{" "}
                                      </button>
                                      <Link
                                        to={`/appointmentsDetails/${appt.id}`}

                                        className="btnr btnr-sm btnr-primary"
                                      >
                                        View details
                                      </Link>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            })
                          ) : (
                            <div className="col-12 noUpAppoin">
                              <img className="img" src={noUpAppoinment} alt="" />
                              <h3>There is no upcoming appointment !</h3>
                              {
                                (clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) &&
                                <Link to="/selectdoctor" className="btnr btnr-primary btnr-hover app_button">
                                  Book Appointment
                                  <span className="arr"></span>
                                </Link>
                              }
                            </div>
                          )
                          }
                        </Swiper>
                      ) : (
                        <Row className="gap-5">
                          {upcommingAppointmentSkeleton}
                          {upcommingAppointmentSkeleton}
                        </Row>

                      )
                      }
                    </div>
                  </div>
                </div>
                <div className="prev">
                  {
                    !previousAppointment.isLoading ? (
                      previousAppointment.list.length > 0 ? (
                        <div className="header">
                          <div className="h1">Previous Appointments</div>
                          <div className="pagination d-none d-md-block">
                            <Pagination2
                              count={previousAppointment.noOfPages}
                              page={previousAppointment.pageNo}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="header">

                        </div>
                      )
                    ) : (
                      <div className="header">
                        <div className="h1"><Skeleton variant="rectangle" width={200} height={30} /></div>
                        <div className="pagination d-none d-md-block">
                          <Pagination2
                            count={previousAppointment.noOfPages}
                            page={previousAppointment.pageNo}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )
                  }
                  <div className="body">
                    {!previousAppointment.isLoading ? (
                      previousAppointment.list.length > 0 && (
                        previousAppointment.list.map((appt, index) => (
                          <>
                            <Row key={appt.id} className="privgr-d-row pd-desk">
                              <Col xs={1} className="icn-p">
                                {console.log(appt.appointmentType)}
                                {appt.appointmentType === "InPerson" && (
                                  <>
                                    <img src={ip} alt="ip" />
                                    <span>InPerson</span>
                                  </>
                                )}
                                {appt.appointmentType === "VideoCall" && (
                                  <>
                                    <img src={vc} alt="vc" />
                                    <span>Video Call</span>
                                  </>
                                )}
                                {appt.appointmentType === "Treatment" && (
                                  <>
                                    <img
                                      src={appt.treatment.pictureUrl}
                                      alt="tpurl"
                                      className="tpurl"
                                    />
                                    <span>Treatment</span>
                                  </>
                                )}
                              </Col>
                              <Col xs={2} className="stat-p">
                                <AppStatus key={index} appt={appt} />
                                {/* {appt.isCancelled && (
                                <span className="status red">
                                  Cancelled
                                  <span></span>
                                </span>
                              )}
                              {!appt.isCancelled && (
                                <span className="status green">Complete</span>
                              )}
                              {appt.isFollowUp && (
                                <span className="status yellow d-none">Follow Up</span>
                              )} */}
                              </Col>
                              <Col xs={2} className="time-p">
                                <div className="dt">
                                  <img src={date_icon} alt="" />
                                  <div>
                                    <span className="date_t">Date</span>
                                    <span className="time">
                                      {moment
                                        .utc(appt.scheduledAtUtc)
                                        .local()
                                        .format("DD, MMMM, YY")}
                                    </span>
                                    <span className="date">
                                      {`${moment
                                        .utc(appt.scheduledAtUtc)
                                        .local()
                                        .format("hh:mm a")} - ${moment
                                          .utc(appt.scheduledAtUtc)
                                          .add(appt.durationMin, "minutes")
                                          .local()
                                          .format("hh:mm a")}`}
                                    </span>
                                  </div>
                                </div>
                              </Col>

                              <Col xs={2} className="relation-p ">
                                <div className="relation">
                                  <div className="txt">
                                    <span className="name">
                                      {appt.name}
                                    </span>
                                    <span className="rel_type">
                                      {appt.appointmentForRelation ? appt.appointmentForRelation : "Patient"}
                                    </span>
                                  </div>
                                </div>
                              </Col>

                              <Col xs={3} className="name-p justify-content-start">
                                <div className="dctr">
                                  <div className="img">
                                    <img
                                      alt="doc"
                                      src={appt.doctorDto.pictureUrl}
                                    />
                                  </div>
                                  <div className="txt">
                                    <span className="name">
                                      {appt.doctorDto.name}
                                    </span>
                                    <span className="title">
                                      {appt.doctorDto.title}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={2} className="btn-p">
                                <Link
                                  to={`/appointmentsDetails/${appt.id}`}
                                  className="btnr btnr-primary-light"
                                >
                                  View
                                </Link>
                              </Col>
                            </Row>

                            {/* responsive */}
                            <Link to={`/appointmentsDetails/${appt.id}`} key={appt.id} className="row privgr-d-row privgr-d-row-responsive">
                              <Col xs={1} className="icn-p">
                                {appt.appointmentType === "InPerson" && (
                                  <>
                                    <img src={ip} alt="ip" />
                                    <span>In Person</span>
                                  </>
                                )}
                                {appt.appointmentType === "VideoCall" && (
                                  <>
                                    <img src={vc} alt="vc" />
                                    <span>Video Call</span>
                                  </>
                                )}
                                {appt.appointmentType === "Treatment" && (
                                  <>
                                    <img
                                      src={appt.treatment.pictureUrl}
                                      alt="tpurl"
                                      className="tpurl"
                                    />
                                    <span>Treatment</span>
                                  </>
                                )}
                              </Col>
                              <Col xs={2} className="stat-p">
                                <AppStatus appt={appt} />
                              </Col>
                              <Col xs={2} className="time-p">
                                <div className="dt">
                                  <img src={date_icon} alt="" />
                                  <div>
                                    <span className="date_t">Date</span>
                                    <span className="time time_m">
                                      {moment
                                        .utc(appt.scheduledAtUtc)
                                        .local()
                                        .format("DD, MMM YY")}
                                    </span>
                                    <span className="date">
                                      {`${moment
                                        .utc(appt.scheduledAtUtc)
                                        .local()
                                        .format("hh:mm a")} - ${moment
                                          .utc(appt.scheduledAtUtc)
                                          .add(appt.durationMin, "minutes")
                                          .local()
                                          .format("hh:mm a")}`}
                                    </span>
                                  </div>
                                </div>
                              </Col>

                              <Col xs={2} className="duration">
                                <div className="dt">
                                  <img src={time_icon} alt="" />
                                  <div>
                                    <span className="date_t">Duration</span>
                                    <span className="date">
                                      30 min
                                    </span>
                                  </div>
                                </div>
                              </Col>

                              <Col xs={2} className="relation-p ">
                                <div className="relation">
                                  <div className="txt">
                                    <span className="name">
                                      {appt.name}
                                    </span>
                                    <span className="rel_type">
                                      {appt.appointmentForRelation ? appt.appointmentForRelation : "Patient"}
                                    </span>
                                  </div>
                                </div>
                              </Col>

                              <Col xs={3} className="name-p justify-content-start">
                                <div className="dctr">
                                  <div className="img">
                                    <img
                                      alt="doc"
                                      src={appt.doctorDto.pictureUrl}
                                    />
                                  </div>
                                  <div className="txt">
                                    <span className="name">
                                      {appt.doctorDto.name}
                                    </span>
                                    <span className="title">
                                      {appt.doctorDto.title}
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Link>
                          </>
                        ))
                      )
                      // : (
                      //   <div className="col-12 prev_appoinment">
                      //     <img className="img" src={prevAppoinment} alt="" />

                      //     <span>
                      //       There is no Previous appointment still not booked yet
                      //       Please make an appointment
                      //     </span>

                      //     <Link to={`/selectdoctor`} className="btn app_button">
                      //       Book Appointment
                      //       <img src={arr} alt="Arrow" className="arr" />
                      //     </Link>
                      //   </div>
                      // )
                    ) : (
                      previousSkeletonRows()
                    )}

                    <div className="pagination d-block d-md-none">
                      <Pagination2
                        count={previousAppointment.noOfPages}
                        page={previousAppointment.pageNo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default UserAppointment;
