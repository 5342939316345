import axios from "axios";
const clinicId = process.env.REACT_APP_CLINIC_ID;

export const getTOkenForAgora = async (obj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetAccessTokenForVideoCall/`,
    {
      ...obj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};


export const getTokenForVideoSDK = async (id, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/CreateRoom/`,{
      appointmentId: id
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};
