import React from "react";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useSelector } from "react-redux";
import phone_icon from "../static/contact/phone.webp"
import email_icon from "../static/contact/email.webp"
import location from "../static/contact/location.webp";
import mark from "../static/contact/7.png";
import { Col, Row } from "react-bootstrap";
import App1 from "../static/contact/App mockup 1.png";

function Contact() {
  useDocumentTitle("Contact");

  const [isLoading, setIsLoading] = React.useState(false);
  const [radio, setRadio] = React.useState(null);

  const userDetails = useSelector((state) => state.user.userObj);

  const contactINFO = useSelector((state) => state.clinic.clinincInfo);

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  const handleRadioChange = (e) => {
    setRadio(e.target.value);
  };

  return (
    <div className="main-body contect-page">
      <div className="container">
        {
          userDetails ? (
            <>
              <div className="back_from">
                <span className="icon-button-left-line"></span>
                Contect Us
              </div>
              <div className="contect_us">
                <div className="contect_title">
                  <h2>Contect Us</h2>
                  <span>Any Question or remarkes? Just Write us message!</span>
                </div>
                <div className="main_contect">
                  <div className="contect_info">
                    <div>
                      <h3>Contact Information</h3>
                      <span>Say something to start a live chat!</span>
                    </div>
                    <div className="info">
                      <div className="con_item">
                        <img src={phone_icon} alt="" />
                        <span>+10123456789</span>
                      </div>
                      <div className="con_item">
                        <img src={email_icon} alt="" />
                        <span>demo@gmail.com</span>
                      </div>
                      <div className="con_item">
                        <img src={location} alt="" />
                        <span>132 Dartmouth Street Boston,<br /> Massachusetts 02156 United States</span>
                      </div>
                    </div>
                    <img className="b_mark" src={mark} alt="" />
                  </div>
                  <div className='contect'>
                    <form onSubmit={handleSubmit} className="contect_form">
                      <div className="input_div">
                        <div className='input'>
                          <label htmlFor="First_name">First Name</label>
                          <input required type="text" placeholder="Enter Your First Name" />
                        </div>
                        <div className="input">
                          <label htmlFor="Last_name">Last Name</label>
                          <input type="" placeholder="Enter Your Last Name" />
                        </div>
                      </div>
                      <div className="input_div">
                        <div className='input'>
                          <label htmlFor="Email">Email</label>
                          <input required type="email" placeholder='Enter your email'
                            name='email' />
                        </div>
                        <div className="input">
                          <label htmlFor="Phone_number">Phone Number</label>
                          <input type="number" placeholder='Enter your phone number' />
                        </div>
                      </div>
                      <div className="">
                        <div className='radio_input'>
                          <div>
                            <input
                              type="radio"
                              name="age"
                              value="Option 1"
                              checked={radio === "Option 1"}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="age1">
                              General Inquiry
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              name="age"
                              value="Option 2"
                              checked={radio === "Option 2"}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="age2">
                              General Inquiry
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              name="age"
                              value="Option 3"
                              checked={radio === "Option 3"}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="age3">
                              General Inquiry
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              name="age"
                              value="Option 4"
                              checked={radio === "Option 4"}
                              onChange={handleRadioChange}
                            />
                            <label htmlFor="age4">
                              General Inquiry
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='input_b'>
                        <label htmlFor="discription">Discription</label>
                        <input
                          className="textareaField"
                          placeholder='Enter your discription'
                        />
                      </div>
                      <div className="submit">
                        <button disabled={isLoading ? true : false} className="btnr btnr-primary btnr-hover">
                          Send Message
                          <span className="arr"></span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          ) :
            (
              <section className="download-contect d-sm-block">
                <div className="container">
                  <div className="base-cont">
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="left-side">
                          <div>
                            <span className="section-title section-title-primary">
                              Chat Options Avaailble in Mobile App !
                            </span>
                          </div>

                          <span className="para">
                            Our App  is simple to navigate and packed with useful features. It also integrates seamlessly regarding booking of a doctor schedule
                          </span>
                          <div className="d-flex">
                            {contactINFO?.cObject?.appStore?.length > 0 && (
                              <a
                                href={contactINFO?.cObject?.appStore}
                                target="_blank"
                                className="btnr btnr-primary btnr-download btnr-hover"
                              >
                                APP STORE <span className="arr"></span>
                              </a>
                            )}
                            {contactINFO?.cObject?.playStore?.length > 0 && (
                              <a
                                href={contactINFO?.cObject?.playStore}
                                target="_blank"
                                className="ms-2 btnr btnr-primary btnr-download btnr-hover"
                              >
                                PLAY STORE <span className="arr"></span>
                              </a>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={5} className="right-side">
                        <img src={App1} className="app1" alt="cu" />
                      </Col>

                    </Row>
                  </div>
                </div>
              </section>
            )
        }
      </div>
    </div>
  );
}

export default Contact;
