import React from "react";
import { useSelector } from "react-redux";

import doc from "../../static/MegaMenu/doc.png";
import treat from "../../static/MegaMenu/treat.png";

function MegamenuHOC(WrappedComponent) {

  return function WithDoctorListWrapper(props) {
    const doctorList = useSelector((state) => state.homepage.ourDoctorsHome.list);
    const treatmentsList = useSelector((state) => state.homepage.treatments.list);

    const testDtat = useSelector((state) => state.homepage);


    ////currency SYmbol
    const currencySymbol = useSelector(
      (state) => state.clinic.clincObj.clinic.currencyDto.symbol
    );

    let list = [];
    let comTitle = "";
    let comImg = null;
    let viewAll = "";

    // console.log(doctorList)
    const { type, ...otherProps } = props;
    const upperCaseName = type.toUpperCase();




    if (type === "doctor") {
      list = doctorList.map((item, index) => {
        let tempObj = {
            name: item.name,
            decs: item.title,
            link: `/doctorprofile/${item.slug}`
        }
        return tempObj
      })

      comTitle = "Doctors";
      comImg = doc;
      viewAll = "/selectdoctor"
    } else if (type === "treatment") {
      list = treatmentsList.map((item, index) => {
        let tempObj = {
            name: item.name,
            decs: item.shortDescription,
            link: `/treatment/${item.slug}`,
            cost:  `(${currencySymbol} ${item.price})`
        }
        return tempObj
      })

      comTitle = "treatment";
      comImg = treat;
      viewAll = null
    }else if(type === "speciality"){
      list = treatmentsList.map((item, index) => {
        let tempObj = {
            name: item.name,
            decs: item.shortDescription,
            link: `/speciality/${item.slug}`,
            cost:  `(${currencySymbol} ${item.price})`
        }
        return tempObj
      })

      comTitle = "Speciality";
      comImg = doc;
      viewAll = "/speciality"
    }

    return <WrappedComponent viewAll={viewAll} comTitle={comTitle} comImg={comImg} type={upperCaseName} list={list} {...otherProps} />;
  };
}

export default MegamenuHOC;
