import React from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

import { getLocalData } from "../../utils/localStorage";
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import plus from "../../static/upload/plus.svg";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import { useTranslation } from "react-i18next";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
//  FilePondPluginPdfPreview,
  FilePondPluginFilePoster
);

function UploadReport(props) {
  const { files, setFiles, setIsDisabled } = props;

  const { t } = useTranslation();

  const onupdatefiles = (file) => { 
    console.log(file);
    
   }
  return (
    <div className={`file-pounder ${files.length > 0 ? "contains" : "empty"}`}>
      <FilePond
        files={files}
        
        allowReorder={true}
        allowMultiple={true}
        allowFileTypeValidation={true}
        acceptedFileTypes={["image/png", "image/jpeg", "image/jpg", "application/pdf"]}
        onupdatefiles={setFiles}
        onaddfilestart={(file) => {
          setIsDisabled(true);
        }}
        // allowPdfPreview= {true}
        // pdfPreviewHeight= {320}
        // pdfComponentExtraParams= {'toolbar=0&view=fit&page=1'}
        maxFiles={5}
        server={{
          process: {
            url: `${process.env.REACT_APP_API}api/${process.env.REACT_APP_API_VERSION_V1}/Media/Upload/${process.env.REACT_APP_CLINIC_ID}`,
            method: "POST",
            headers: {
              Authorization: `Bearer  ${getLocalData("user-info")?.userToken}`,
            },
            ondata: (formData) => {
              let fileAll = formData.getAll("filepond");

              var formData1 = new FormData();
              formData1.append("file", fileAll[1]);

              //formData.append('extraField', value);
              return formData1;
            },
            onload: (res) => {
              const obj = JSON.parse(res);
              setIsDisabled(false);
              return obj.response.pictureId;
            },
          },
          load: (source, load, error, progress, abort, headers) => {
            var myRequest = new Request(source);
            fetch(myRequest, {mode: 'no-cors'}).then(function(response) {
              response.blob().then(function(myBlob) {
                load(myBlob)
              });
            });         
        },
        }}
        
        allowFilePoster={true}
        labelIdle={`<img src=${plus} alt="plux" /><br/> <span className="filepond--label-action">Click to add document/image <br/> <span className="bel-upload">Supported file format jpeg, png, jpg, pdf<span></span> `}
      />
    </div>
  );
}

export default UploadReport;
