import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function TreatmentTrack({ treatmentsList }) {
  ////currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  const [isSeeMoreActive, setIsSeeMoreActive] = useState(false);
  return (
    <>
      <Row className={`treatment-track ${isSeeMoreActive ? "seemore" : ""}`}>
        {treatmentsList?.map((item, index) => {
          if (index >= 0) {
            return (
              <Col
                sm={3}
                xs={6}
                className={`treatmentItem ${index > 3 && "dissapear-onmobile"}`}
                key={item.id}
              >
                <Link
                  to={`/treatment/${item.slug}`}
                  className="item"
                >
                  <div className="image">
                    <img src={item.pictureUrl} alt={item.name} />
                    {item.price && (
                      <span>
                        {currencySymbol} {item.price}
                      </span>
                    )}
                  </div>
                  <div className="title">{item.name}</div>
                  <div className="text">{item.shortDescription}</div>
                </Link>
              </Col>
            );
          }
        })}
      </Row>
      {treatmentsList?.length > 4 && (
        <div className="d-flex justify-content-center mt-5 d-flex d-sm-none">
          <span
            className="btnr btnr-primary"
            onClick={() => setIsSeeMoreActive(!isSeeMoreActive)}
          >
            {isSeeMoreActive ? "View Less" : "See More"}
          </span>
        </div>
      )}
      {treatmentsList?.length > 8 && (
        <div className="see_more justify-content-center mt-5 d-none d-sm-flex">
          <span
            className="btnr btnr-primary"
            onClick={() => setIsSeeMoreActive(!isSeeMoreActive)}
          >
            {isSeeMoreActive ? "View Less" : "See More"}
          </span>
        </div>
      )}
    </>
  );
}

export default TreatmentTrack;
