import React from "react";
import { GetClinicFollowUpAppointmentsApi } from "../../services/apptservice";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import ip from "../../static/appt/ip.svg";
import vc from "../../static/appt/vc.svg";
import cal from "../../static/userapp/cal.svg";
import fup from "../../static/appt/fup.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function FollowUpList(props) {
  const [FollowUpList, setFollowUpList] = useState([]);
  const userToken = useSelector((state) => state.user.userObj.token);
  const { patientId } = props;

  let tempObj = {
    doctorId: 0,
    pageNo: 1,
    healthCenterId: 0,
    pageSize: 100,
    imageSize: 0,
    patientId: patientId,
    appointmentType: "",
  };

  useEffect(() => {
    GetClinicFollowUpAppointmentsApi(tempObj, userToken)
      .then((res) => {
        setFollowUpList(res.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const navigateToBookAppt = (stateforNextPage) => {
    navigate("/bookappt", { state: stateforNextPage });
  };

  return (
    FollowUpList.length > 0 && (
      <div className="follow-up-list-parent">
        <div className="header">
          <div className="img">
            <img src={fup} alt="s" />
          </div>

          <span>Follow-ups</span>
        </div>
        <Swiper
          slidesPerView={"auto"}
          centeredSlides={false}
          spaceBetween={20}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="list"
        >
          {FollowUpList.map((fup, index) => (
            <SwiperSlide className="fup-card" key={index}>
              <div className="doctor">
                <div className="img">
                  <img src={fup.doctorDto.pictureUrl} alt="doc" />
                </div>

                <div className="title">
                  <span className="header">{fup.doctorDto.name}</span>
                  {fup.doctorDto.title}
                </div>
              </div>
              <div className="icn">
                <div className="img">
                  {fup.appointmentType === "VideoCall" && (
                    <img src={vc} alt="asd" />
                  )}
                  {fup.appointmentType === "InPerson" && (
                    <img src={ip} alt="asd" />
                  )}
                  {fup.appointmentType === "Treatment" && (
                    <img src={ip} alt="asd" />
                  )}
                </div>

                <div className="title">
                  <span className="header">Appointment type </span>
                  {fup.appointmentType === "VideoCall" && <>Video Call</>}
                  {fup.appointmentType === "InPerson" && <>In Person</>}
                  {fup.appointmentType === "Treatment" && <>Treatment</>}
                </div>
              </div>
              <div className="icn">
                <div className="img">
                  <img src={cal} alt="doc" />
                </div>

                <div className="title">
                  <span className="header">Date </span>
                  {moment(fup.followUpDate).format("DD, MMMM YYYY")}
                </div>
              </div>
              <div className="text-center w-100">
                <button
                  className="btnr btnr-primary"
                  onClick={() =>
                    navigateToBookAppt({
                      doctor: fup.doctorDto,
                      apptType: fup.appointmentType,
                      processObj: fup.treatment,
                      processId: fup.treatmentId,
                      followup: true,
                      apptId: fup.id,
                      isFreeFollowUp: fup.isFreeFollowUp,
                    })
                  }
                >
                  Book Appointment
                </button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  );
}

export default FollowUpList;
