import React from "react";
// import clock from "../static/selectdoctors/clock.png";
// import dollar from "../static/selectdoctors/dollar.png";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetFilteredDoctors } from "../services/doctorservice";
import moment from "moment";
import ApptType from "../components/appt/ApptType";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDeepLinking,
  updateLoginDrawerState,
} from "../redux/webAppSlice";

import Drawer from "@mui/material/Drawer";
import Skeleton from "@mui/material/Skeleton";
import noDoc from "../static/selectdoctors/Mask group.png";
import TreatmentSelector from "../components/appt/TreatmentSelector";
import filter from "../static/selectdoctors/filter.svg";
import filterB from "../static/selectdoctors/filterB.svg";
import ConsultationFee from "../components/SelectDoctor/ConsultationFee";
import PriceInput from "../components/SelectDoctor/PriceInput";
import Gender from "../components/SelectDoctor/Gender";
import Availability from "../components/SelectDoctor/DoctorAvailable.js";
import Language from "../components/SelectDoctor/Language";
import FilterButton from "../components/SelectDoctor/FilterButton";
import filter_icon from "../static/selectdoctors/filter_icon.svg";

// import cross_icon from "../static/selectdoctors/cross.svg";
// import sports_icon from "../static/selectdoctors/spotrs.svg";
// import rheuma_icon from "../static/selectdoctors/rheuma.svg";
// import trauma_icon from "../static/selectdoctors/Trauma.svg";


const PAGE_SIZE = 6;
const fixedFilter = {
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  healthCenterId: 0,
  patientId: 0,
  lastUpdatedTimeTicks: 0,
  imageSize: 0,
  available: "",
  consultationFeeRange: {
    start: 0,
    end: 0
  },
  gender: ""
};


function SelectDoctor() {
  const location = useLocation();
  const clinicDetails = useSelector((state) => state.clinic.clincObj.clinic);



  // Filter STATE
  const [initialFilter, setInitialFilter] = useState({
    ...fixedFilter,
    appointmentType: location.state?.data?.type
      ? location.state?.data?.type
      : "InPerson",
    treatmentId: location.state?.data?.processId
      ? location.state?.data?.processId
      : 0,
  });

  //List STATE

  const [doctorObj, setDoctorObj] = useState({
    list: [],
    isLoading: true,
    isSuccess: false,
    msg: "",
    hasMore: true,
  });

  console.log(doctorObj.isLoading);

  useEffect(() => {
    fetchMoreData();
  }, []);

  const fetchMoreData = () => {
    GetFilteredDoctors(initialFilter)
      .then((res) => {
        let hasMore = false;
        let pageIndex = initialFilter.pageIndex;
        console.log(pageIndex)
        console.log("filterForHasMore", PAGE_SIZE * pageIndex)
        if (res.count >= PAGE_SIZE * pageIndex) {
          hasMore = true;
          pageIndex = pageIndex + 1;

          setInitialFilter({
            ...initialFilter,
            pageIndex,
          });
        }

        setDoctorObj({
          ...doctorObj,
          list: [...doctorObj.list, ...res.doctors],
          isLoading: false,
          isSuccess: true,
          hasMore,
        });
        // console.log("hasMore", hasMore);
      })
      .catch((err) => {
        console.log(err);
        setDoctorObj({
          ...doctorObj,

          isLoading: false,
          isSuccess: false,
          msg: err,
        });
      });
  };

  const onApptTypeChange = (selectedType) => {
    let tempFilter = {
      ...initialFilter,
      appointmentType: selectedType,
      treatmentId: 0,
      pageIndex: 1,
    };

    refetchEverything(tempFilter);
    setProcessObj(null);
  };

  const onGenderChange = (gender) => {
    let tempFilter = {
      ...initialFilter,
      gender: gender,
      pageIndex: 1,
    };

    refetchEverything(tempFilter);
  };

  const onTreatmentChange = (selectedTreatment) => {
    console.log(selectedTreatment)
    let tempFilter = {
      ...fixedFilter,
      appointmentType: 'Treatment',
      treatmentId: selectedTreatment.id,
      pageIndex: 1,
    };

    setProcessObj(selectedTreatment);

    refetchEverything(tempFilter);
  };

  const refetchEverything = (filter) => {
    console.log("refatchEveryThing", filter)
    setInitialFilter({
      ...filter,
    });
    console.log("the filter", filter)
    GetFilteredDoctors(filter)
      .then((res) => {
        let hasMore = false;
        let pageIndex = 1;
        if (res.count >= PAGE_SIZE * pageIndex) {
          hasMore = true;
          pageIndex = pageIndex + 1;
        }
        setInitialFilter({
          ...filter,
          pageIndex,
        });
        setDoctorObj({
          ...doctorObj,
          list: [...res.doctors],
          isLoading: false,
          isSuccess: true,
          hasMore,
        });
      })
      .catch((err) => {
        console.log(err);
        setDoctorObj({
          ...doctorObj,

          isLoading: false,
          isSuccess: false,
          msg: err,
        });
      });
  };

  //openLogin drwaer
  const dispatch = useDispatch();
  const openLoginDrawer = (tempObj) => {
    dispatch(updateLoginDrawerState(true));

    dispatch(updateDeepLinking(tempObj));
  };

  //get user detals redux
  const userDetails = useSelector((state) => state.user.userObj);

  const navigate = useNavigate();

  const navigateToBookAppt = (stateforNextPage) => {
    if (userDetails !== null) {
      console.log(stateforNextPage)
      navigate("/bookappt", { state: stateforNextPage });
    } else if (userDetails === null) {
      let tempObj = {
        toLink: "/bookappt",
        state: stateforNextPage,
      };
      openLoginDrawer(tempObj);
    }

    //check whether user is Logged in ??
  };

  const navigateViewProfile = (doc) => {
    navigate(`/doctorprofile/${doc.slug}`)
  }

  //moemnt formatter
  const format1 = "hh:mm A, Do MMM";

  //get process obj if any
  const [processObj, setProcessObj] = useState(
    location.state?.data?.processObj ? location.state?.data?.processObj : null
  );

  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  const hasDoctorFullProfile = useSelector(
    (state) => state.clinic?.clincObj?.clinic?.hasDoctorFullProfile
  );

  //filter
  const [isFilterDrawerActive, setIsFilterDrawerActive] = useState(false);

  const layout = useSelector((state) => state.webapp.layout);

  const skeleton = (
    <>
      {[...Array(6)].map((_, index) => (
        <div className="col-sm-4 doct_col" key={index}>
          <div className="doct-parent">
            <div className="img">
              <Skeleton variant="rect" height={300} />
            </div>
            <div className="body">
              <span className="name">
                <Skeleton variant="text" width="100%" height={30} />
              </span>
              <span className="des">
                <Skeleton variant="text" width="100%" height={20} />
              </span>
              <div className="internalbody align-items-center gap-1">
                <div>
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="text" width="100%" height={20} />
                </div>
                <Skeleton variant="rect" width={3} height={50} />
                <div>
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="text" width="100%" height={20} />
                </div>
              </div>
              <div className="button_sec d-none">
                <Skeleton variant="text" width={100} height={36} />
                <Skeleton variant="text" width={150} height={36} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const checkFOrUi = (availTime, apptype, doctor) => {
    let boolVar = true;
    if (apptype === "VideoCall") {
      if (doctor.videoCallConsultationFee) {
        return true;
      } else {
        if (availTime) {
          return true;
        } else {
          return false;
        }
      }
    } else if (apptype === "Process") {
      if (processObj.price) {
        return true;
      } else {
        if (availTime) {
          return true;
        } else {
          return false;
        }
      }
    } else if (apptype === "InPerson") {
      if (doctor.inPersonConsultationFee) {
        return true;
      } else {
        if (availTime) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const onreset = () => {
    let tempFilter = {
      ...initialFilter,
      available: "",
      consultationFeeRange: { start: 0, end: 0 },
      gender: "",
      healthCenterId: 0,
      imageSize: 0,
      lastUpdatedTimeTicks: 0,
      pageIndex: 1,
      pageSize: 6,
      patientId: 0,
      appointmentType: "",
      treatmentId: 0
    }

    refetchEverything(tempFilter)
  }

  return (
    <div className="main-body">
      <div className="container">
        <div className="selectdoctor-grid">
          {layout !== "mobile" && (
            <div className="sdgNav">
              <div className="filter_title">
                <h3 className="title">Filters</h3>
                <span onClick={onreset}>Clear All</span>
              </div>
              {
                (clinicDetails?.hasInPersonAppointment || clinicDetails?.hasVideoCallAppointment) &&
                <ApptType
                  processObj={processObj}
                  apptType={initialFilter.appointmentType}
                  outPutFn={(selectedType) => {
                    onApptTypeChange(selectedType);
                  }}
                />
              }
              {/* <PriceInput /> */}
              <Gender initFilter={initialFilter} onGenderChange={(gender) => {
                onGenderChange(gender)
              }} />
              {/* <Availability /> */}
              {/* <Language /> */}

              <TreatmentSelector
                onTreatmentChange={onTreatmentChange}
                processId={initialFilter.treatmentId}
              />
              {/* <FilterButton onreset={onreset} /> */}
            </div>
          )}

          <div className="sdgList">
            <div className="sdg_filter">
              <div>
                <span className="title">Available Doctors </span>
                <span className="subtitle">Select a doctor of your choice </span>

              </div>
              {/* {layout === "mobile" && (
                <button
                  className="btnr btnr-primary filter-btn-top"
                  onClick={() => setIsFilterDrawerActive(!isFilterDrawerActive)}
                >
                  <img src={filterB} alt="" />
                </button>
              )} */}
            </div>

            <InfiniteScroll
              className="doc-list row"
              dataLength={doctorObj.list.length}
              next={fetchMoreData}
              hasMore={doctorObj.hasMore}
              loader={skeleton}
            >
              {doctorObj.list.map((doctor, index) => (
                <div className="col-sm-4 doct_col" key={index}>
                  <div className="doct-parent ">
                    <div className="img">
                      <img src={doctor.pictureUrl} alt={doctor.name} />
                    </div>
                    <div className="body">
                      <span className="name">{doctor.name}</span>
                      <span className="des">{doctor.title} </span>
                      <span className="stdy">{doctor.qualification}</span>
                      <div className="internalbody">
                        {
                          // checkFOrUi(
                          //   doctor.nextAvailableTime,
                          //   initialFilter.appointmentType,
                          //   doctor
                          // ) && (
                          <>
                            <ConsultationFee initialFilter={initialFilter} doctor={doctor} processObj={processObj} />

                            <span className="st_line"></span>

                            {doctor.nextAvailableTime ? (
                              <div className="icon-text">
                                <div>
                                  <span className="it_time">
                                    {moment(doctor.nextAvailableTime)
                                      .utc()
                                      .local()
                                      .format(format1)}
                                  </span>
                                  Next available
                                </div>
                              </div>
                            ) : (
                              <div className="n_time">
                                <span className="">
                                  Not <br /> available
                                </span>
                              </div>
                            )
                            }
                          </>
                          // )
                        }
                      </div>


                      <div className="button_sec d-none">
                        {
                          hasDoctorFullProfile && (<button
                            onClick={() =>
                              navigateViewProfile(doctor)
                            }
                            className="btnr btnr-primary btnr-hover"
                          >
                            View Profile <span className="arr"></span>
                          </button>)
                        }
                        {
                          (clinicDetails?.hasInPersonAppointment || clinicDetails?.hasVideoCallAppointment) &&
                          <button
                            onClick={() =>
                              navigateToBookAppt({
                                doctor: doctor,
                                apptType: initialFilter.appointmentType,
                                processObj: processObj,
                                processId: initialFilter.treatmentId,
                                dateTimeObj: doctor.nextAvailableTime
                              })
                            }
                            className="btnr  btnr-primary-outline btnr-hover"
                          >
                            Book Appointment <span className="arr"></span>
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
            {(doctorObj.list.length < 1 && !doctorObj.isLoading) && (
              <div className="col-12">
                <div className="noDoc">
                  <img src={noDoc} alt="" />
                  <h3>No Doctor is available in this criteria</h3>
                  <span>Please modify your search & try again</span>
                </div>
              </div>
            )}
          </div>
          {layout === "mobile" && (
            <>
              <button
                className="btnr btnr-primary filter-btn"
                onClick={() => setIsFilterDrawerActive(!isFilterDrawerActive)}
              >
                <img src={filter} alt="" />
                Use FIlter
              </button>
              <Drawer
                anchor="bottom"
                open={isFilterDrawerActive}
                onClose={() => setIsFilterDrawerActive(!isFilterDrawerActive)}
                className="doc-filter"
              >
                {
                  (clinicDetails?.hasInPersonAppointment || clinicDetails?.hasVideoCallAppointment) &&
                  <ApptType
                    processObj={processObj}
                    apptType={initialFilter.appointmentType}
                    outPutFn={(selectedType) => {
                      onApptTypeChange(selectedType);
                    }}
                  />
                }

                {/* <PriceInput /> */}
                <Gender initFilter={initialFilter} onGenderChange={(gender) => {
                  onGenderChange(gender)
                }} />
                {/* <Availability /> */}
                {/* <Language /> */}
                <TreatmentSelector
                  onTreatmentChange={onTreatmentChange}
                  processId={initialFilter.treatmentId}
                />
                <FilterButton onreset={onreset} />
              </Drawer>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectDoctor;
