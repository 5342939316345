import axios from "axios";
const clinicId = process.env.REACT_APP_CLINIC_ID;

export const AddorUpdatePatientRating = async (revObj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/appointment/${revObj.appointmentId}/patientrating`,
    {
      ...revObj,
      clinicId,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};
export const DeletePatientReview = async (revId, token) => {
  const promise = axios.delete(
    `/${process.env.REACT_APP_API_VERSION_V1}/patientreview/${revId}`,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};
